import React from "react";
import { Box } from "@chakra-ui/react";

// Filters
import FilterForm from "../components/FilterForm";

// Reports
// organization
import OrganizationTickets from "./organization/OrganizationTickets";
import TicketsPerService from "./organization/OrganizationTicketsPerService";
import ServiceTimePerService from "./organization/OrganizationServiceTimePerService";

import TicketsPerServiceBranches from "./branches/BranchTicketsPerService";
import ServiceTimePerServiceBranches from "./branches/BranchServiceTimePerService";
import ArrivalRatesPerHour from "./organization/OrganizationArrivalRatesPerHour";
import ArrivalRatesPerDay from "./organization/OrganizationArrivalRatesPerDay";
import ArrivalRatesPerService from "./organization/OrganizationArrivalRatesPerService";
import TicketsPerBranch from "./organization/OrganizationTicketsPerBranch";
import AgentServiceTimePerService from "./branches/BranchAgentServiceTimePerService";
import AgentTicketsByService from "./branches/BranchAgentTicketsPerService";
import BranchesArrivalRatesPerDay from "./branches/BranchArrivalRatesPerDay";
import BranchesArrivalRatesPerHour from "./branches/BranchArrivalRatesPerHour";
import BranchesArrivalRatesPerService from "./branches/BranchArrivalRatesPerService";
import TicketsPerAgents from "./branches/BranchTicketsPerAgent";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getReportRangeString } from "../store/helpers";

const FullReport = () => {
  const { reportRange } = useSelector((state) => state.defaultReducer);

  const report = [
    <OrganizationTickets />,
    <TicketsPerService />,
    <ServiceTimePerService />,
    <TicketsPerServiceBranches />,
    <ServiceTimePerServiceBranches />,
    <ArrivalRatesPerHour />,
    <ArrivalRatesPerDay />,
    <ArrivalRatesPerService />,
    <TicketsPerBranch />,
    <AgentServiceTimePerService />,
    <AgentTicketsByService />,
    <BranchesArrivalRatesPerDay />,
    <BranchesArrivalRatesPerHour />,
    <BranchesArrivalRatesPerService />,
    <TicketsPerAgents />,
  ];
  return (
    <Box>
      <Helmet>
        <title>{getReportRangeString(reportRange)} Full Report</title>
      </Helmet>
      <FilterForm />
      {report.map((a, index) =>
        React.cloneElement(a, {
          key: index,
          
          showFilterForm: 0,
          showTitle: 0,
        })
      )}
    </Box>
  );
};

export default FullReport;
