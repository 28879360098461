import {
  HStack,
  Icon,
  Box,
  Text,
  Divider,
  Button,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { IoAddSharp } from "react-icons/io5";


import {
  setAddReport,
  setIsEditing,
} from "../../../../../store/actions/subscriptionsActions";
import { updateDrawer } from "../../../../../store/actions/miscAction";

const SelectedReportsTable = ({ reports,  reportTypes,  onDeleteReport,  onUpdateReport}) => {
  const dispatch = useDispatch();
  return (
    <Box>
      <Box  m="60px -25px 30px"  w="auto" position='relative' borderColor="#ebebeb">
        <Divider />
        <AbsoluteCenter bg='white' px='4'>
          Report Types
        </AbsoluteCenter>
      </Box>

          {reports.map((report, i) => {
            const reportType = reportTypes.find(a => a.id === report.reportTypeId);
            const reportName = reportType?.name
              .split("_")
              .slice(1)
              .join("_")
              .replace(/_([a-z])/g, (match, letter) => " " + letter.toUpperCase()) // Add a space before the capital letter
              .replace(/^./, (str) => str.toUpperCase());
            
            return (
                <HStack
                  key={i}
                  alignItems="start"
                  justifyContent="space-between"
                  borderBottom="1px solid #ebebeb"
                  p="10px 10px"
                  _hover={{ bg: "#ebebeb" }}
                  _last={{ borderBottom: "none" }}


                >
                  <Box>
                    <HStack spacing="10px" justifyContent="start" alignItems="flex-start">
                      <Text fontSize="15px">{i + 1}</Text>
                      <Text fontSize="15px"> {reportName} </Text>
                    </HStack>
                  </Box>
                  <HStack alignItems="flex-start" justifyContent="space-between">
                    <Text cursor="pointer" _hover={{textDecoration: "underline"}} onClick={() => onUpdateReport(report.reportTypeId)}>Edit</Text>
                    <Text cursor="pointer" _hover={{textDecoration: "underline"}} onClick={() => onDeleteReport(report.reportTypeId)}>Remove</Text>
                  </HStack>
                </HStack>
            );
          })}


      <Button 
        mt="0"
        className="arrowBtn lineBtn"
        w="100%"
        h="42px" 
        justifyContent="flex-start" 
        paddingLeft="15px" 
        background="var(--black-dry)" 
        color="var(--white)" 
        _hover={{ bg: 'var(--black-2)', borderColor: "var(--black-2)" }} 
        borderRadius="0"
        border="1px solid var(--black)" 
        fontWeight={400} 
        fontSize="15px" 
        leftIcon={<Icon boxSize="22px" color="#fff" as={IoAddSharp} />}
        onClick={() => {
          const drawer = {
            title: "Add Report Type",
            isOpen: 1,
            content: { component: "ReportForm", id: null },
          };
          dispatch(setIsEditing(false)); //editing a report
          dispatch(updateDrawer(drawer));
          dispatch(setAddReport(true));
        }}
      >
        Add another Report Type
      </Button>
    </Box>
  );
};

export default SelectedReportsTable;
