import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REPORT_RANGES } from "../../../../../store/constants";
import { Box, Text, VStack, Center } from "@chakra-ui/react";

import {
  setAddReport,
  setIsEditing,
  updateLocalSubscription,
} from "../../../../../store/actions/subscriptionsActions";
import _ from "lodash";
import { updateDrawer } from "../../../../../store/actions/miscAction";

import Select from "../../../../averly/Select";
import Input from "../../../../averly/Input";

const ReportRangeSelection = () => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.subscriptionsReducer);
  const subscriptionClone = _.cloneDeep(subscription);
  const [showSubNameInput, setShowSubNameInput] = useState(false);
  const { subscriptionName } = subscriptionClone || "";

  const deliveryOptions = [
    { name: "Monthly", message: "1st day of every month" },
    { name: "Weekly", message: "Every Monday" },
    { name: "Daily", message: "Start of every business day" },
  ];

  useEffect(() => {
    if (_.isEmpty(subscription)) {
      const { reportRange } = _.cloneDeep(subscription);
      dispatch(updateLocalSubscription({ reportRange })); //remove everything except the reportRange
    }
  }, [subscription]);

  let reportRangeString = {
    day: "daily",
    week: "weekly",
    month: "monthly",
  }[subscriptionClone.reportRange];

  reportRangeString =
    reportRangeString?.charAt(0).toUpperCase() + reportRangeString?.slice(1);

  const subscriptionNameInput = (
    <Box onBlur={(e) => setShowSubNameInput(0)}>
      <Input
        label="Name"
        placeholder="Enter report subscription name"
        value={subscriptionName}
        onChange={(e) =>
          dispatch(
            updateLocalSubscription({
              ...subscriptionClone,
              subscriptionName: e,
            })
          )
        }
      />
    </Box>
  );

  return (
    <Box>
      <VStack alignItems="left" spacing="5px">
        {!_.isEmpty(subscription.reports) ? (
          !showSubNameInput ? (
            <Box bg="#ebebeb" p="20px 20px">
              <VStack alignItems="flex-start">
                <Text fontSize="14px">
                  <strong>Name:</strong> {subscriptionName}
                  <Text
                    fontSize="14px"
                    color="#7b7b7b"
                    cursor="pointer"
                    onClick={() => setShowSubNameInput(1)}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Update subscription name
                  </Text>
                </Text>
                <Text fontSize="14px">
                  <strong>Subscription:</strong> {reportRangeString}
                </Text>
                <Text fontSize="14px">
                  <strong>Delivery:</strong>{" "}
                  {
                    deliveryOptions.find((a) => a.name === reportRangeString)
                      .message
                  }
                </Text>
              </VStack>
            </Box>
          ) : (
            subscriptionNameInput
          )
        ) : (
          <>
            {subscriptionNameInput}
            <Select
              label="Report Range"
              placeholder="Select Report Range"
              isDisabled={!_.isEmpty(subscription.reports)}
              value={subscriptionClone.reportRange}
              options={[
                { name: "Monthly", id: REPORT_RANGES.MONTH },
                { name: "Weekly", id: REPORT_RANGES.WEEK },
                { name: "Daily", id: REPORT_RANGES.DAY },
              ]}
              onChange={(e) =>
                dispatch(
                  updateLocalSubscription({
                    ...subscriptionClone,
                    reportRange: e,
                  })
                )
              }
            />
          </>
        )}

        {_.isEmpty(subscription.reports) &&
        subscriptionClone.reportRange &&
        subscriptionName?.length ? (
          <Center border="2px dashed #E3E0E0" h="108px" borderRadius={10}>
            <Text
              fontSize="16px"
              color="var(--black)"
              cursor="pointer"
              textDecoration="underline"
              _hover={{ color: "var(--blue)", textDecoration: "underline" }}
              onClick={() => {
                const drawer = {
                  title: "Add Report Type",
                  isOpen: 1,
                  content: { component: "ReportForm", id: null },
                };
                dispatch(setIsEditing(false));
                dispatch(updateDrawer(drawer));
                dispatch(setAddReport(true));
              }}
            >
              Add your first report type
            </Text>
          </Center>
        ) : null}
      </VStack>
    </Box>
  );
};

export default ReportRangeSelection;
