import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import { LEVELS } from "../../../../../store/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  resetForm,
  setAddReport,
  setActiveReportTypeId,
  updateLocalSubscription,
  updateLevel,
  setSelectedBranches,
} from "../../../../../store/actions/subscriptionsActions";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  resetDrawer,
  updateDrawer,
} from "../../../../../store/actions/miscAction";

const Save = ({}) => {
  const dispatch = useDispatch();
  const {
    subscription,
    activeReportTypeId,
    editReportTypeId,
    isEditing,
    level,
    selectedBranches,
  } = useSelector((state) => state.subscriptionsReducer);

  const subscriptionClone = _.cloneDeep(subscription);

  const saveToLocal = () => {
    //while editing the chosen reportTypeId must not exist in the reports array
    if (
      subscriptionClone?.reports?.find(
        (report) => report.reportTypeId === editReportTypeId
      ) &&
      editReportTypeId !== activeReportTypeId &&
      isEditing
    ) {
      toast.error("Report already exists");
      return;
    }

    const reportExists = subscriptionClone?.reports?.find(
      (report) => report.reportTypeId === activeReportTypeId
    );

    if (level === LEVELS.BRANCH && selectedBranches.length === 0) {
      toast.error("Please select branches");
      return;
    }
    if (reportExists) {
      //update existing report
      const reportIndex = subscriptionClone.reports.findIndex(
        (a) => a.reportTypeId === activeReportTypeId
      );

      //update the report
      const updatedReport = {
        ...subscriptionClone.reports[reportIndex],
        reportTypeId: editReportTypeId || activeReportTypeId,
        branches: selectedBranches || null,
      };

      subscriptionClone.reports[reportIndex] = updatedReport;
      dispatch(updateLocalSubscription(subscriptionClone));
      discard();
      return;
    }

    //activeReportType id will be empty when the user deletes all reports during editing
    let listOrder = 0;
    if (!_.isEmpty(subscriptionClone?.reports)) {
      listOrder =
        Math.max(...subscriptionClone?.reports?.map((a) => a.listOrder || 0)) ||
        0;
    }
    listOrder += 1;
    const updatedReports = subscriptionClone.reports
      ? [
          ...subscriptionClone.reports,
          level === LEVELS.BRANCH
            ? {
                reportTypeId: activeReportTypeId || editReportTypeId,
                branches: selectedBranches,
                listOrder: listOrder,
              }
            : {
                reportTypeId: activeReportTypeId || editReportTypeId,
                listOrder: listOrder,
              },
        ]
      : [
          level === LEVELS.BRANCH
            ? {
                reportTypeId: activeReportTypeId || editReportTypeId,
                branches: selectedBranches,
                listOrder,
              }
            : {
                reportTypeId: activeReportTypeId || editReportTypeId,
                listOrder,
              },
        ];

    dispatch(
      updateLocalSubscription({
        ...subscriptionClone,
        reports: updatedReports,
      })
    );
    dispatch(updateLevel(""));
    dispatch(setSelectedBranches([]));
    dispatch(setAddReport(false));
    dispatch(setActiveReportTypeId(""));
    const title = subscriptionClone?.id
      ? "Edit Reports Subscription"
      : "Create a Reports Subscription";
    const drawer = {
      title,
      isOpen: 1,
      content: { component: "SubscriptionForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };

  const discard = () => {
    if (activeReportTypeId) {
      const title = subscriptionClone.id
        ? "Edit Reports Subscription"
        : "Create a Reports Subscription";
      const drawer = {
        title,
        isOpen: 1,
        content: { component: "SubscriptionForm", id: null },
      };
      dispatch(setActiveReportTypeId(""));
      dispatch(setAddReport(false));
      dispatch(updateDrawer(drawer));
      return;
    } else if (
      //only the subscriptionName and reportRange
      Object.keys(subscription).length === 2 &&
      Object.keys(subscription).includes("reportRange")
    ) {
      const title = "Create a Reports Subscription";
      const drawer = {
        title,
        isOpen: 1,
        content: { component: "SubscriptionForm", id: null },
      };
      dispatch(setAddReport(false));
      dispatch(
        updateLocalSubscription({ ...subscriptionClone, reportRange: "" })
      );

      dispatch(updateDrawer(drawer));
      return;
    }
    dispatch(resetForm());

    dispatch(resetDrawer());
  };

  return (
    <>
      <Box mt={5}>
        <Box>
          <ButtonGroup>
            <Button
              onClick={discard}
              variant="outline"
              colorScheme="black"
              size="sm"
            >
              Cancel
            </Button>
            {(activeReportTypeId || editReportTypeId) &&
              (level === LEVELS.ORGANIZATION ||
                (selectedBranches.length > 0 && level === LEVELS.BRANCH)) && (
                <Button onClick={saveToLocal} size="sm" bg="#000" color="#fff">
                  {!_.isEmpty(
                    subscription?.reports?.find(
                      (a) => a.reportTypeId === activeReportTypeId
                    )
                  ) && isEditing
                    ? "Update" /* if editing */
                    : "Add"}
                </Button>
              )}
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export default Save;
