import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IoTimeOutline } from "react-icons/io5";

import {
  checkServiceHasChildren,
  generateHoursArray,
  formatTime,
  convertTo24HourHour,
} from "../../../../store/helpers";

import AgentServiceTimePerServiceLineGraph from "../../../branches/charts/daily/AgentServiceTimePerServiceLineGraph";
import StatsCard from "../../../StatsCard";
import { TICKET_STATUS } from "../../../../store/constants";

const OrganizationAverageWaitTimePerBranch = ({ reportIndex }) => {
  const { workDay, branches, servicesSubscriptions, services } = useSelector(
    (state) => state.defaultReducer
  );
  const { tickets, timeNow } = useSelector((state) => state.realtimeReducer);
  let branchesClone = _.cloneDeep(branches);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  branchesClone = branchesClone.filter(
    (a) => a.reportStatus != null && Number(a.reportStatus)
  );

  const branchesMap = branchesClone.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  ticketsClone.forEach((data) => {
    const branchId = data.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(data);
    }
  });

    const branchesData = Object.keys(branchesMap)
      .map((branchId) => {
        const branchTickets = branchesMap[branchId];
        const branchName =
          branches.find((branch) => branch.id === branchId)?.name ||
          `Branch-${branchId}`;

        let branchServices = servicesSubscriptionsClone.find(
          (service) => service.branchId === branchId
        )?.services;
        branchServices = checkServiceHasChildren(servicesClone).filter(
          (service) =>
            branchServices?.includes(service.id) && !service.hasChildren
        );
        const numServices = branchServices.length || 0;

        //BranchChartData

        const startTimeStamp = dayjs()
          .set("hour", workDay[0].split(" ")[0])
          .set("minute", 0)
          .unix();
        const endTimeStamp = timeNow;
        const hoursArray = generateHoursArray(startTimeStamp, endTimeStamp);
        const chart = hoursArray
          .map((hour) => {
            const time24 = convertTo24HourHour(hour);
            const incompletedTickets = branchTickets
              .filter((a) => a.status !== TICKET_STATUS.COMPLETED)
              .filter((a) => Number(dayjs.unix(a.created).hour()) <= time24);

            const currentTime =
              dayjs().hour() === time24
                ? dayjs().unix()
                : dayjs().set("hour", time24).set("minute", 59).unix();

            const incompleteTicketsWaitTime =
              incompletedTickets.reduce(
                (acc, a) => acc + (currentTime - a.created),
                0
              ) || 0;

            const completedTickets = branchTickets.filter(
              (a) =>
                Number(a.status) === TICKET_STATUS.COMPLETED &&
                dayjs.unix(a.sessionEnd).format("h A") === hour
            );

            const completedTicketsWaitTime = completedTickets.reduce(
              (acc, a) => acc + (a.sessionStart - a.created),
              0
            );

            const totalWaitTime =
              incompleteTicketsWaitTime + completedTicketsWaitTime;
            const total =
              (incompletedTickets?.length || 0) +
              (completedTickets?.length || 0);
            const hourlyAverage = Math.ceil(totalWaitTime / total) || null;

            if (workDay.includes(hour))
              return {
                hour,
                total,
                hourlyAverage: Number.isFinite(hourlyAverage)
                  ? hourlyAverage
                  : null,
              };
            return null;
          })
          .filter(Boolean);

        const min = Math.min(...chart.map((a) => a.hourlyAverage)) || 0;
        const max = Math.max(...chart.map((a) => a.hourlyAverage)) || 0;
        const validPoints = chart.filter((a) => a.hourlyAverage !== null);
        const average =
          chart.reduce((a, b) => a + b.hourlyAverage, 0) / validPoints.length ||
          0;
        const total = chart.reduce((a, b) => a + b.total, 0) || 0;

        return {
          branchName,
          min,
          branchId,
          max,
          total,
          average,
          numServices,
          chart,
        };
      })
      .sort((a, b) => {
        // Sort by totalTickets time in descending order
        if (b.total - a.total !== 0) {
          return b.total - a.total;
        }
        // If totalTickets is the same, sort alphabetically by name
        return a.branchName.localeCompare(b.branchName);
      });


  return (
    <Box>
      {branchesData?.map((branchData, index) => {
        const { branchName, chart, average, max, branchId } = branchData;
        const stats = [
          {
            title: "Avg. Wait Time Today",
            stat:
              Number(Math.max(...chart.map((a) => a.total))) === 0
                ? "NA"
                : Number.isFinite(average) && !Number.isNaN(average)
                ? formatTime(average)
                : "NA",
            icon: IoTimeOutline,
            iconColor: "var(--gray-8)",
          },
          {
            title: "Max Avg. Wait Time Per Hour",
            stat:
              Number(Math.max(...chart.map((a) => a.total))) === 0
                ? "NA"
                : Number.isFinite(max) && !Number.isNaN(max)
                ? formatTime(max)
                : "NA",
            icon: IoTimeOutline,
            iconColor: "var(--gray-8)",
          },
          {
            title: "Avg. Wait Time this hour",
            stat:
              chart.find((a) => a.hour === dayjs().format("h A"))?.total > 0
                ? formatTime(
                    chart.find((a) => a.hour === dayjs().format("h A"))
                      ?.hourlyAverage || "0"
                  )
                : "NA",
            icon: IoTimeOutline,
            iconColor: "var(--gray-8)",
          },
        ];
        return (
          <Box className="reportContainer" mt="20px" key={branchId}>
            <Heading fontSize={"24px"} color="var(--black-dry)">
              {branchName} Branch
            </Heading>

            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={0}
              mb={10}
              mt="20px"
            >
              {stats.map((a, i) => (
                <Box
                  px={"20px"}
                  py={"15px"}
                  key={i}
                  mb={"5px"}
                  border={"1px solid var(--borderGrey)"}
                  borderRight={{
                    base: "1px solid #E5E4E5",
                    md: [2, 5].includes(i) ? "1px solid #E5E4E5" : "none",
                  }}
                  borderRadius={{
                    base: "10px",
                    md: [0, 3].includes(i)
                      ? "10px 0 0 10px"
                      : [2, 5].includes(i)
                      ? "0 10px 10px 0"
                      : "0",
                  }}
                >
                  <StatsCard
                    title={a.title}
                    stat={a.stat}
                    icon={a.icon}
                    iconColor={a.iconColor}
                  />
                </Box>
              ))}
            </SimpleGrid>
            <AgentServiceTimePerServiceLineGraph
              chart={chart}
              lineName="Average Wait Time In Seconds"
              title={"Average Wait Time Per Branch, Hour by Hour"}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default OrganizationAverageWaitTimePerBranch;
