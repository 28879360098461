import React, {useState, useEffect} from "react";
import { IoCloseCircleOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Box, HStack, Heading, Icon, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

import useRoles from "../hooks/useRoles";

import { stepsDefault } from "../store/constants";

const Dashboard = () => {
  const { organizationId, reportRange } = useSelector((state) => state.defaultReducer);
  const { isSuperAdmin } = useRoles();
  const [steps, setSteps] = useState(_.cloneDeep(stepsDefault));

  if (!isSuperAdmin) delete steps[0];

  useEffect(() => {
    const updatedSteps = steps.map(a => {
      if (a.type === "organization") {
        return {...a, status: isSuperAdmin && organizationId.length ? 1 : 0};
      } else if (a.type === "reportRange") {
        return {...a, status: organizationId.length && reportRange.length ? 1 : 0};
      }
      return a;
    });
    setSteps(updatedSteps);
  }, [isSuperAdmin, organizationId, reportRange]);

  return (
    <Box>
      <Heading size="md" fontWeight={500} color="var(--black-dry)">Welcome to the QM Analytics Dashboard</Heading>
      <Text>Follow the steps below to get started.</Text>
      <Box>
        {steps.map((a, i) => (
          <HStack key={i} mt="20px">
            <Box w="32px" h="32px">
              <Icon as={a.status ? IoCheckmarkCircleOutline : IoCloseCircleOutline} boxSize="32px" color={a.status ? "var(--green)" : "var(--lightGrey)"} />
            </Box>
            <Box>
              <Heading fontSize="md" color={a.status ? "var(--bleck)" : "#6a6a6a"}>{i+1}. {a.title}</Heading>
              <Text color="var(--lightGrey)">{a.description}</Text>
            </Box>
          </HStack>
        ))}
      </Box>
    </Box>
  );
};

export default Dashboard;
