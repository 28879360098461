import { Center, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Center py="10em">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="var(--blue)"
        size="xl"
        display="flex"
        justifyContent="center"
        alignContent="center"
      />
    </Center>
  );
};

export default Loader;
