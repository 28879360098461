import { Box, Text } from "@chakra-ui/react";

import AgentServicesTable from "../tables/AgentServicesTable";


const AgentServices = ({ data }) => {
  return (
    <Box>
      {data.map(
        ({ branchName, servicesData, numServices }, branchIndex) => {
          const title = `${branchIndex + 1}. ${branchName}`;

          return (
            <>
              <h2>{title}</h2>
              <Text>{numServices} services.</Text>
              {servicesData.map((data, index) => {
                return (
                  <AgentServicesTable
                    index={index}
                    serviceData={data}
                    branchIndex={branchIndex}
                    branchName={branchName}
                  />
                );
              })}
            </>
          );
        }
      )}
    </Box>
  );
};

export default AgentServices;
