import { Box, Text } from "@chakra-ui/react";


import ArrivalRatesPerDayTable from "../tables/ArrivalRatesPerDayTable";

const ArrivalRatesPerDay = ({ data }) => {


  return (
    <Box>
      {data.map((data, index) => {
        const { branchName, numServices } = data;
        const title = `${index + 1}. ${branchName}`;
        
        return (
          <Box key={index}>
            <h2>{title}</h2>
            <Text>{numServices} services</Text>
            <ArrivalRatesPerDayTable data={data} index={index} />
          </Box>
        );
      })}
    </Box>
  );
};

export default ArrivalRatesPerDay;
