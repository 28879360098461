import { useDispatch, useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/react";
import _ from "lodash";
import MultiSelect from "react-select";
import { setSelectedBranches } from "../../../../../store/actions/subscriptionsActions";

const BranchSelection = () => {
  const dispatch = useDispatch();
  const { branches, organizationId } = useSelector((store) => store.defaultReducer);

  const {
    subscription,
    activeReportTypeId,
    editReportTypeId,
  } = useSelector((store) => store.subscriptionsReducer);

  const subscriptionClone = _.cloneDeep(subscription);
  const index = subscriptionClone?.reports?.findIndex(
    (a) => a.reportTypeId === activeReportTypeId
  );
  let currentBranches = [];
  if (index > -1) {
    currentBranches = subscriptionClone.reports[index].branches;
  }

  const orgBranches = branches.filter(
    (branch) =>
      branch.organizationId === organizationId &&
      Number(branch.reportStatus) === 1
  );

  return (
    (activeReportTypeId || editReportTypeId) ? (<Box
      mt="10px"
      w="100%"
      h="auto"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 5px 9px 12px"
    >
      <Text
        h="15px"
        lineHeight="15px"
        fontSize="12px"
        color="#6a6a6a"
      >
        Select Branches
      </Text>

        <MultiSelect
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "none", outline: "none", boxShadow: "none", paddingLeft: 0, marginLeft: 0,
            }),
          }}
          options={orgBranches.map((a) => ({
            value: a.id,
            label: a.name,
          }))}
          defaultValue={currentBranches?.map((branch) => {
            return {
              value: branch.id,
              label: orgBranches.find((a) => a.id === branch.id).name,
            };
          })}
          onChange={(e) => {
            const newBranches = e.map((a, i) => ({
              id: a.value,
              listOrder: i + 1,
            }));
            dispatch(setSelectedBranches(newBranches));
          }}
          isMulti={true}
          isClearable={true}
          isSearchable={true}
          placeholder="Select Branch"
        />

    </Box>)
    : null
  );
};

export default BranchSelection;
