import { Box, Text, VStack, HStack, Switch, Input, Heading, Divider, AbsoluteCenter } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { updateLocalSubscription } from "../../../../../store/actions/subscriptionsActions";

const SubscriptionOptions = () => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.subscriptionsReducer);
  const subscriptionClone = _.cloneDeep(subscription);
  let { activeStatus, emailAttachment, subscriptionName } = subscriptionClone;

  if (emailAttachment === undefined || emailAttachment === null) {
    emailAttachment = 1;
  }
  emailAttachment = Number(emailAttachment) === 1 ? 1 : 0;
  if (activeStatus === undefined || activeStatus === null) {
    activeStatus = 1;
  }
  activeStatus = Number(activeStatus) === 1 ? 1 : 0;

  subscriptionName = subscriptionName || "";
  return (
    <Box>
      <Box  m="60px -25px 30px"  w="auto" position='relative' borderColor="#ebebeb">
        <Divider />
        <AbsoluteCenter bg='white' px='4'>
          Subscription Options
        </AbsoluteCenter>
      </Box>

      <VStack alignItems="left">
        <Box>
          <HStack>
            <Text fontSize="15px" color="var(--black)">Attach Report to Email</Text>
            <Switch
              size="md"
              isChecked={emailAttachment}
              onChange={() => {
                dispatch(
                  updateLocalSubscription({
                    ...subscriptionClone,
                    emailAttachment: Number(emailAttachment) === 1 ? 0 : 1,
                  })
                );
              }}
            />
          </HStack>
            <Text fontSize="14px"  color="var(--gray-8)">
              A link to the report will automatically be provided if the
              attachment is more than 3MB
            </Text>
        </Box>
        <Divider m="10px 0" w="auto" borderColor="#ebebeb" />
        <Box>
          <HStack>
            <Text fontSize="15px" color="var(--black)">Provide Link to report</Text>
            <Switch
              size="md"
              isChecked={!emailAttachment}
              onChange={() => {
                dispatch(
                  updateLocalSubscription({
                    ...subscriptionClone,
                    emailAttachment: Number(emailAttachment) === 1 ? 0 : 1,
                  })
                );
              }}
            />
          </HStack>
        </Box>
        <Divider m="10px 0" w="auto" borderColor="#ebebeb" />
        <Box>
          <HStack>
            <Text fontSize="15px" color="var(--black)">Pause Report Subscription</Text>
            <Switch
              size="md"
              isChecked={!activeStatus}
              onChange={() => {
                dispatch(
                  updateLocalSubscription({
                    ...subscriptionClone,
                    activeStatus: Number(activeStatus) === 1 ? 0 : 1,
                  })
                );
              }}
            />
          </HStack>
          <Text fontSize="14px"  color="var(--gray-8)">
            Temporarily halt automated report deliveries. Enable to continue receiving this report subscription.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default SubscriptionOptions;
