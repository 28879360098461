import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import BranchArrivalRatesPerService from "../../../components/branches/reports/daily/BranchArrivalRatesPerService";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import FilterForm from "../../../components/FilterForm";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import _ from "lodash";
import { Helmet } from "react-helmet";

const DailyBranchArrivalRatesPerService = ({
  showFilterForm = true,
  
  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId = REPORT_TYPE_IDS.daily_branch_arrival_rates_per_service;

  const title =  'Arrival Rates Per Service';
  

    const reportsDataClone = _.cloneDeep(reportsData);
    let reports = reportsDataClone.filter(
      (a) => a.reportTypeId === reportTypeId
    );
    if (branchId.length > 0) {
      reports = reports.filter((a) => a.branchId === branchId);
    }
    const data = reports.map((a) => a.data) || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Branch Arrival Rates Per Service</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <Heading fontSize="34px" color="#222" fontWeight="700">
                {title}

                <Tooltip
                  label={
                    getReportDescription(reportRange)
                      .BranchesArrivalRatesPerService
                  }
                  aria-label="A tooltip"
                >
                  <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                </Tooltip>
              </Heading>
              <BranchArrivalRatesPerService data={data} />
            </>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default DailyBranchArrivalRatesPerService;
