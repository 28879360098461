import React from "react";
import { Box, Heading, Text, Link } from "@chakra-ui/react";

const NoPermissions = () => {
  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      bg="gray.50" 
      borderRadius="lg" 
      boxShadow="md" 
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, red.400, red.600)"
        backgroundClip="text"
      >
        403
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        No Permissions to Access
      </Text>
      <Text color={"gray.500"} mb={6}>
        You do not have the required permissions to access this page.
      </Text>
      <Link
        href={`https://auth.averly.com.na?origin=${window.location.href}`} 
        color="blue.500"
        fontWeight="bold"
        fontSize="lg"
        target="_blank"
      >
        Log in
      </Link>
      <Text fontSize="sm" color="gray.500" mt={2}>
        or contact your administrator for assistance.
      </Text>
    </Box>
  );
};

export default NoPermissions;
