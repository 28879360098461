import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import FilterForm from "../../../components/FilterForm";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import OrganizationArrivalRatesPerHour from "../../../components/organization/reports/daily/OrganizationArrivalRatesPerHour";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import _ from "lodash";
import { Helmet } from "react-helmet";

const DailyOrganizationArrivalRatesPerHour = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId =
    REPORT_TYPE_IDS.daily_organization_arrival_rates_per_hour;

  const title = ` Arrival Rates Per Hour`;

  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Organization Arrival Rates Per Hour</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <Box mb="20px">
                <Heading fontSize="34px" color="#222" fontWeight="700">
                  {title}
                  <Tooltip
                    label={
                      getReportDescription(reportRange).ArrivalRatesPerHour
                    }
                    aria-label="A tooltip"
                  >
                    <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                  </Tooltip>
                </Heading>
              </Box>
              <OrganizationArrivalRatesPerHour data={data} />
            </>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default DailyOrganizationArrivalRatesPerHour;
