import ReportRangeSelection from "./components/ReportRangeSelection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Text, ButtonGroup, textDecoration, Center, Icon, Stack, Divider } from "@chakra-ui/react";

import {
  getBranches,
  getReportTypes,
} from "../../../../store/actions/defaultAction";
import _ from "lodash";
import { LEVELS, PRODUCT_ID } from "../../../../store/constants";


import SubscriptionOptions from "./components/SubscriptionOptions";
import {
  createSubscription,
  resetForm,
  setActiveReportTypeId,
  setAddReport,
  setEditReportTypeId,
  setIsEditing,
  setSelectedBranches,
  setSubLoading,
  updateLevel,
  updateLocalSubscription,
  updateReportRange,
  updateSubscription,
} from "../../../../store/actions/subscriptionsActions";
import {
  resetDrawer,
  updateDrawer,
} from "../../../../store/actions/miscAction";
import SelectedReportsTable from "./tables/SelectedReportsTable";
import { toast } from "react-toastify";

const SubscriptionForm = ({ id }) => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.authReducer);
  const { organizationId } = useSelector((state) => state.defaultReducer);
  const { subscription, loading } = useSelector(
    (state) => state.subscriptionsReducer
  );
  const { reportTypes } = useSelector((store) => store.defaultReducer);
  const subscriptionClone = _.cloneDeep(subscription);

  //edit existing subscription
  useEffect(() => {
    if (id && !_.isEmpty(subscription)) {
      dispatch(updateReportRange(subscription.reportRange));
    }
  }, [id, subscription]);

  //successfully save data: somehow save successul is not dispatching
  useEffect(() => {
    if (loading) {
      dispatch(resetForm());
      dispatch(resetDrawer());
    }
  }, [loading]);

  //setup
  useEffect(() => {
    dispatch(getReportTypes()); //get the report types
    dispatch(setSubLoading(false));
  }, []);

  const onDeleteReport = (reportTypeId) => {
    const index = subscriptionClone.reports.findIndex(
      (report) => report.reportTypeId === reportTypeId
    );

    if (index === -1) {
      console.log(`Report with type ID ${reportTypeId} not found.`);
      return;
    }

    // Remove the report from array and update the listOrder
    subscriptionClone.reports.splice(index, 1);

    subscriptionClone.reports.forEach((report, index) => {
      report.listOrder = index + 1;
    });

    dispatch(updateLocalSubscription(subscriptionClone));
  };

  const onUpdateReport = (reportTypeId) => {
    dispatch(setIsEditing(true));
    dispatch(setAddReport(true));
    dispatch(setActiveReportTypeId(reportTypeId));
    dispatch(setEditReportTypeId(reportTypeId));
    //determine level
    const reportName = reportTypes.find((a) => a.id === reportTypeId).name;
    const level = LEVELS[reportName.split("_")[1].toUpperCase()];
    const selectedBranches = subscriptionClone?.reports.find(
      (a) => a.reportTypeId === reportTypeId
    )?.branches;
    if (selectedBranches) {
      //branch level reports
      dispatch(setSelectedBranches(selectedBranches));
    }
    dispatch(updateLevel(level, false));

    const drawer = {
      title: "Update Report Type",
      isOpen: 1,
      content: { component: "ReportForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };

  const saveSubscription = () => {
    if (!subscriptionClone.subscriptionName) {
      toast.error("Subscription name is required");
      return;
    }
    if (subscriptionClone?.reports.length === 0) {
      toast.error("Please add a report");
      return;
    }
    //was editing will mess with the listOrder
    if (subscriptionClone.id) {
      dispatch(updateSubscription(subscriptionClone, subscriptionClone.id));

      return;
    }
    const subscriptionObj = {
      ...subscriptionClone,
      userId: userData.id,
      organizationId,
      activeStatus:
        subscriptionClone?.activeStatus === undefined ||
        subscriptionClone.activeStatus === null
          ? 1
          : subscriptionClone?.activeStatus,
      emailAttachment:
        subscriptionClone?.emailAttachment === undefined ||
        subscriptionClone?.emailAttachment === null
          ? 1
          : subscriptionClone?.emailAttachment,
      productId: PRODUCT_ID,
    };

    dispatch(createSubscription(subscriptionObj));
  };

  return (
    <Box id="subscriptionForm">
      <ReportRangeSelection />

      {subscriptionClone?.reports?.length ? (
        <>
          <SelectedReportsTable
            reports={subscriptionClone?.reports || []}
            reportTypes={reportTypes}
            onDeleteReport={onDeleteReport}
            onUpdateReport={onUpdateReport}
          />
          <SubscriptionOptions />
        </>
      ) : (
        <></>
      )}

      {subscriptionClone?.reports?.length > 0 && (
        <Box>
          <Divider m="20px -25px" w="auto" borderColor="#ebebeb" />
          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={{ base: "15px" }}
            mt={{ base: "20px" }}
          >
            <Button
              className="arrowBtn lineBtn"
              w="100%"
              h="52px"
              justifyContent="flex-start"
              paddingLeft="15px"
              background="var(--black)"
              color="var(--white)"
              _hover={{ bg: "var(--black-2)", borderColor: "var(--black-2)" }}
              borderRadius="8px"
              border="1px solid var(--black)"
              fontWeight={400}
              fontSize="16px"
              onClick={saveSubscription}
            >
              Save
            </Button>
            <Button
              className="arrowBtn lineBtn"
              w="100%"
              h="52px"
              justifyContent="flex-start"
              paddingLeft="15px"
              background="var(--white)"
              color="var(--black)"
              _hover={{
                color: "var(--black-2)",
                borderColor: "var(--black-2)",
                stroke: "var(--black-2)",
              }}
              borderRadius="8px"
              border="2px solid var(--black)"
              fontWeight={400}
              font-size="16px"
              stroke="var(--blue)"
              onClick={() => {
                dispatch(resetForm());
                dispatch(resetDrawer());
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionForm;
