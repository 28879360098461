import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Box, Center, Text } from "@chakra-ui/react";
import Loader from "../../../loader";
import { TICKET_STATUS } from "../../../../store/constants";
import { useEffect, useState } from "react";
import CustomerCard from "../../../CustomerCard";
import { formatTime } from "../../../../store/helpers";

const BranchTicketLookup = ({ branches }) => {
  const { customerTicket, fetchingTicket } = useSelector(
    (state) => state.realtimeReducer
  );
  const [data, setData] = useState(null);
  const { services, users, workDay, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const { iamRoles } = useSelector((state) => state.authReducer);
  const agentRoleId = iamRoles.find((a) => a.name === "agent")?.id;

  let agents = _.cloneDeep(users).filter(
    (a) => Number(a.agentStatus) === 1 || a.roleId === agentRoleId
  );
  const servicesClone = _.cloneDeep(services);

  useEffect(() => {
    if (_.isEmpty(customerTicket)) return;
    const { customer, ticket } = customerTicket;
    if (ticket.branchId !== branchId) {
      setData(null);
      return;
    }
    const agent = agents.find((a) => a.id === ticket?.manage?.userId);
    const agentFullName = agent
      ? `${agent?.name} ${agent?.lastname}`
      : "Unnamed Agent";
    const waitingTime =
      Number(ticket.status) < 1
        ? dayjs().unix() - ticket.created
        : ticket.sessionStart - ticket.created;
    const serviceName = servicesClone.find(
      (a) => a.id === ticket.serviceId
    )?.name;
    let totalTimeInBranch = null;
    if (ticket.status === TICKET_STATUS.COMPLETED) {
      totalTimeInBranch =
        Math.abs(
          dayjs
            .unix(ticket.sessionStart)
            .diff(dayjs.unix(ticket.created), "seconds")
        ) + ticket.sessionInSeconds;
    } else if (
      ticket.status === TICKET_STATUS.ACTIVE ||
      ticket.status === TICKET_STATUS.IN_QUEUE
    ) {
      totalTimeInBranch = dayjs().diff(dayjs.unix(ticket.created), "seconds");
    }

    const data = {
      customer,
      agentFullName,
      waitingTime,
      serviceName,
      totalTimeInBranch,
      ticket,
    };
    setData(data);
  }, [customerTicket, branchId]);

  //update serviceTime for active tickets
  useEffect(() => {
    const interval = setInterval(() => {
      //dont update anything at the end of work day, good idea to stop listeners
      const lastWorkTime = dayjs()
        .set("hour", workDay[workDay.length - 1].split(" ")[0] + 12) //convert to 24 hour notation, if workDay ends before 1PM, this will need to be changed
        .set("minute", 0)
        .add(1, "hour");
      const currentTime = dayjs();
      if (currentTime.isAfter(lastWorkTime)) {
        return;
      }

      //update waiting time
      if (Number(data?.ticket.status) < TICKET_STATUS.ACTIVE) {
        const { id: ticketId, created } = data.ticket;
        const customerWatingTime = document.querySelector(
          `#waitingTime-${ticketId}`
        );
        if (!customerWatingTime) return;
        const duration = dayjs().diff(dayjs.unix(created), "seconds");
        const formatedTime = formatTime(duration);
        customerWatingTime.innerHTML = formatedTime;
      }

      if (Number(data?.ticket?.status) === TICKET_STATUS.ACTIVE) {
        const { id: ticketId, sessionStart } = data.ticket;
        const customerServiceTime = document.querySelector(
          `#serviceTime-${ticketId}`
        );
        if (!customerServiceTime) return;
        const duration = dayjs().diff(dayjs.unix(sessionStart), "seconds");
        const formatedTime = formatTime(duration);
        customerServiceTime.innerHTML = formatedTime;
      }

      //update timeInBranch
      if (Number(data?.ticket.status) <= TICKET_STATUS.ACTIVE) {
        const { id: ticketId, status, created } = data.ticket;
        const timeInBranch = document.querySelector(
          `#timeInBranch-${ticketId}`
        );
        if (!timeInBranch) return;
        let totalTimeInBranch = null;
        if (
          Number(status) === TICKET_STATUS.ACTIVE ||
          Number(status) === TICKET_STATUS.IN_QUEUE
        ) {
          totalTimeInBranch = dayjs().diff(dayjs.unix(created), "seconds");
        }
        const formatedTime = formatTime(totalTimeInBranch);
        timeInBranch.innerHTML = formatedTime;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <Box>
      {fetchingTicket ? (
        <Loader />
      ) : (
        <Box>
          {_.isEmpty(data) ? (
            <Center>
              <Box
                outline="1px dotted"
                mt={10}
                outlineColor="gray.300"
                p={4}
                h="70px"
                borderRadius={10}
                w={{ base: "90%", md: "50%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Center>
                  <Text color="gray.500">
                    Search for ticket details by ticket number
                  </Text>
                </Center>
              </Box>
            </Center>
          ) : (
            <CustomerCard {...data} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default BranchTicketLookup;
