import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPastReport,
  getSubscriptions,
  setPastReportDateRange,
} from "../../../../store/actions/subscriptionsActions";
import SubscriptionSelection from "./components/SubscriptionSelection";
import _ from "lodash";
import { Box, Button } from "@chakra-ui/react";
import DatePicker from "../../../filterForm/DatePicker";
import { DownloadIcon } from "@chakra-ui/icons";

const PastReportsForm = () => {
  const { organizationId } = useSelector((store) => store.defaultReducer);
  const { userData } = useSelector((store) => store.authReducer);
  const {
    subscriptions,
    subscriptionId,
    downloadingReport,
    pastReportDateRange,
  } = useSelector((store) => store.subscriptionsReducer);
  const subscriptionsClone = _.cloneDeep(subscriptions);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(organizationId);
    dispatch(getSubscriptions(userData.id, organizationId));
  }, [organizationId]);

  const data = {
    subscription: subscriptionsClone.find((a) => a.id === subscriptionId),
    dateRange: pastReportDateRange,
  };

  const subscriptionReportRange = subscriptionsClone.find(
    (a) => a.id === subscriptionId
  )?.reportRange;
  return (
    <Box>
      <SubscriptionSelection />
      {subscriptionId.length > 0 ? (
        <Box mt={5}>
          <DatePicker
            onDateRangeChange={setPastReportDateRange}
            calendarType={subscriptionReportRange}
            selectedDateRange={pastReportDateRange}
          />
        </Box>
      ) : null}

      {subscriptionId.length > 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Button
            className="downloadButton"
            loadingText="Downloading..."
            isLoading={downloadingReport}
            size="md"
            onClick={() => {
              dispatch(downloadPastReport(data));
            }}
            leftIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default PastReportsForm;
