import dayjs from "dayjs";
import { ACTION_TYPES } from "../actions/types";
import { TICKET_STATUS } from "../constants";

const initialState = {
  tickets: [],
  ticket: {},
  ticketTransfers: [],
  agentSessions: [],
  customers: [],
  fetchingTicket: false,
  byDateTickets: [],
  isFetchingByDateTickets: false,
  isByDateTicketsReport: false,
  selectedTicketsStatus: TICKET_STATUS.IN_QUEUE,
  timeNow: dayjs().unix(),
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    //tickets
    case ACTION_TYPES.RT_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };

    case ACTION_TYPES.UPDATE_TIME_NOW:
      return {
        ...state,
        timeNow: dayjs().unix(),
      };

    case ACTION_TYPES.BY_DATE_TICKETS:
      return {
        ...state,
        byDateTickets: action.payload,
      };

    //numVisitorsInHour
    case ACTION_TYPES.UPDATE_VISITORS_IN_HOUR:
      return {
        ...state,
        visitorsInHour: action.payload,
      };

    //isFetchingByDateTickets
    case ACTION_TYPES.UPDATE_IS_FETCHING_BY_DATE_TICKETS:
      return {
        ...state,
        isFetchingByDateTickets: action.payload,
      };

    //isByDateTicketsReport
    case ACTION_TYPES.UPDATE_IS_BY_DATE_TICKETS_REPORT:
      return {
        ...state,
        isByDateTicketsReport: action.payload,
      };

    case ACTION_TYPES.UPDATE_ACTIVE_TICKETS: {
      return {
        ...state,
        tickets: state.tickets.map((ticket) => {
          const update = action.payload.find((a) => a.id === ticket.id);
          return update ? { ...ticket, ...update } : ticket;
        }),
      };
    }

    case ACTION_TYPES.LISTEN_TICKETS_CREATE:
      return {
        ...state,
        tickets: [...state.tickets, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_TICKETS_UPDATE:
      return {
        ...state,
        tickets: state.tickets.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_TICKETS_DELETE:
      return {
        ...state,
        tickets: state.tickets.filter((data) => data.id !== action.payload.id),
      };

    //ticketTransfers
    case ACTION_TYPES.GET_TICKET_TRANSFERS:
      return {
        ...state,
        ticketTransfers: action.payload,
      };

    case ACTION_TYPES.LISTEN_TICKET_TRANSFERS_CREATE:
      return {
        ...state,
        ticketTransfers: [...state.ticketTransfers, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_TICKET_TRANSFERS_UPDATE:
      return {
        ...state,
        ticketTransfers: state.ticketTransfers.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_TICKET_TRANSFERS_DELETE:
      return {
        ...state,
        ticketTransfers: state.ticketTransfers.filter(
          (data) => data.id !== action.payload.id
        ),
      };

    //agentSessions
    case ACTION_TYPES.GET_AGENT_SESSIONS:
      return {
        ...state,
        agentSessions: action.payload,
      };

    case ACTION_TYPES.LISTEN_AGENT_SESSIONS_CREATE:
      return {
        ...state,
        agentSessions: [...state.agentSessions, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_AGENT_SESSIONS_UPDATE:
      return {
        ...state,
        agentSessions: state.agentSessions.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_AGENT_SESSIONS_DELETE:
      return {
        ...state,
        agentSessions: state.agentSessions.filter(
          (data) => data.id !== action.payload.id
        ),
      };

    case ACTION_TYPES.UPDATE_AGENT_SESSIONS: {
      return {
        ...state,
        agentSessions: state.agentSessions.map((agent) => {
          const update = action.payload.find((a) => a.userId === agent.userId);
          return update ? { ...agent, ...update } : agent;
        }),
      };
    }

    //customers
    case ACTION_TYPES.GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    //for ticket lookup
    case ACTION_TYPES.CUSTOMER_TICKET_LOOKUP:
      return {
        ...state,
        customerTicket: action.payload,
      };
    case ACTION_TYPES.UPDATE_FETCHING_TICKET:
      return {
        ...state,
        fetchingTicket: action.payload,
      };

    case ACTION_TYPES.UPDATE_SELECTED_TICKETS_STATUS:
      return {
        ...state,
        selectedTicketsStatus: action.payload,
      };

    default:
      return state;
  }
}
