import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { LEVELS } from "../../../../../store/constants";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setActiveReportTypeId,
  setEditReportTypeId,
} from "../../../../../store/actions/subscriptionsActions";

import Select from "../../../../averly/Select";

const ReportTypesSelection = () => {
  const dispatch = useDispatch();
  const { reportTypes } = useSelector((store) => store.defaultReducer);
  const {
    subscription,
    isEditing,
    activeReportTypeId,
    editReportTypeId,
    level,
  } = useSelector((store) => store.subscriptionsReducer);
  const [showSelection, setShowSelection] = useState(false);
  const subscriptionClone = _.cloneDeep(subscription);

  const reportTypesClone = _.cloneDeep(reportTypes);
  const levelName = Object.keys(LEVELS)
    .find((key) => LEVELS[key] === level)
    ?.toLowerCase();
  const reportRangeStr = {
    day: "daily",
    week: "weekly",
    month: "monthly",
  }[subscriptionClone.reportRange];

  const options = reportTypesClone.filter(
    (a) => a.level === levelName && a.name.includes(reportRangeStr)
  );

  useEffect(() => {
    if (
      (level === LEVELS.ORGANIZATION && subscriptionClone.reportRange) ||
      (level === LEVELS.BRANCH && subscriptionClone.reportRange)
    ) {
      setShowSelection(true);
    } else {
      setShowSelection(false);
    }
  }, [level, subscriptionClone.reportRange]);

  return (
    <Box>
      {showSelection && level ? (
        <>
          <Box mt="10px">
            <Select
              label="Report Type"
              placeholder="Select Report Type"
              value={isEditing ? editReportTypeId : activeReportTypeId}
              options={options.map((a) => ({
                id: a.id,
                name: a.name
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase()),
              }))}
              onChange={(e) => {
                if (isEditing) {
                  dispatch(setEditReportTypeId(e));
                } else {
                  dispatch(setActiveReportTypeId(e));
                }
              }}
            />
          </Box>
        </>
      ) : <></>}
    </Box>
  );
};

export default ReportTypesSelection;
