import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  formatTime,
  generateHoursArray,
} from "../../../../store/helpers";
import { Box, Heading, SimpleGrid, Tag, Text } from "@chakra-ui/react";
import StatsCard from "../../../StatsCard";
import LineGraph from "../../../daily/LineGraph";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";
import { TICKET_STATUS } from "../../../../store/constants";

const BranchAverageSessionTimePerAgent = ({ branches }) => {
  const {
    workDay,
    servicesSubscriptions,
    services,
    users,
    organizationId,
    branchId,
  } = useSelector((state) => state.defaultReducer);
  const { tickets, timeNow } = useSelector((state) => state.realtimeReducer);
  const { iamRoles } = useSelector((state) => state.permissionsReducer);
  const agentRoleId = iamRoles.find((a) => a.name === "agent")?.id;

  let agents = _.cloneDeep(users).filter(
    (a) => Number(a.agentStatus) === 1 || a.roleId === agentRoleId
  );

  let ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.organizationId === organizationId
  );
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});

  const branchesData = Object.keys(branchesMap).map((branchId) => {
    const branchData = branchesMap[branchId];
    //agents for this branch
    const agentsMap = agents
      .filter((a) => a.branchId === branchId)
      .reduce((acc, agent) => {
        acc[agent.id] =
          branchData.filter((a) => a?.manage?.userId === agent.id) || [];
        return acc;
      }, {});

    const agentsData = Object.keys(agentsMap)
      .map((agentId) => {
        const agentTickets = agentsMap[agentId];
        const agent = agents.find((a) => a.id === agentId);
        const agentName = `${agent.name} ${agent.lastname}`;
        const numAgentServices =
          agents.find((a) => a.id === agentId)?.services.length || 0;

        const startTimeStamp = dayjs()
          .set("hour", workDay[0].split(" ")[0])
          .set("minute", 0)
          .unix();
        const endTimeStamp = timeNow;
        const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);
        const chart = hoursBetween.map((hour) => {
          const hourTickets = agentTickets.filter(
            (a) =>
              dayjs.unix(a.created).format("h A") === hour && a.sessionStart
          );

          const activeTickets = hourTickets.filter(
            (a) =>
              Number(a.status) !== 2 &&
              Number(a.status) === TICKET_STATUS.ACTIVE
          );
          const activeTicketsSessionTime = activeTickets.reduce((acc, a) => {
            return acc + dayjs().diff(dayjs.unix(a.sessionStart), "seconds");
          }, 0);

          const completedTicketsSessionTime =
            hourTickets.reduce(
              (acc, a) => acc + (a.sessionInSeconds || 0),
              0
            ) || 0;

          const totalSessionTime =
            activeTicketsSessionTime + completedTicketsSessionTime;

          if (workDay.includes(hour))
            return {
              hour,
              total: hourTickets.length,
              hourlyAverage:
                hourTickets.length > 0
                  ? Math.ceil(totalSessionTime / hourTickets.length) || 0
                  : null,
            };
          return null;
        });

        const min = Math.min(...chart.map((a) => a.hourlyAverage)) || 0;
        const max = Math.max(...chart.map((a) => a.hourlyAverage)) || 0;
        const totalNonNull = chart.filter((a) => a.hourlyAverage).length;
        const average =
          chart.reduce((a, b) => a + b.hourlyAverage, 0) / totalNonNull || 0;
        const total = chart.reduce((a, b) => a + b.total, 0) || 0;

        return {
          agentName,
          agentId,
          numAgentServices,
          min,
          max,
          total,
          average,
          chart,
        };
      })
      .sort((a, b) => b.total - a.total);
    let branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branchId
    )?.services;

    branchServices = checkServiceHasChildren(servicesClone)
      .filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      )
      .map((service) => service.id);
    const branchName =
      branches.find((branch) => branch.id === branchId)?.name ||
      `Branch-${branchId}`;
    const numAgents = agentsData.length || 0;

    return {
      branchName,
      branchServices,
      agentsData,
      numAgents,
      branchId,
    };
  });

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              { branchName, agentsData, branchServices, numAgents, branchId },
              i
            ) => {
              return (
                <Box key={branchId}>
                  {agentsData?.map(
                    (
                      {
                        agentName,
                        chart,
                        total,
                        min,
                        max,
                        average,
                        numAgentServices,
                        agentId,
                      },
                      i
                    ) => {
                      const stats = [
                        {
                          title: "Average Session Time Today",
                          stat: formatTime(average),
                        },
                        {
                          title: "Max Average Session Time Per Hour",
                          stat: formatTime(max),
                        },
                        {
                          title: "Average Session Time this hour",
                          stat: formatTime(
                            chart.find((a) => a.hour === dayjs().format("h A"))
                              ?.hourlyAverage || 0
                          ),
                        },
                      ];
                      return (
                        <Box key={agentId} mt="20px">
                          <Heading fontSize="24px" color="var(--black-dry)">{`${
                            i + 1
                          }. ${agentName}`}</Heading>
                          <Text fontSize="14px" color="var(--gray-8)">
                            {numAgentServices} services and {total} tickets
                          </Text>

                          {total > 0 ? (
                            <>
                              <Box mt={4}>
                                <SimpleGrid
                                  columns={{ base: 1, md: 3 }}
                                  spacing="0"
                                  mb={"10px"}
                                >
                                  {stats.map((a, i) => (
                                    <Box
                                      key={i}
                                      px="20px"
                                      py="15px"
                                      mb="5px"
                                      border="1px solid var(--borderGrey)"
                                      borderRight={{
                                        base: "1px solid #E5E4E5",
                                        md: [2, 5].includes(i)
                                          ? "1px solid #E5E4E5"
                                          : "none",
                                      }}
                                      borderRadius={{
                                        base: "10px",
                                        md: [0, 3].includes(i)
                                          ? "10px 0 0 10px"
                                          : [2, 5].includes(i)
                                          ? "0 10px 10px 0"
                                          : "0",
                                      }}
                                    >
                                      <StatsCard
                                        title={a.title}
                                        stat={a.stat}
                                        statNumberId={a.statNumberId}
                                        icon={a.icon}
                                        iconColor={a.iconColor}
                                      />
                                    </Box>
                                  ))}
                                </SimpleGrid>
                              </Box>

                              <Box mt="20px" mb="20px">
                                <LineGraph
                                  chart={chart}
                                  lineDataKey="hourlyAverage"
                                  title={`${agentName} Average Session Time, Hour by Hour Comparison`}
                                  lineName="Average Session Time In Seconds"
                                />
                              </Box>
                            </>
                          ) : (
                            <Text>No tickets for this agent</Text>
                          )}
                        </Box>
                      );
                    }
                  )}
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchAverageSessionTimePerAgent;
