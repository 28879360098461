import React, { useEffect, useState } from "react";
import { Box, Heading, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import _ from "lodash";

import FilterForm from "../../../components/FilterForm";
import OrganizationTickets from "../../../components/organization/reports/daily/OrganizationTickets";
import { getReportDescription } from "../../../store/helpers";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import { Helmet } from "react-helmet";

const DailyOrganizationTickets = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_organization_tickets;
  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  const reportTitle = ` Organization Tickets`;
  console.log(reportTitle);
  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Organization Tickets</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : null}
      {!_.isEmpty(data) ? (
        <Box mb="20px">
          <Heading fontSize="34px" color="#222" fontWeight="700">
            {reportTitle}

            <Tooltip
              label={getReportDescription(reportRange).Tickets}
              aria-label="A tooltip"
            >
              <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
            </Tooltip>
          </Heading>
        </Box>
      ) : null}
      {!_.isEmpty(data) ? <OrganizationTickets data={data[0]} /> : null}
    </Box>
  );
};

export default DailyOrganizationTickets;
