import React from "react";
import { Box } from "@chakra-ui/react";

// Filters
import FilterForm from "../../../components/FilterForm";

// Reports
// organization
import DailyBranchAgentServiceTimePerService from "../../branches/daily/DailyBranchAgentServiceTimePerService";
import DailyBranchAgentTicketsPerService from "../../branches/daily/DailyBranchAgentTicketsPerService";
import DailyBranchTicketsPerAgent from "../../branches/daily/DailyBranchTicketsPerAgent";
import { Helmet } from "react-helmet";

const DailyFullReportAgentPerformance = () => {
  const report = [
    <DailyBranchTicketsPerAgent />,
    <DailyBranchAgentTicketsPerService />,
    <DailyBranchAgentServiceTimePerService />,
  ];
  return (
    <Box>
        <Helmet>
            <title>Agent Performance Daily Full Report</title>
        </Helmet>
      <FilterForm />
      {report.map((a, index) =>
        React.cloneElement(a, {
          key: index,
          
          showFilterForm: 0,
          showTitle: 0,
        })
      )}
    </Box>
  );
};

export default DailyFullReportAgentPerformance;
