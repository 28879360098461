import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import LineGraph from "../../../daily/LineGraph";
import StatsCard from "../../../StatsCard";
import { TICKET_STATUS } from "../../../../store/constants";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

const BranchAbondonmentRate = ({ branches }) => {
  const { workDay, servicesSubscriptions, services, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const { tickets } = useSelector((state) => state.realtimeReducer);
  let ticketsClone = _.cloneDeep(tickets);

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});
  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;

      branchServices = checkServiceHasChildren(servicesClone)
        .filter(
          (service) =>
            branchServices?.includes(service.id) && !service.hasChildren
        )
        .map((service) => service.id);
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      const startTimeStamp = dayjs()
        .set("hour", workDay[0].split(" ")[0])
        .set("minute", 0)
        .unix();
      const endTimeStamp = dayjs().unix();
      const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);

      const chart = hoursBetween
        .map((hour) => {
          const hourTickets = branchData.filter(
            (a) => dayjs.unix(a.created).format("h A") === hour
          );
          const noShowHourTickets = hourTickets.filter(
            (a) => Number(a.status) === TICKET_STATUS.NO_SHOW
          );

          const abondonmentRate =
            Number(
              ((noShowHourTickets.length / hourTickets.length) * 100).toFixed(2)
            ) || null;

          if (workDay.includes(hour))
            return {
              abondonmentRate,
              hour,
              total: hourTickets.length,
            };
          return null;
        })
        .filter(Boolean);

      const min =
        Math.min(
          ...chart
            .filter((a) => a.abondonmentRate !== null)
            .map((a) => a.abondonmentRate)
        ) || 0;
      const max =
        Math.max(
          ...chart
            .filter((a) => a.abondonmentRate !== null)
            .map((a) => a.abondonmentRate)
        ) || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;
      const averageAbondonmentRate =
        chart.reduce((a, b) => a + b.abondonmentRate, 0) /
          chart.filter((a) => a.abondonmentRate !== null).length || null;

      return {
        branchName,
        branchServices,
        chart,
        min,
        max,
        total,
        averageAbondonmentRate,
      };
    })
    .sort((a, b) => b.averageAbondonmentRate - a.averageAbondonmentRate);

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              {
                branchName,
                chart,
                min,
                max,
                branchServices,
                averageAbondonmentRate,
              },
              i
            ) => {
              const abondonmentRateThisHour = chart.find(
                (a) => a.hour === dayjs().format("h A")
              )?.abondonmentRate;

              const stats = [
                {
                  title: 'Abondonment Rate',
                  stat: averageAbondonmentRate !== null && !Number.isNaN(averageAbondonmentRate) ? averageAbondonmentRate.toFixed(2) + "%" : "NA",
                },
                {
                  title: 'Max Abondonment Rate Per Hour',
                  stat: max || max >= 0 ? max.toFixed(2) + "%" : "NA",
                },
                {
                  title: 'Min Abondonment Rate Per Hour',
                  stat: min || min >= 0 ? min.toFixed(2) + "%" : "NA",
                },
                {
                  title: 'Abondonment Rate This Hour',
                  stat: abondonmentRateThisHour || abondonmentRateThisHour >= 0 ? min.toFixed(2) + "%" : "NA",
                },
              ];
              return (
                <Box>
                  {averageAbondonmentRate !== null &&
                  !Number.isNaN(averageAbondonmentRate) ? (
                    <>
                      <Box mt={4}>
                        <SimpleGrid
                          columns={{ base: 1, md: 4 }}
                          spacing="0"
                          mb={"10px"}
                        >
                          {
                            stats.map((a, i) => (
                              <Box
                                key={i}
                                px="20px"
                                py="15px"
                                mb="5px"
                                border="1px solid var(--borderGrey)"
                                borderRight={{
                                  base: '1px solid #E5E4E5',
                                  md: [3, 7].includes(i) ? '1px solid #E5E4E5' : 'none',
                                }}
                                borderRadius={{
                                  base: '10px',
                                  md: [0, 4].includes(i)
                                    ? '10px 0 0 10px'
                                    : [3, 7].includes(i)
                                      ? '0 10px 10px 0'
                                      : '0',
                                }}
                              >
                                <StatsCard title={a.title} stat={a.stat} statNumberId={a.statNumberId} icon={a.icon} iconColor={a.iconColor} />
                              </Box>
                            ))
                          }
                        </SimpleGrid>
                      </Box>

                      <Box mt="20px" mb="20px">
                        <LineGraph
                          chart={chart}
                          title={`${branchName} Abondonment Rate, Hour by Hour Comparison`}
                          lineDataKey="abondonmentRate"
                          lineName="Abondonment Rate(%)"
                        />
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <Text>No data available for this branch</Text>
                    </Box>
                  )}
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchAbondonmentRate;
