import LineGraph from "../../LineGraph";
import ArrivalRatesPerDayTable from "../tables/ArrivalRatesPerDayTable";

import { Box, Divider } from "@chakra-ui/react";
import TimeOfDayChart from "../../TimeOfDayChart";

const ArrivalRatesPerHour = ({  data:reportData }) => {
  const title = `1. Entire Organization`;
  

  const {average, min, max, total, chart, timeOfDayChart} = reportData;

  return (
    <>
      <Box className="reportContainer" mt="20px">
        <ArrivalRatesPerDayTable
          average={average}
          min={min}
          max={max}
          total={total}
          title={title}
        />

        <Box mt="20px" mb="20px">
          <LineGraph
            chart={chart}
            height={"300px"}
            title={"Entire Organization Arrival Rates, Day by Day Comparison"}
          />
        </Box>
        <Divider />
        <TimeOfDayChart
          {...timeOfDayChart}
          title={"Time of Day Chart"}
        />
      </Box>
    </>
  );
};

export default ArrivalRatesPerHour;
