import axios from "axios";
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import { ACTION_TYPES } from "./types";
import dayjs from "dayjs";
import { getReportTypeFromId } from "../helpers";

// update reportSubsbcription
export const updateReportSubscription = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_REPORT_SUBSCRIPTION,
    payload,
  });
};

//update OrganizationId
export const updateOrganizationId = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_ORGANIZATION_ID,
    payload,
  });
};

//update level
export const updateLevel =
  (payload, resetReport = true) =>
  (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_LEVEL,
      payload,
    });
    // reset reportTypeId

    if (resetReport) {
      dispatch(setActiveReportTypeId(""));
      dispatch(setEditReportTypeId(""));
      // reset selected branches
      dispatch(setSelectedBranches([]));
    }
  };

//update branchIds
export const updateBranchIds = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_BRANCH_IDS,
    payload,
  });
};

//update report range
export const updateReportRange = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SUB_REPORT_RANGE,
    payload,
  });
};

//update reportTypeId
export const updateReportTypeId = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_REPORT_TYPE_ID,
    payload,
  });
};

//update reports
export const updateReports = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_REPORTS,
    payload,
  });
};

//SET_ADD_NEW_REPORT
export const setAddNewReport = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADD_NEW_REPORT,
    payload,
  });
};

export const createSubscription = (data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: true });
  try {
    const baseUrl =
      process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
    const endpoint = `${baseUrl}/reports/subscriptions`;
    const res = await axios.post(endpoint, data);

    toast.success("Successfully Saved Subscription");
    dispatch(setSaveSuccessful(true));
  } catch (err) {
    toast.error("Could not Save Subscription");
  }
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: false });
};

export const updateSubscription = (data, id) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: true });
  try {
    const baseUrl =
      process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
    const endpoint = `${baseUrl}/reports/subscriptions/${id}`;
    const res = await axios.patch(endpoint, data);

    toast.success("Successfully Saved Subscription");
    dispatch(setSaveSuccessful(true));
  } catch (err) {
    toast.error("Could not Save Subscription");
  }
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: false });
};

export const deleteSubscription = (id) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: true });
  try {
    const baseUrl =
      process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
    const endpoint = `${baseUrl}/reports/subscriptions/id/${id}`;
    const res = await axios.delete(endpoint);

    toast.success("Successfully Deleted Subscription");
    dispatch(setSaveSuccessful(true));
  } catch (err) {
    toast.error("Could not delete Subscription");
  }
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: false });
};

//set save successful
export const setSaveSuccessful = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SAVE_SUCCESSFUL,
    payload,
  });
};

//set subscriptions
export const setSubscriptions = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SUBSCRIPTIONS,
    payload,
  });
};

//SET_SUBSCRIPTION
export const setSubscription = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUBSCRIPTION, payload });
};

//paginate active report index
export const paginateActiveReportIndex =
  (totalReports, currentIndex) => (dispatch) => {
    let newIndex = 0;
    if (currentIndex < totalReports - 1) {
      newIndex = currentIndex + 1;
    }
    dispatch({ type: ACTION_TYPES.SET_ACTIVE_REPORT_INDEX, payload: newIndex });
  };

//SET ACTIVE REPORT INDEX
export const setActiveReportIndex = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_ACTIVE_REPORT_INDEX, payload });
};

//GET_SUBSCRIPTIONS
export const getSubscriptions = (userId, organizationId) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: true });
  const baseUrl =
    process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
  const endpoint = `${baseUrl}/reports/subscriptions?userId=${userId}&organizationId=${organizationId}`;
  const res = await axios.get(endpoint);
  dispatch(setSubscriptions(res.data));
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload: false });
};

//download report
export const downloadReport = (data) => async (dispatch) => {
  const { dateRange, reportTypeId } = data;

  if(!dateRange)
  {
    toast.error('Please select a date range.')
    return;
  }

  data.startDate = dayjs.unix(dateRange[0]).format("DD-MM-YYYY");
  delete data.dateRange;
  const reportType = getReportTypeFromId(reportTypeId);

  // Don't send branchId if reportType is organization
  if (reportType.split('_')[1] === 'organization') {
    delete data.branchId;
  }

  const baseUrl = process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
  const endpoint = `${baseUrl}/qm/download`;

  dispatch({ type: ACTION_TYPES.UPDATE_DOWNLOADING_REPORT, payload: true });
  try {
    const res = await axios.get(endpoint, {
      params: data,
      responseType: 'blob'
    });

    
    const blob = new Blob([res.data], { type: res.headers['content-type'] });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${reportType}_report.pdf`; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading the report:', error);
  }
  dispatch({ type: ACTION_TYPES.UPDATE_DOWNLOADING_REPORT, payload: false });
};

//SET_ADD_REPORT
export const setAddReport = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_ADD_REPORT, payload });
};

//SET_SUB_LOADING
export const setSubLoading = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUB_LOADING, payload });
};

export const updateLocalSubscription = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SUBSCRIPTION, payload });
};

//SET_ACTIVE_REPORT_TYPE_ID
export const setActiveReportTypeId = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_ACTIVE_REPORT_TYPE_ID, payload });
};

//SET_IS_EDITING
export const setIsEditing = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_IS_EDITING, payload });
};

//SET_EDIT_REPORT_TYPE_ID
export const setEditReportTypeId = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_EDIT_REPORT_TYPE_ID, payload });
};

//UPDATE_SUBSCRIPTION_ID
export const updateSubscriptionId = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.UPDATE_SUBSCRIPTION_ID, payload });
};

//SET_SELECTED_BRANCHES
export const setSelectedBranches = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SELECTED_BRANCHES, payload });
};

// SET_PAST_REPORT_DATE_RANGE
export const setPastReportDateRange = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_PAST_REPORT_DATE_RANGE, payload });
};

//download report
export const downloadPastReport = (data) => async (dispatch) => {
  if(!data.subscription)
  {
    toast.error('Please select a subscription.')
    return;
  }

  if(!data.dateRange)
  {
    toast.error('Please select a date range.')
    return;
  }

  const { subscription, dateRange } = data;
  const {reportRange, subscriptionName} = subscription;

  let startDate = null;
  let endDate = null
  switch(reportRange)
  {
    case 'day':
      startDate = dayjs.unix(dateRange[0]).format("DD-MM-YYYY");
      endDate = startDate;
      break;
    case 'week':
      startDate = dayjs.unix(dateRange[0]).startOf('week').format("DD-MM-YYYY");
      endDate = dayjs.unix(dateRange[0]).endOf('week').format("DD-MM-YYYY");
      break;
    case 'month':
      startDate = dayjs.unix(dateRange[0]).startOf('month').format("DD-MM-YYYY");
      endDate = dayjs.unix(dateRange[0]).endOf('month').format("DD-MM-YYYY");
      break;
    default:
      break;
  }
  data.startDate = startDate;
  data.endDate = endDate;
  delete data.dateRange;

  const baseUrl = process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
  const endpoint = `${baseUrl}/reports/subscriptions/download`;

  dispatch({ type: ACTION_TYPES.UPDATE_DOWNLOADING_REPORT, payload: true });
  try {
    const res = await axios.post(endpoint, data, {
      responseType: 'blob'
    });

    
    const blob = new Blob([res.data], { type: res.headers['content-type'] });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${subscriptionName}_report.pdf`; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    toast.error('Error downloading the report');
    console.error('Error downloading the report:', error);
  }
  dispatch({ type: ACTION_TYPES.UPDATE_DOWNLOADING_REPORT, payload: false });
};

//RESET FORM
export const resetForm = () => (dispatch) => {
  dispatch(updateReportTypeId(""));
  dispatch(setAddReport(false));
  dispatch(setSubscription({}));
  dispatch(setSubLoading(false));
  dispatch(updateLocalSubscription({}));
  dispatch(setActiveReportTypeId(""));
  dispatch(setEditReportTypeId(""));
  dispatch(setIsEditing(false));
  dispatch(setSaveSuccessful(false));
  dispatch(updateLevel(""));
  dispatch(setSelectedBranches([]));
};
