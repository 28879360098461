import _ from "lodash";
import { useSelector } from "react-redux";
import { checkServiceHasChildren } from "../../../../store/helpers";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import StatsCard from "../../../StatsCard";
import AgentCard from "../../../AgentCard";
import dayjs from "dayjs";
import { useEffect } from "react";
import { formatTime } from "../../../../store/helpers";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

const BranchAgentStatus = ({ branches }) => {
  const { servicesSubscriptions, services, users, organizationId, branchId } =
    useSelector((state) => state.defaultReducer);
  const { agentSessions } = useSelector((state) => state.realtimeReducer);
  const { iamRoles } = useSelector((state) => state.permissionsReducer);
  const agentRoleId = iamRoles.find((a) => a.name === "agent")?.id;

  let agents = _.cloneDeep(users)
    .filter((a) => Number(a.agentStatus) === 1 || a.roleId === agentRoleId)
    .filter((a) => a.organizationId === organizationId);
  const agentSessionsClone = _.cloneDeep(agentSessions).filter(
    (a) => a.organizationId === organizationId
  );
  const availableAgentsIds = agents.map((a) => a.id); //some agents in the agentSessions might be deleted

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = agentSessionsClone.filter(
      (a) => a.branchId === branchId && availableAgentsIds.includes(a.userId)
    );
    return acc;
  }, {});
  const branchesData = Object.keys(branchesMap).map((branchId) => {
    const branchAgents = branchesMap[branchId];
    //agents for this branch
    const agentsData = branchAgents
      .map(({ status, userId, updated }) => {
        const agent = agents.find((a) => a.id === userId);
        const agentName = agent
          ? `${agent?.name} ${agent?.lastname}`
          : "Deleted User";
        const duration = dayjs().diff(dayjs.unix(updated), "seconds");
        return {
          agentName,
          status,
          duration,
          userId,
        };
      })
      .sort((a, b) => {
        if (b.status === a.status) {
          return b.duration - a.duration;
        }
        return b.status - a.status;
      });
    const numActiveAgents = agentsData.filter((a) => a.status === 1).length;
    const numLoggedOutAgents = agentsData.filter((a) => a.status === 0).length;

    let branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branchId
    )?.services;

    branchServices = checkServiceHasChildren(servicesClone)
      .filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      )
      .map((service) => service.id);
    const branchName =
      branches.find((branch) => branch.id === branchId)?.name ||
      `Branch-${branchId}`;
    const numAgents = agentsData.length || 0;

    return {
      branchName,
      branchServices,
      agentsData,
      numAgents,
      numActiveAgents,
      numLoggedOutAgents,
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      //loop through agentSessionsClone
      agentSessionsClone.forEach(({ userId, updated }) => {
        const agentFormatedTime = document.querySelector(
          `#formartedTime-${userId}`
        );
        if (!agentFormatedTime) return;
        const duration = dayjs().diff(dayjs.unix(updated), "seconds");
        const formatedTime = formatTime(duration);
        agentFormatedTime.innerHTML = formatedTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              {
                branchName,
                agentsData,
                numAgents,
                numActiveAgents,
                numLoggedOutAgents,
              },
              i
            ) => {
              return (
                <Box>
                  <h2>{`${branchName}`}</h2>
                  <Box mt={4}>
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      spacing={{ base: 5, lg: 8 }}
                      mb={10}
                    >
                      <StatsCard
                        title={"Number of Agents"}
                        stat={`${numAgents}`}
                      />
                      <StatsCard
                        title={"Number of Active Agents"}
                        stat={`${numActiveAgents}`}
                      />
                      <StatsCard
                        title={"Number of Logged Out Agents"}
                        stat={numLoggedOutAgents}
                      />
                    </SimpleGrid>
                  </Box>

                  {agentsData?.map(
                    ({ agentName, status, duration, userId }, i) => {
                      return (
                        <Box key={userId}>
                          <AgentCard
                            agentName={agentName}
                            status={status}
                            duration={duration}
                            userId={userId}
                          />
                        </Box>
                      );
                    }
                  )}
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchAgentStatus;
