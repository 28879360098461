import _ from "lodash";
import ServiceArrivalRatesTable from "../ServiceArrivalRatesTable";


const ArrivalRatesByService = ({ data }) => {

  return (
    <>
      {data.map((serviceData, index) => {
        return (
          <>
            <ServiceArrivalRatesTable
              index={index}
              
              serviceData={serviceData}
            />
          </>
        );
      })}
    </>
  );
};

export default ArrivalRatesByService;
