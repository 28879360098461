import {
  Box,
  Button,
  Divider,
  Heading,
  Icon,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  IoCloseCircleOutline,
} from "react-icons/io5";

import ReportsSubscriptions from "../../components/reports/subscriptions/Subscriptions";
import DrawerComponent from "../../components/reports/subscriptions/misc/Drawer";
import { updateDrawer } from "../../store/actions/miscAction";
import { useEffect } from "react";
import { getSubscriptions } from "../../store/actions/subscriptionsActions";

const Subscriptions = () => {
  const { userData } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const openDrawer = () => {
    const drawer = {
      title: "Create a Reports Subscription",
      isOpen: 1,
      content: { component: "SubscriptionForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };

  const { subscriptions } = useSelector((state) => state.subscriptionsReducer);
  const { organizationId } = useSelector((state) => state.defaultReducer);

  useEffect(() => {
    dispatch(getSubscriptions(userData.id, organizationId));
  }, [userData, organizationId]);

  return (
    <Box>
      <Box>
        <Heading fontSize="34px" color="#222" fontWeight="700">
          Reports Subscriptions
        </Heading>
        <Text fontSize="16px" color="#666" fontWeight={400} mt="10px">
          Our Report Subscription Tool automates the delivery of customized
          reports to your inbox at your chosen schedule. Save time, stay
          informed, and ensure secure access to up-to-date information with
          minimal effort.
        </Text>
        <Divider m="20px 0 30px" borderColor="#ebebeb" />
      </Box>

      <DrawerComponent />

      {subscriptions.length ? (
        <Box>
          <Button
            bg={"var(--black-dry)"}
            mb="20px"
            color={"white"}
            _hover={{ bg: "var(--blue)" }}
            _active={{ bg: "var(--blue)" }}
            fontWeight={400}
            fontSize="16px"
            onClick={openDrawer}
          >
            Create another Subscription
          </Button>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="10px">
            You have {subscriptions.length} active subscription
            {subscriptions.length === 1 ? "" : "s"}.
          </Text>
          <Box mt={5} mb={5}></Box>
          <ReportsSubscriptions />
        </Box>
      ) : (
        <VStack mt="100px">
          <Box w="128px" h="128px">
            <Icon
              as={IoCloseCircleOutline}
              boxSize="128px"
              color="var(--red-pale)"
            />
          </Box>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            You have no active subscriptions.
          </Text>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            <Link color="var(--blue)" onClick={openDrawer}>
              Create a Report Subscription
            </Link>{" "}
            to get started.
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default Subscriptions;
