import React from "react";
import { Box } from "@chakra-ui/react";

// Filters
import FilterForm from "../../../components/FilterForm";

// Reports
import DailyBranchArrivalRatesPerHour from "../../branches/daily/DailyBranchArrivalRatesPerHour";
import DailyBranchArrivalRatesPerService from "../../branches/daily/DailyBranchArrivalRatesPerService";
import { Helmet } from "react-helmet";

const DailyFullReportArrivalRates = () => {
  const report = [
    <DailyBranchArrivalRatesPerHour />,
    <DailyBranchArrivalRatesPerService />,
  ];
  return (
    <Box>
      <Helmet>
        <title>Arrival Rates Daily Full Report</title>
      </Helmet>
      <FilterForm />
      {report.map((a, index) =>
        React.cloneElement(a, {
          key: index,
          
          showFilterForm: 0,
          showTitle: 0,
        })
      )}
    </Box>
  );
};

export default DailyFullReportArrivalRates;
