//RESET_DRAWER
export const resetDrawer = () => (dispatch) => {
  dispatch({
    type: "RESET_DRAWER",
    payload: {},
  });
}

//UPDATE_DRAWER
export const updateDrawer = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_DRAWER",
    payload,
  });
};

//UPDATE_DELETE_MODAL
export const updateDeleteModal = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_DELETE_MODAL",
    payload,
  });
};

//RESET_DELETE_MODAL
export const resetDeleteModal = () => (dispatch) => {
  dispatch({
    type: "RESET_DELETE_MODAL",
    payload: {},
  });
};