import {
  Flex,
  IconButton,
} from "@chakra-ui/react";

import { FiMenu } from "react-icons/fi";
import UserAvatar from "./header/UserAvatar";
import DrawerComponent from "./reports/subscriptions/misc/Drawer";

const Header = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      bg="var(--white)"
      borderBottom="1px solid #ebebeb"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <DrawerComponent />
      <UserAvatar />
    </Flex>
  );
};

export default Header;
