import { Box, Text } from "@chakra-ui/react";
import ArrivalRatesPerHourTable from "../tables/ArrivalRatesPerHourTable";
import { ARRIVAL_RATES_FREQUENCIES } from "../../../store/constants";

const ArrivalRatesPerHour = ({ data }) => {
  return (
    <Box>
      {data.map((data, index) => {
        const { branchName, numServices } = data;
        const title = `${index + 1}. ${branchName}`;
        
        return (
          <Box key={index}>
            <h2>{title}</h2>
            <Text>{numServices} services</Text>
            <ArrivalRatesPerHourTable
              data={data}
              index={index}
              frequency={ARRIVAL_RATES_FREQUENCIES.HOUR}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ArrivalRatesPerHour;
