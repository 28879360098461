import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  IoLayersOutline,
  IoCheckmarkCircleOutline,
  IoTimeOutline,
  IoPeopleOutline,
  IoIdCardOutline,
} from "react-icons/io5";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import useCustomers from "../../../../hooks/reports/branches/realtime/useCustomers";
import StatsCard from "../../../StatsCard";
import { formatTime } from "../../../../store/helpers";
import useTickets from "../../../../hooks/reports/branches/realtime/useTickets";
import LineGraph from "../../../daily/LineGraph";
import useAgents from "../../../../hooks/reports/branches/realtime/useAgents";
import useServices from "../../../../hooks/reports/branches/realtime/useServices";
import AgentsOverviewCard from "../../../organization/misc/AgentsOverviewCard";
import ServiceOverviewCard from "../../misc/ServiceOverViewCard";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

function BranchLiveDahboard({ branches }) {
  BranchLiveDahboard.propTypes = {
    branches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };
  
  const [limitIndexes, setLimitIndexes] = useState({
    agentLimit: 1,
    serviceLimit: 1,
  });

  const { branchId } = useSelector((state) => state.defaultReducer);
  const { numQueued, maxQueueTimeNow, ticketNumOfLongestQueueingCustomer } = useCustomers({
    branchId,
  });
  const { numAgents, numOnline, agentsData } = useAgents({ branchId });
  const {
    totalTickets,
    numCompletedTickets,
    numActiveTickets,
    chart: ticketsChart,
  } = useTickets({
    branchId,
  });
  const { servicesData } = useServices({ branchId });
  const branchName = branches.find((branch) => branch.id === branchId)?.name;

  const stats = [
    {
      title: "Total",
      stat: totalTickets || 0,
      icon: IoLayersOutline,
      iconColor: "var(--green)",
    },
    {
      title: "Completed",
      stat: numCompletedTickets || 0,
      icon: IoCheckmarkCircleOutline,
      iconColor: "var(--blue-classic)",
      filterStatus: 3,
      to: "/realtime/branches/customers-in-queue",
    },
    {
      title: "Customers Being Served Now",
      stat: numActiveTickets || 0,
      icon: IoPeopleOutline,
      iconColor: "var(--green)",
      filterStatus: 1,
      to: "/realtime/branches/customers-in-queue",
    },
    {
      title: "Customers In Queue",
      stat: numQueued,
      icon: IoPeopleOutline,
      iconColor: "var(--orange)",
      filterStatus: 0,
      to: "/realtime/branches/customers-in-queue",
    },
    {
      title: "Max. Queueing Time",
      stat:
        !Number.isNaN(maxQueueTimeNow) && Number.isFinite(maxQueueTimeNow)
          ? `${formatTime(
            maxQueueTimeNow
          )} | ${ticketNumOfLongestQueueingCustomer}`
          : "NA",
      icon: IoTimeOutline,
      iconColor: "var(--red)",
      to: "/realtime/branches/customers-in-queue",
    },
    {
      title: "Online Agents",
      stat: `${numOnline}/${numAgents}`,
      icon: IoIdCardOutline,
      iconColor: "var(--black-dry)",
    },
  ];

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box mt={4}>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={0} mb={10}>
            {stats.map((a, i) => {
              const borderRightValue = [2, 5].includes(i)
                ? "1px solid #E5E4E5"
                : "none";

              let borderRadiusValue = 0;
              if ([0, 3].includes(i)) {
                borderRadiusValue = "10px 0 0 10px";
              } else if ([2, 5].includes(i)) {
                borderRadiusValue = "0 10px 10px 0";
              }

              return (
                <Box
                  key={uuid()}
                  px="20px"
                  py="15px"
                  mb="5px"
                  border="1px solid var(--borderGrey)"
                  borderRight={{
                    base: "1px solid #E5E4E5",
                    md: borderRightValue,
                  }}
                  borderRadius={{
                    base: "10px",
                    md: borderRadiusValue,
                  }}
                >
                  <StatsCard
                    title={a.title}
                    stat={a.stat}
                    icon={a.icon}
                    iconColor={a.iconColor}
                    to={a.to}
                  />
                </Box>
              );
            })}
          </SimpleGrid>
          <Box>
            <LineGraph
              chart={ticketsChart}
              title={`${branchName} branch arrival rates, Hour by Hour comparison`}
              lineName="Tickets"
              lineDataKey="total"
            />
          </Box>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 5, lg: 8 }}
            mb={10}
          >
            <Box mt={"40px"}>
              {
                servicesData?.map((a, i) => 
                  i < (limitIndexes.serviceLimit ? 6 : servicesData?.length)
                    ? <ServiceOverviewCard
                      key={a.serviceId}
                      data={a}
                      index={i}
                      totalIndexes={ 
                        servicesData?.length > 6
                          ? Number(limitIndexes.serviceLimit) 
                            ? 6
                            : servicesData?.length || 0
                          : servicesData?.length || 0
                      }
                    />
                    : null
                )
              }
              <Text
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setLimitIndexes({...limitIndexes, serviceLimit: !Number(limitIndexes.serviceLimit)})}
                width={"100%"}
                textAlign={"center"}
                mt={"20px"}
              >
                {
                  servicesData?.length > 6
                    ? Number(limitIndexes.agentLimit) ? "View More" : "View Less"
                    : null
                }
              </Text>
            </Box>
            <Box mt={"40px"}>
              {
                agentsData?.map((a, i) => 
                  i < (limitIndexes.agentLimit ? 6 : agentsData?.length)
                    ? <AgentsOverviewCard
                      key={a.agentId}
                      data={a}
                      index={i}
                      totalIndexes={ 
                        agentsData?.length > 6
                          ? Number(limitIndexes.agentLimit)
                            ? 6
                            : agentsData?.length || 0
                          : agentsData?.length || 0
                      }
                    />
                    : null
                )
              }
              <Text
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setLimitIndexes({...limitIndexes, agentLimit: !Number(limitIndexes.agentLimit)})}
                width={"100%"}
                textAlign={"center"}
                mt={"20px"}
              >
                {
                  agentsData?.length > 6
                    ? Number(limitIndexes.agentLimit) ? "View More" : "View Less"
                    : null
                }
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
}

export default BranchLiveDahboard;
