import { connect } from "react-redux";
import React from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import OrganizationTicketsLineGraph from "../../charts/daily/OrganizationTicketsLineGraph";

const { formatNumber } = require("../../../../store/helpers");

const OrganizationTicketsTable = ({ table, chart }) => {
  return (
    <Box mt={10}>
      <Box>
        {table.map((a, index) => (
          <Box className="reportContainer">
            <Box mb="20px">
              <Heading fontSize="30px" color="#222" fontWeight="700">
                {index + 1}. {a.name}
              </Heading>
            </Box>
            <Table key={index} className="stripedTable" size="sm">
              <Thead>
                <Tr>
                  <Th borderRight="1px solid #dadce0">Total Tickets</Th>
                  <Th>Avg /Hour.</Th>
                  <Th>Min.</Th>
                  <Th borderRight="1px solid #dadce0">Max</Th>
                  <Th>Completed</Th>
                  <Th>No Show</Th>
                  <Th>Unattended</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td borderRight="1px solid #dadce0">
                    <Text color={"var(--blue)"}>{formatNumber(a.total)}</Text>
                  </Td>
                  <Td>
                    <Text>{formatNumber(a.averageTicketsPerHour)}</Text>
                  </Td>
                  <Td>
                    <Text>{formatNumber(a.minTicketsPerHour)}</Text>
                  </Td>
                  <Td borderRight="1px solid #dadce0">
                    <Text>{formatNumber(a.maxTicketsPerHour)}</Text>
                  </Td>
                  <Td>
                    <Text color={"var(--green)"}>
                      {formatNumber(a.completed)}
                      <Box
                        color="gray.500"
                        fontWeight="semibold"
                        fontSize="xs"
                        textTransform="uppercase"
                      >
                        ({a.pctCompleted.toFixed(2)}%)
                      </Box>
                    </Text>
                  </Td>
                  <Td>
                    <Text cursor="pointer" color={"var(--red)"}>
                      {formatNumber(a.noShow)}
                      <Box
                        color="gray.500"
                        fontWeight="semibold"
                        fontSize="xs"
                        textTransform="uppercase"
                      >
                        ({a.pctNoShow.toFixed(2)}%)
                      </Box>
                    </Text>
                  </Td>
                  <Td>
                    <Text color={"var(--red)"}>
                      {formatNumber(a.unattended)}
                      <Box
                        color="gray.500"
                        fontWeight="semibold"
                        fontSize="xs"
                        textTransform="uppercase"
                      >
                        ({a.pctUnattended.toFixed(2)}%)
                      </Box>
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={9} p={0}>
                    <Box mt="20px" mb="20px">
                      <OrganizationTicketsLineGraph
                        title={`Entire Organization: Hour by hour comparison`}
                        chart={chart}
                        chartHeight="300px"
                      />
                    </Box>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { defaultReducer } = state;
  return {
    tickets: defaultReducer.tickets,
  };
};

export default connect(mapStateToProps, {})(OrganizationTicketsTable);
