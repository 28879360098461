import { Box, Heading } from "@chakra-ui/react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TicketSessionByServiceLineGraph = ({
  title,
  chart,
  chartHeight
}) => {

  return (
    <Box width="100%" h={chartHeight} >
      <ResponsiveContainer>
        <LineChart
          data={chart}
          margin={{
            top: 0,
            right: 10,
            left: -10,
            bottom: 15
          }}
        >
          <CartesianGrid  stroke="#ebebeb" vertical={false} />
          <XAxis dataKey="day" name="Day"  axisLine={{ stroke: 'var(--darkGrey)', strokeWidth: 1 }} />
          <YAxis axisLine={{ stroke: 'var(--darkGrey)', strokeWidth: 1 }}/>
          <Tooltip />
          <Legend width="100%" />
          <Line type="monotone" strokeWidth={1.6} dataKey="sessionInSeconds" name="Session Time" stroke={'var(--blue)'} dot={{ fill: 'var(--blue)', r: 2 }} activeDot={{ r: 4 }} />
        </LineChart>
      </ResponsiveContainer>
      <Heading size="sm" fontWeight={400} fontSize="14px" color="#535353" mb="20px" textAlign="center">{title}</Heading>
    </Box>
  );

};

export default TicketSessionByServiceLineGraph;
