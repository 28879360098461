import { Box } from "@chakra-ui/react";
import BranchSelection from "./components/BranchSelection";
import ReportTypesSelection from "./components/ReportTypesSelection";
import ReportLevelSelection from "./components/ReportLevelSelection";
import { useSelector } from "react-redux";
import { LEVELS } from "../../../../store/constants";
import Save from "./components/Save";
const ReportForm = () => {
  const { level } = useSelector((state) => state.subscriptionsReducer);
  return (
    <Box>
      <ReportLevelSelection />

      <ReportTypesSelection />

      {level === LEVELS.BRANCH && <BranchSelection />}
      <Save/>
    </Box>
  );
};

export default ReportForm;
