import React, { PureComponent } from 'react';
import { Box, Heading, Text } from "@chakra-ui/react";
import { PieChart, Pie, Legend, ResponsiveContainer } from 'recharts';

class AllTicketsPieGraph extends PureComponent {
  render() {
    const { title, chart, chartHeight } = this.props;

    const data = [
      { name: 'Completed', value: chart.completed, fill: 'var(--green)' },
      { name: 'No Show', value: chart.noShow, fill: 'var(--red)' },
      { name: 'Unattended', value: chart.unattended, fill: 'var(--orange)' },
    ];

    return (
      <Box width="100%" h={chartHeight}>
        {
          chart.completed === 0 && chart.noShow === 0 && chart.unattended === 0
          ?   <Box mt="70px">
                <Text align="center" color="#535353">No data available for this Pie Chart</Text>
              </Box>
          : <>
              <ResponsiveContainer>
                <PieChart width={400} height={300} >
                  <Pie
                    dataKey="value"
                    startAngle={0}
                    endAngle={360}
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={45}
                    fill="#8884d8"
                    label // causes - Warning: Encountered two children with the same key, `label-0-0`.
                  />
                  <Legend verticalAlign="bottom" width="100%"  />
                </PieChart>
              </ResponsiveContainer>
            </>
        }
      </Box>
    );
  }
}

export default AllTicketsPieGraph;