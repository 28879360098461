import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Africa/Windhoek");

export const getMonths = (activeDate) => {
    const totalWeeksInMonth = getWeeksInMonth(activeDate);
    const completeCalendar = generateCompleteMonthCalendar(
        totalWeeksInMonth,
        [
            getMonthMetrics(activeDate.subtract(1, 'month')),
            getMonthMetrics(activeDate),
            getMonthMetrics(activeDate.add(1, 'month'))
        ]
    );
    const weeks = Array.from({ length: totalWeeksInMonth }, (_, i) => completeCalendar.slice(i * 7, i * 7 + 7));
    return { weeks };
}

const getMonthMetrics = (date) => {
    return {
        startDay: date.startOf('month').day(),
        daysInMonth: date.daysInMonth(),
        month: date.month(),
        year: date.year(),
        days: Array.from({ length: date.daysInMonth() }, (_, i) => date.startOf('month').add(i, 'day').format('DD'))
    }
}

const generateCompleteMonthCalendar = (totalWeeksInMonth, monthData) => {
    const prevMonth = monthData[0];
    const currentMonth = monthData[1];
    const nextMonth = monthData[2];
    
    const completeCalendar = [];
    const totalDaysInWeek = 7;

    // Fill in the days from the previous month
    for (let i = prevMonth.daysInMonth - currentMonth.startDay + 1; i <= prevMonth.daysInMonth; i++) {
        completeCalendar.push(dayjs(new Date(prevMonth.year, prevMonth.month, i)).format('YYYY-MM-DD'));
    }

    // Fill in the days for the current month
    for (let i = 1; i <= currentMonth.daysInMonth; i++) {
        completeCalendar.push(dayjs(new Date(currentMonth.year, currentMonth.month, i)).format('YYYY-MM-DD'));
    }

    // Fill in the days from the next month
    const remainingDays = totalDaysInWeek * totalWeeksInMonth - completeCalendar.length;
    for (let i = 1; i <= remainingDays; i++) {
        completeCalendar.push(dayjs(new Date(nextMonth.year, nextMonth.month, i)).format('YYYY-MM-DD'));
    }

    return completeCalendar;
}

const getWeeksInMonth = (date) => {
    const startWeek = date.startOf('month').week();
    const endWeek = date.endOf('month').week();
    const adoptedEndWeek = endWeek === 1 ? 53 : endWeek;
    return adoptedEndWeek - startWeek + 1;
}