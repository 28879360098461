import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";

// Filters
import FilterForm from "../../../components/FilterForm";
import { Helmet } from "react-helmet";

// Reports
// organization

//daily
import DailyOrganizationArrivalRatesPerHour from "../../organization/daily/DailyOrganizationArrivalRatesPerHour";
import DailyOrganizationArrivalRatesPerService from "../../organization/daily/DailyOrganizationArrivalRatesPerService";
import DailyOrganizationServiceTimePerService from "../../organization/daily/DailyOrganizationServiceTimePerService";
import DailyOrganizationTickets from "./../../organization/daily/DailyOrganizationTickets";
import DailyOrganizationTicketsPerBranch from "./../../organization/daily/DailyOrganizationTicketsPerBranch";
import DailyOrganizationTicketsPerService from "../../organization/daily/DailyOrganizationTicketsPerService";
//branches
import DailyBranchAgentServiceTimePerService from "../../branches/daily/DailyBranchAgentServiceTimePerService";
import DailyBranchAgentTicketsPerService from "../../branches/daily/DailyBranchAgentTicketsPerService";
import DailyBranchArrivalRatesPerHour from "../../branches/daily/DailyBranchArrivalRatesPerHour";
import DailyBranchArrivalRatesPerService from "../../branches/daily/DailyBranchArrivalRatesPerService";
import DailyBranchServiceTimePerService from "../../branches/daily/DailyBranchServiceTimePerService";
import DailyBranchTicketsPerAgent from "../../branches/daily/DailyBranchTicketsPerAgent";
import DailyBranchTicketsPerService from "../../branches/daily/DailyBranchTicketsPerService";

const DailyFullReport = () => {
  const report = [
    <DailyOrganizationTickets />,
    <DailyOrganizationTicketsPerService />,
    <DailyOrganizationServiceTimePerService />,
    <DailyBranchTicketsPerService />,
    <DailyBranchServiceTimePerService />,
    <DailyOrganizationArrivalRatesPerHour />,
    <DailyOrganizationArrivalRatesPerService />,
    <DailyOrganizationTicketsPerBranch />,
    <DailyBranchAgentServiceTimePerService />,
    <DailyBranchAgentTicketsPerService />,
    <DailyBranchArrivalRatesPerHour />,
    <DailyBranchArrivalRatesPerService />,
    <DailyBranchTicketsPerAgent />,
  ];
  return (
    <Box>
      <Helmet>
        <title>Daily Full Report</title>
      </Helmet>
      <FilterForm />
      {report.map((a, index) =>
        React.cloneElement(a, {
          key: index,
          
          showFilterForm: 0,
          showTitle: 0,
        })
      )}
    </Box>
  );
};

export default DailyFullReport;
