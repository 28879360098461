import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";
import { TICKET_STATUS } from "../../../../store/constants";
import { generateHoursArray } from "../../../../store/helpers";

const useTickets = () => {
  const { tickets } = useSelector((state) => state.realtimeReducer);
  const { organizationId, workDay } = useSelector(
    (state) => state.defaultReducer
  );
  const ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.organizationId === organizationId
  );
  const totalTickets = ticketsClone.length;
  const numCompletedTickets = ticketsClone.filter(
    (a) => Number(a.status) === TICKET_STATUS.COMPLETED
  )?.length || 0;
  const numActiveTickets = ticketsClone.filter(
    (a) => Number(a.status) === TICKET_STATUS.ACTIVE
  )?.length || 0;

  const numNoShow = ticketsClone.filter(
    (a) => Number(a.status) === TICKET_STATUS.NO_SHOW
  )?.length || 0;

  const startTimeStamp = dayjs()
    .set("hour", workDay[0].split(" ")[0])
    .set("minute", 0)
    .unix();
  const endTimeStamp = dayjs().unix();
  const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);
  const chart = hoursBetween.map((hour) => {
    const hourTickets = ticketsClone.filter(
      (a) => dayjs.unix(a.created).format("h A") === hour
    );
    return {
      hour,
      total: hourTickets.length,
    };
  });


  return {
    totalTickets,
    numCompletedTickets,
    numActiveTickets,
    numNoShow,
    chart,
  };
};

export default useTickets;
