import dayjs from "dayjs";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "./constants";
// loop through services and add a property to each service object that indicates if the service has children or not
export const checkServiceHasChildren = (services) => {
  return services.map((a) => {
    a.hasChildren = services.filter((b) => b.parent === a.id).length ? 1 : 0;
    return a;
  });
};

// loop through ticket array and add a property to each ticket object that indicates if the service exists or not
export const checkServiceExistsForTickets = (tickets, services) => {
  return tickets.map((a) => {
    a.serviceExistStatus = services.find((b) => b.id === a.serviceId) ? 1 : 0;
    return a;
  });
};

// find all services that have been delete but were present in the tickets array
export const findDeletedServices = (ticketsUpdated) => {
  return ticketsUpdated
    .map((a) => {
      return !a.serviceExistStatus ? a : null;
    })
    .filter(Boolean);
};

export const getReadableKeyFromObject = (obj, value) => {
  let key = Object.keys(obj).find((key) => obj[key] === value);
  return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
};

export const getNonWorkDays = (workWeek) => {
  return [0, 1, 2, 3, 4, 5, 6].filter((a) => !workWeek.includes(a));
};

export const getNonWorkDates = (date, nonWorkDays, daysInMonth) => {
  const nonWorkDates = [];
  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    const currentDay = date.date(dayIndex);
    if (nonWorkDays.includes(currentDay.day())) {
      nonWorkDates.push(currentDay.format("D"));
    }
  }
  return nonWorkDates;
};

//
export const mapHasData = (map) => {
  const maxLength = Math.max(...Object.values(map).map((arr) => arr.length));

  return maxLength > 0;
};

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600).toFixed(0);
  const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
  const remainingSeconds = (seconds % 60).toFixed(0);
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;
  return formattedTime;
};
function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

export const formatNumber = (number) => {
  return number.toLocaleString();
};

//generate dates array between provided timestamps
export const generateDateArray = (startTimestamp, endTimestamp) => {
  const startDate = dayjs.unix(startTimestamp);
  const endDate = dayjs.unix(endTimestamp);

  const datesArray = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate)) {
    datesArray.push(currentDate.format("MM-DD-YYYY"));
    currentDate = currentDate.add(1, "day");
  }

  return datesArray;
};

//compare two arrays
export const arraysAreEqual = (arr1, arr2) => {
  return (
    arr1.length === arr2.length && arr1.every((elem, i) => elem === arr2[i])
  );
};

export const getReportRangeString = (reportRange) => {
  return {
    [REPORT_RANGES.DAY]: "Daily",
    [REPORT_RANGES.WEEK]: "Weekly",
    [REPORT_RANGES.MONTH]: "Monthly",
    [REPORT_RANGES.RT]: "Real Time",
  }[reportRange];
};

export const getReportDescription = (reportRange) => {
  const startStr =
    {
      [REPORT_RANGES.DAY]: "Daily",
      [REPORT_RANGES.WEEK]: "Weekly",
      [REPORT_RANGES.MONTH]: "Monthly",
      [REPORT_RANGES.RT]: "Real Time",
      [REPORT_RANGES.TICKET_INFO]: "Ticket Information",
    }[reportRange] || "Monthly";
  const timeRangeStr =
    reportRange === REPORT_RANGES.DAY ? "hour to hour" : "day to day";
  const perTimeRangeStr = reportRange === REPORT_RANGES.DAY ? "hour" : "day";

  return Object.freeze({
    Tickets: `${startStr} Tickets overview of the entire organization. Total tickets, average tickets per ${perTimeRangeStr}, minimum and maximum tickets recorded for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total tickets, completed tickets, no-show and unattended tickets.`,
    TicketsPerService: `${startStr} Tickets Service-by-Service breakdown of the entire organization. Total tickets, average tickets per ${perTimeRangeStr}, minimum and maximum tickets recorded for the ${reportRange} for each service. Including a Pie-Chart for the monthly comparison of Completed, No-show and unattended tickets and a Line-chart a line-chart with a ${timeRangeStr} comparison of total tickets, completed tickets, no-show and unattended tickets per service`,
    ServiceTimePerService: `${startStr} Service Time, Service-by-Service breakdown of the entire organization. Total completed tickets, Total Service Time, Average Service Time per ticket, minimum and maximum service times recorded for the ${reportRange} for each service. Including a line-chart with a ${timeRangeStr} comparison of Average Service Time per Day.`,
    ArrivalRatesPerHour: `${startStr} overview of the Arrival rates per hour of the entire organization. Total customers /${reportRange}, average customer per hour, minimum and maximum customers per hour. Including a line-chart with a ${timeRangeStr} comparison of total customers per hour and Time-of-Day Chart indicate customer arrival time for every day of the week.`,
    ArrivalRatesPerDay: `${startStr} overview of the Arrival rates per ${perTimeRangeStr} of the entire organization. Total customers /${reportRange}, average customer per ${perTimeRangeStr}, minimum and maximum customers per ${perTimeRangeStr}. Including a line-chart with a ${timeRangeStr} comparison of total customers per ${perTimeRangeStr}.`,
    ArrivalRatesByService: `${startStr} overview of the Arrival rates per ${perTimeRangeStr} per service. Total customers /${reportRange}, average customer per ${perTimeRangeStr}, minimum and maximum customers per ${perTimeRangeStr} per service. Including a line-chart with a ${timeRangeStr} comparison of total customers per service.`,
    TicketsByBranch: `${startStr} overview of Tickets Per Branch. Total customers /${reportRange}, average customer per ${timeRangeStr}, minimum and maximum customers per ${perTimeRangeStr} per service. Including a line-chart with a ${timeRangeStr} comparison of total customers per branch.`,
    BranchesArrivalRatesPerHour: `${startStr} overview of hourly customer visits per branch. Total customers /${reportRange}, average customers per hour, minimum and maximum customers per hour, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total customers per hour and a Time-of-Day Chart indicating customer arrival times for every day of the week.`,
    BranchesArrivalRatesPerDay: `${startStr} overview of daily customer visits per branch. Total customers /${reportRange}, average customers per ${perTimeRangeStr}, minimum and maximum customers per ${perTimeRangeStr}, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total customers per ${perTimeRangeStr}.`,
    BranchesArrivalRatesPerService: `${startStr} overview of the frequency of demand for specific service per branch. Total customers /${reportRange}, average customers per ${perTimeRangeStr}, minimum and maximum customers per ${perTimeRangeStr}, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total customers per service.`,
    ServiceTimePerServiceBranches: `${startStr} overview of the total time to complete specific service per branch. Total customers /${reportRange}, average customer per ${timeRangeStr}, minimum and maximum customers per ${perTimeRangeStr}. Including a line-chart with a ${timeRangeStr} comparison of total customers per service.`,
    AgentServiceSessionTime: `${startStr} overview of total time agent took to complete particular service per branch. Total customers /${reportRange}, average customers per ${timeRangeStr}, minimum and maximum customers per ${perTimeRangeStr}, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total customers per service.`,
    TicketsPerServiceBranches: `${startStr} overview of number of tickets issued for a service per branch. Total tickets /${reportRange}, average tickets per ${timeRangeStr}, minimum and maximum tickets per ${perTimeRangeStr}, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total tickets per service and per branch.`,
    TicketsByAgents: `${startStr} overview of number of tickets attended by specific agent per branch. Total tickets /${reportRange}, average tickets per ${timeRangeStr}, minimum and maximum tickets per ${perTimeRangeStr}, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total tickets per agent, per branch.`,
    AgentTicketsByService: `${startStr} breakdown of number of tickets attended by specific agent for specific service per branch. Total tickets /${reportRange}, average tickets per ${perTimeRangeStr}, minimum and maximum tickets per ${perTimeRangeStr}, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total customers per service.`,
    BranchesCustomersInBranchPerHour: `${startStr} overview of the number of customers per hour per branch. Total customers /${reportRange}, average customers per hour, minimum and maximum customers per hour, for the ${reportRange}. Including a line-chart with a ${timeRangeStr} comparison of total customers per hour.`,

    //Realtime
    OrganizationArrivalRatesPerHour: `Realtime overview of the hourly customer visits of the entire organization. Including a line-chart with a realtime comparison of total customers per hour.`,
    OrganizationAverageSessionTimePerBranch: `Realtime overview of the average session time of the entire organization. Including a line-chart with a realtime comparison of average session time per hour.`,
    OrganizationAverageWaitTimePerBranch: `Realtime overview of the average wait time of the entire organization. Including a line-chart with a realtime comparison of average wait time per hour.`,
    OrganizationLiveDashboard: `Realtime overview of the entire organization. Including a line-chart with a realtime comparison of total customers, completed customers, no-show and unattended customers.`,
    //branches
    BranchAbondonmentRate: `The abandonment rate is presented as an hourly graph. The X-axis represents the hours of the day, while the Y-axis shows the abandonment rate as a percentage. Each data point on the graph indicates the abandonment rate for that specific hour. The graph helps identify peak hours when abandonment rates are highest, allowing for better staffing and resource allocation to reduce ticket abandonment. The overall trend displayed in the graph provides insights into customer behavior and system performance throughout the day.`,
    BranchAgentStatus: `The real-time Branch Agent Status report provides a comprehensive overview of the current status of agents across different branches. It displays whether agents are logged in or offline, along with listing various branches and their respective agents. Continuously updating in real-time. This allows supervisors and managers to effectively monitor agent availability, manage workloads, and ensure efficient ticket handling across all branches.`,
    BranchArrivalRatesPerHour: `The real-time Branch Arrival Rates per Hour report provides a comprehensive snapshot of visitor dynamics across different branches. It tracks several key metrics: total visitors, which accumulates throughout the day; maximum visitors per hour, indicating the peak hour with the highest foot traffic; minimum visitors per hour, reflecting the quietest period; total visitors for the current hour, offering real-time updates on visitor influx; average visitors per hour, providing an overview of typical traffic levels; and peak hour identification, highlighting the busiest period based on hourly data. This report enables managers to monitor branch activity, optimize staffing based on visitor trends, and enhance operational efficiency to meet varying demands throughout the day..`,
    BranchAverageIdleTimePerAgent: `Realtime overview of the average idle time per agent per branch. Including a line-chart with a realtime comparison of average idle time per agent.`,
    BranchAverageSessionTimePerAgent: `Realtime overview of the average session time per agent per branch. Including a line-chart with a realtime comparison of average session time per agent.`,
    BranchFirstContactResolutionRate: `Realtime overview of the first contact resolution rate per hour per branch. Including a line-chart with a realtime comparison of first contact resolution rate per hour.`,
    BranchLiveDashboard: `Realtime overview of the branch. Including a line-chart with a realtime comparison of total customers, completed customers, no-show and unattended customers.`,
    BranchNumberOfCustomersInHour: `Realtime overview of the number of customers per hour per branch. Including a line-chart with a realtime comparison of number of customers per hour.`,
    BranchServiceTransfers: `Realtime overview of the service transfers per hour per branch. Including a line-chart with a realtime comparison of service transfers per hour.`,
    BranchTickets: `Realtime overview of the tickets per hour per branch. Including a line-chart with a realtime comparison of tickets per hour.`,

    //Tickets Information
    TicketsByDate: `Go back in time to get a comprehensive overview of the number of tickets created, completed, and abondoned on each specific date within a chosen time frame. This report helps in understanding the daily workload, identifying trends, and managing resources effectively`,
    TicketLookup: `Ticket Lookup by ticket number and day of creation.`,
  });
};

export const formatTicketNumber = (text) => {
  text = text.toUpperCase();
  //check if char at 0 is letter
  if (text.length > 0 && !Number.isNaN(Number(text.charAt(0)))) {
    return "";
  }

  //max 2 letters
  if (text.replace(/[^a-zA-Z]/g, "").length > 2) {
    console.log(text.replace(/[^a-zA-Z]/g, "").length, "letters");
    return null;
  }
  //max 4 numbers
  if (text.replace(/\D/g, "").length > 4) {
    return null;
  }
  const hasNumber = /[1-9]/.test(text);
  if (hasNumber && !text.includes("-")) {
    //insert - before the number
    let index = text.search(/\d/);
    text = text.substring(0, index) + "-" + text.substring(index);

    return text;
  }

  return text;
};

//generate hours array between provided timestamps
export const generateHoursArray = (startTimestamp, endTimestamp) => {
  const startDate = dayjs.unix(startTimestamp);
  const endTime = dayjs.unix(endTimestamp);

  const hoursArray = [];
  let currentTime = startDate;

  while (currentTime.isBefore(endTime)) {
    hoursArray.push(currentTime.format("h A"));
    currentTime = currentTime.add(1, "hour");
  }

  return hoursArray;
};

export const capitalizeName = (name) => {
  return name
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const convertTo24HourHour = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let hours = parseInt(time);

  if (modifier === "AM" && hours === 12) {
    hours = 0;
  } else if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }

  return hours;
};

export const getReportTypeFromId = (reportTypeId) => {
  const reportTypeIds = REPORT_TYPE_IDS;
  return Object.keys(reportTypeIds).find(
    (key) => reportTypeIds[key] === reportTypeId
  );
}
