import { Box, useDisclosure } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

const Layout = (props) => {
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <Sidebar />
      <Header onOpen={onOpen} />
      <Box p="15px" overflowY="auto" minH="400px" ml="260px">
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
