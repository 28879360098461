import { Box, Heading, Icon, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import CustomersInQueueReport from "../../../components/branches/reports/realtime/CustomersInQueue";
import BranchTicketsByDateReport from "../../../components/branches/reports/ticket-info/BranchTicketsByDate";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";

const CustomersInQueue = ({ showFilterForm = true, reportIndex = 1 }) => {
  const { branches, branchId, organizationId, reportRange, workDay } = useSelector(
    (state) => state.defaultReducer
  );
  const { isByDateTicketsReport } = useSelector(
    (state) => state.realtimeReducer
  );
  let branchesClone = _.cloneDeep(branches);
  branchesClone = branchesClone
    .filter(
      (a) =>
        a.reportStatus != null &&
        Number(a.reportStatus) &&
        a.organizationId === organizationId
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));
  const reportTitle = `Customers In Queue`;
  return (
    <Box>
      <Helmet>
        <title>{reportTitle}</title>
      </Helmet>
      {showFilterForm ? <FilterForm isRTTicketsReport={true} /> : null}
      <>
        <Heading fontSize="34px" color="#222" fontWeight="700">
          {reportTitle}
          <Tooltip
            label={getReportDescription(reportRange).BranchTickets}
            aria-label="A tooltip"
          >
            <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
          </Tooltip>
        </Heading>
        {isByDateTicketsReport ? (
          <BranchTicketsByDateReport
            reportIndex={reportIndex}
            branches={branchesClone}
          />
        ) : (
          <>
            {_.isEmpty(workDay) ? (
              <Loader />
            ) : (
              <CustomersInQueueReport
                reportIndex={reportIndex}
                branches={branchesClone}
              />
            )}
          </>
        )}
      </>
    </Box>
  );
};

export default CustomersInQueue;
