//clock useEffect with interval of 1 second
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { updateTimeNow } from '../store/actions/realtimeAction';


const useClock = () => {
    const dispatch = useDispatch();
    const [time, setTime] = useState(dayjs().unix());
    useEffect(() => {
        const interval = setInterval(() => {
        setTime(dayjs().unix());
        dispatch(updateTimeNow());

        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return time;
}

export default useClock;