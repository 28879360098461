import { Box, Text, Select } from "@chakra-ui/react";

const SelectComponent = ({ label, value, options, onChange, placeholder, maxDate, disabled=false }) => {
    return (
        <Box
            w="100%"
            h="56px"
            borderRadius="8px"
            border="1px solid #b0b0b0"
            p="12px 5px 9px 12px"
        >
            <Text
                h="15px"
                lineHeight="15px"
                fontSize="12px"
                color="#6a6a6a"
            >{label}</Text>
            <Select
                placeholder={placeholder}
                value={value}
                borderRadius={0}
                borderColor="var(--black)"
                h="20px"
                w="100%"
                fontSize="16px"
                color="#222"
                p="0"
                m="0"
                outline="none"
                boxShadow="none"
                border="none"
                disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map((a, i) => ( <option key={i} value={a.id}>{a.name}</option> ))}
            </Select>
        </Box>  
    );
}

export default SelectComponent;