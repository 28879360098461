import { Box, VStack, HStack, Center, Icon, Text } from "@chakra-ui/react";
import { IoCalendarClearOutline, IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import dayjs from 'dayjs';
import { useState, useEffect, useRef } from "react";
import weekOfYear from 'dayjs/plugin/weekOfYear';
// timezone
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getMonths } from '../../helpers';

dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Africa/Windhoek");

const DailyPicker = ({ styling, value, maxDate, onChange }) => {
    const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const currentDate = dayjs();

    const [uiCalendarDate, setUICalendarDate] = useState(currentDate);
    const [activeMonth, setActiveMonth] = useState({});
    const [activeDate, setActiveDate] = useState(
        value.length
            ?   dayjs.unix(value[0])
            :   currentDate
    );

    const [showCalendar, setShowCalendar] = useState(0);
    const calendarRef = useRef(null);

    useEffect(() => {
        updateValue(value);
        const handleClickOutside = (event) => 
            calendarRef.current && !calendarRef.current.contains(event.target) && setShowCalendar(false);

        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside) };
    }, []);

    useEffect(() => {
        setUICalendarDate(activeDate);
        setActiveMonth(getMonths(activeDate));
    }, [activeDate]);

    useEffect(() => {
        updateValue(value);
    }, [value]);

    const updateValue = (value) => {
        value = value == null || !(value != null && value.length && value[0].toString().length )
            ?  dayjs().startOf("day").unix()
            : value[0];
        setActiveDate(dayjs.unix(value));
    }

    const getNextPrevMonth = (a) => {
        const newDate = a === "add" 
            ?   uiCalendarDate.add(1, 'month').startOf('month')
            :   uiCalendarDate.subtract(1, 'month').startOf('month');

        // check maxDate to limit selecting future dates
        const next =  {
            status: !(a === "add" && newDate.isAfter(dayjs.unix(maxDate).endOf('month'))),
            newDate
        };

        return next;
    }

    const handleMonthChange = (a) => {
        const next = getNextPrevMonth(a);
        if (!next.status) return;
        setUICalendarDate(next.newDate);
        setActiveMonth(getMonths(next.newDate));
    }

    const handleDateChange = (date) => {
        const selectedDate = dayjs(date);
        const startUnix = selectedDate.startOf('day').unix();
        const endUnix = selectedDate.endOf('day').unix();

        setActiveDate(selectedDate);
        onChange([startUnix, endUnix]);
    }

    // calendar styling
    const calendarStyling = {
        [styling.calendar.placement[0]]: styling.calendar.spacing[0],
        [styling.calendar.placement[1]]: styling.calendar.spacing[1]
    };
    
    return (
        <Box
            className="averly-date-picker day-picker"
            w={styling.width}
            h={styling.height}
            cursor="pointer"
            zIndex="999999"
            position="relative"
        >
            <VStack spacing={styling.spacing} position="relative">
                <HStack spacing="5px" onClick={() => setShowCalendar(!showCalendar)}>
                    <Icon as={IoCalendarClearOutline} boxSize="16px" color="#171C1E" />
                    <Text fontSize={styling.fontSize} h={styling.height} display="inline-block" overflow="hidden">{activeDate.startOf('day').format("DD MMM YYYY")}</Text>
                </HStack>
                <Box
                    ref={calendarRef}
                    bg="#F6F6F9"
                    w="356px"
                    h="auto"
                    position="absolute"
                    p="9px 5px 5px"
                    borderRadius="5px"
                    display={showCalendar ? "block" : "none"}
                    {...calendarStyling}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <VStack spacing="0">
                        <HStack className="header" w="100%" justifyContent="space-between">
                            <Center className="iconBackground" w="30px" h="30px" onClick={() => handleMonthChange("subtract")}>
                                <Icon as={IoArrowBackOutline} className="icon" boxSize="16px" />
                            </Center>
                            <Text fontSize="14px" color="#000" textAlign="center">{ uiCalendarDate.format("MMMM YYYY") }</Text>
                            <Center
                                className="iconBackground"
                                w="30px"
                                h="30px"
                                opacity={ !(getNextPrevMonth("add").newDate.isAfter(dayjs.unix(maxDate).endOf('month'))) ? 1 : 0}
                                onClick={() => handleMonthChange("add")}
                            >
                                <Icon as={IoArrowForwardOutline} className="icon" boxSize="16px" />
                            </Center>
                        </HStack>
                        <VStack spacing="5px" bg="#fff" w="100%" p="5px 16px 16px" borderRadius="3px" mt="10px">
                            <HStack spacing="22px" width="100%" h="17px" mb="7px">
                                {
                                    daysOfTheWeek.map((a, i) => <Center w="26px" h="17px" fontSize="14px" color="#697387" key={i}>{a}</Center> )
                                }
                            </HStack>
                            {
                                activeMonth.weeks?.map((a, i) => {
                                    

                                    return <HStack
                                        key={i}
                                        spacing="22px"
                                        width="100%"
                                        h="26px"
                                        bg="transparent"
                                        outline="none"
                                        boxShadow="none"
                                        borderRadius="13px"
                                        
                                    >
                                        {
                                            a.map((b, j) => {
                                                const activeDayStatus = value != null && value.length &&  value[0].toString().length && ( dayjs.unix(value[0]).format("YYYY-MM-DD") === b ) ? 1 : 0;
                                                const currentDayStatus = b === currentDate.format("YYYY-MM-DD") ? 1 : 0;
                                                const currentMonthStatus = uiCalendarDate.format("MM") === dayjs(b).format("MM") ? 1 : 0;
                                                const maxDateStatus = maxDate && dayjs(b).format("YYYY-MM-DD") >= dayjs.unix(maxDate).format("YYYY-MM-DD") ? 0 : 1;

                                                return <Center 
                                                    key={j}
                                                    w="26px" 
                                                    h="26px"
                                                    bg={ activeDayStatus
                                                        ?   "#344054"
                                                        :   currentDayStatus
                                                            ?   "#C3F0C2"
                                                            :   "transparent" }
                                                    borderRadius="50%"
                                                    fontSize="12px"
                                                    color={ activeDayStatus
                                                            ?   "#fff" 
                                                            :   currentMonthStatus
                                                                ?   "#344054"
                                                                :   "#D0D5DD"
                                                    }
                                                    _hover={ activeDayStatus ? { bg: "#344054" } : { bg: "#EAECF0" }}
                                                    cursor={ maxDateStatus ? "pointer" : "not-allowed" }
                                                    onClick={() => {
                                                        if (!maxDateStatus) return;
                                                        handleDateChange(b);
                                                        setTimeout(() => {
                                                            setShowCalendar(0)
                                                        }, 200);
                                                    }}

                                                >
                                                    {dayjs(b).format("D")}
                                                </Center>
                                            })
                                        }
                                    </HStack>
                                })
                            }
                        </VStack>
                    </VStack>
                </Box>
            </VStack>
        </Box>
    );
}

export default DailyPicker;