import {
  Box,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tag,
  Text,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  setIsEditing,
  setSubscription,
  updateSubscription,
} from "../../../../store/actions/subscriptionsActions";
import {
  updateDeleteModal,
  updateDrawer,
} from "../../../../store/actions/miscAction";

const SubscriptionRow = ({ subscription, index, reportTypes }) => {
  const dispatch = useDispatch();

  const { reportRange, reports, activeStatus, id, subscriptionName } = subscription;

  const reportNames = reports.map((report) => {
    const reportType = reportTypes?.find((a) => a.id === report.reportTypeId);
    let reportName = reportType?.name
      .split("_")
      .slice(1)
      .join("_")
      .replace(/_([a-z])/g, (match, letter) => " " + letter.toUpperCase())
      .replace(/^./, (str) => str.toUpperCase());
    return reportName;
  });
  const updateSubscriptionStatus = () => {
    //update subscription status
    dispatch(
      updateSubscription(
        { activeStatus: Number(activeStatus) === 1 ? 0 : 1 },
        id
      )
    );
  };

  return (
    <>
      <Tr>
        <Td borderRight="1px solid #dadce0">{index + 1}</Td>
        <Td borderRight="1px solid #dadce0">{reportRange}</Td>
        <Td borderRight="1px solid #dadce0">{subscriptionName}</Td>
        <Td borderRight="1px solid #dadce0">
          <Box>
            {reportNames.map((reportName, index) => {
              return (
                <Tag m={1} key={index}>
                  {reportName}
                </Tag>
              );
            })}
          </Box>
        </Td>
        <Td borderRight="1px solid #dadce0">{reports.length}</Td>
        <Td borderRight="1px solid #dadce0">
          <Switch
            size="md"
            id={`${id}-checkbox`}
            isChecked={activeStatus}
            onChange={updateSubscriptionStatus}
          />
        </Td>
        <Td>
          <HStack>
            <Text
              mr={2}
              colorScheme="blue"
              color="var(--black)"
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() => {
                dispatch(setSubscription(subscription));
                const drawer = {
                  title: "Edit Reports Subscription",
                  isOpen: 1,
                  content: { id, component: "SubscriptionForm" },
                };
                dispatch(setIsEditing(true));
                dispatch(updateDrawer(drawer));
              }}
            >
              Edit
            </Text>
            <Text
              onClick={() => {
                dispatch(updateDeleteModal({ isOpen: 1, subscriptionId: id }));
              }}
              colorScheme="red"
              color="var(--black)"
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
            >
              Delete
            </Text>
          </HStack>
        </Td>
      </Tr>
    </>
  );
};
const SubscriptionsTable = ({ subscriptions }) => {
  const { reportTypes } = useSelector((store) => store.defaultReducer);
  const reportTypesClone = _.cloneDeep(reportTypes);
  return (
    <Box>
      <Table className="stripedTable" size="sm">
        <Thead>
          <Tr>
            <Th borderRight="1px solid #dadce0">#</Th>
            <Th borderRight="1px solid #dadce0">Report Range</Th>
            <Th borderRight="1px solid #dadce0">Name</Th>
            <Th borderRight="1px solid #dadce0">Reports</Th>
            <Th borderRight="1px solid #dadce0">Number of Reports</Th>
            <Th borderRight="1px solid #dadce0">Active</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {subscriptions.map((subscription, index) => {
            return (
              <SubscriptionRow
                subscription={subscription}
                index={index}
                reportTypes={reportTypesClone}
                key={index}
              />
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default SubscriptionsTable;
