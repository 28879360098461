import React from 'react';

import {
    Box,
} from '@chakra-ui/react';

import AllTicketsTable from "../tables/AllTicketsTable";

const Tickets = ({
    data
}) => {
    return (
        <Box>
            <AllTicketsTable 
                table={[data]} 
                chart={data.chart} 
            />
        </Box>
    );
};


  
export default Tickets;
  