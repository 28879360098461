import React, { useEffect } from "react";

import { Box, Heading, Text, Tooltip, Icon, HStack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import { useSelector } from "react-redux";
import _ from "lodash";

import FilterForm from "../../components/FilterForm";
import AllTickets from "../../components/organization/reports/AllTickets";
import {
  getReportDescription,
  getReportRangeString,
} from "../../store/helpers";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";
import { Helmet } from "react-helmet";
import DownloadReport from "../../components/DownloadReport";

const TicketsPage = ({ showFilterForm = true, showTitle = 1 }) => {
  const { reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_tickets
      : REPORT_TYPE_IDS.monthly_organization_tickets;
  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  const reportTitle = `Organization Tickets`;
  useEffect(() => {
    if (showTitle === 1) {
      document.title = getReportRangeString(reportRange) + " " + reportTitle;
    }
  }, []);

  return (
    <Box>
      {showFilterForm ? <FilterForm /> : null}
      {!_.isEmpty(data) ? (
        <HStack flexDirection={"row"} justifyContent={"space-between"} >
          <Box>
            <Heading fontSize="34px" color="#222" fontWeight="700">
              {reportTitle}
              <Tooltip
                label={getReportDescription(reportRange).Tickets}
                aria-label="A tooltip"
              >
                <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
              </Tooltip>
            </Heading>
          </Box>
          <DownloadReport reportRange={reportRange} reportTypeId={reportTypeId}/>
        </HStack>
      ) : (
        <Text>No data available for this report</Text>
      )}
      {!_.isEmpty(data) ? <AllTickets data={data[0]} /> : null}
    </Box>
  );
};

export default TicketsPage;
