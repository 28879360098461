import { Box, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IoPeopleOutline, IoTimeOutline, } from "react-icons/io5";
import PropTypes from "prop-types";

import {
  checkServiceHasChildren,
  formatNumber,
  generateHoursArray,
} from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";
import StatsCard from "../../../StatsCard";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

const BranchArrivalRatesPerHour = ({ branches }) => {
  BranchArrivalRatesPerHour.propsValidation = {
    branches: PropTypes.array.isRequired,
  };

  BranchArrivalRatesPerHour.defaultProps = {
    branches: [],
  };

  const { workDay, servicesSubscriptions, services, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const { tickets } = useSelector((state) => state.realtimeReducer);
  let ticketsClone = _.cloneDeep(tickets);

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});
  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;

      branchServices = checkServiceHasChildren(servicesClone)
        .filter(
          (service) =>
            branchServices?.includes(service.id) && !service.hasChildren
        )
        .map((service) => service.id);
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      const startTimeStamp = dayjs()
        .set("hour", workDay[0].split(" ")[0])
        .set("minute", 0)
        .unix();
      const endTimeStamp = dayjs().unix();
      const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);

      const chart = hoursBetween
        .map((hour) => {
          const hourTickets = branchData.filter(
            (a) => dayjs.unix(a.created).format("h A") === hour
          );

          if (workDay.includes(hour))
            return {
              hour,
              total: hourTickets.length,
            };
          return null;
        })
        .filter(Boolean);

      const min = Math.min(...chart.map((a) => a.total)) || 0;
      const max = Math.max(...chart.map((a) => a.total)) || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;
      const average =
        chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
      const peakHour = chart.find((a) => a.total === max)?.hour || "NA";

      return {
        branchName,
        branchServices,
        chart,
        min,
        max,
        total,
        branchId,
        average,
        peakHour,
      };
    })
    .sort((a, b) => b.total - a.total);

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              {
                branchName,
                chart,
                total,
                min,
                average,
                max,
                branchId,
                peakHour,
              },
              i
            ) => {
              const stats = [
                {
                  title: 'Total Visitors',
                  stat: total,
                  icon: IoPeopleOutline,
                  iconColor: 'var(--green)',
                },
                {
                  title: 'Maximum Visitors Per Hour',
                  stat: Number.isFinite(max) && !Number.isNaN(max) ? formatNumber(max) : "NA",
                  icon: IoTimeOutline,
                  iconColor: 'var(--red)',
                },
                {
                  title: 'Minimum Visitors Per Hour',
                  stat: Number.isFinite(min) && !Number.isNaN(min) ? formatNumber(min) : "NA",
                  icon: IoTimeOutline,
                  iconColor: 'var(--green)',
                },
                {
                  title: 'Peak Hour',
                  stat: peakHour,
                  icon: IoTimeOutline,
                  iconColor: 'var(--red)',
                },
                {
                  title: 'Average Visitors Per Hour',
                  stat: Number.isFinite(average) && !Number.isNaN(average) ? formatNumber(average) : "NA",
                  icon: IoTimeOutline,
                  iconColor: 'var(--blue-classic)',
                },
                {
                  title: 'Total This Hour',
                  stat: chart.find((a) => a.hour === dayjs().format("h A")) ?.total || 0,
                  icon: IoTimeOutline,
                  iconColor: 'var(--blue-classic)',
                },
               
              ];
              return (
                <Box key={branchId}>
                  <Box mt={4}>
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      mb={"10px"}
                      spacing={0}
                    >
                      {
                        stats.map((a, i) => (
                          <Box
                            key={i}
                            px="20px"
                            py="15px"
                            mb="5px"
                            border="1px solid var(--borderGrey)"
                            borderRight={{
                              base: '1px solid #E5E4E5',
                              md: [2, 5].includes(i) ? '1px solid #E5E4E5' : 'none',
                            }}
                            borderRadius={{
                              base: '10px',
                              md: [0, 3].includes(i)
                                ? '10px 0 0 10px'
                                : [2, 5].includes(i)
                                  ? '0 10px 10px 0'
                                  : '0',
                            }}
                          >
                            <StatsCard title={a.title} stat={a.stat} statNumberId={a.statNumberId} icon={a.icon} iconColor={a.iconColor} />
                          </Box>
                        ))
                      }
                    </SimpleGrid>
                  </Box>

                  <Box mt="20px" mb="20px">
                    <LineGraph
                      chart={chart}
                      title={`${branchName} Arrival rates per hour, Hour by Hour Comparison`}
                      lineName="Tickets"
                    />
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchArrivalRatesPerHour;
