import OrganizationArrivalRatesPerDayTable from "../../tables/daily/OrganizationArrivalRatesPerHourTable";
import LineGraph from "../../../daily/LineGraph";

import { Box, Divider } from "@chakra-ui/react";

const OrganizationArrivalRatesPerHour = ({  data: reportData }) => {
  const title = `1. Entire Organization`;
  

  const { average, min, max, total, chart } = reportData;

  return (
    <>
      <Box className="reportContainer" mt="20px">
        <OrganizationArrivalRatesPerDayTable
          average={average}
          min={min}
          max={max}
          total={total}
          title={title}
        />

        <Box mt="20px" mb="20px">
          <LineGraph
            chart={chart}
            height={"300px"}
            title={"Entire Organization Arrival Rates, Hour by Hour Comparison"}
          />
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default OrganizationArrivalRatesPerHour;
