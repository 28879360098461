import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptions,
  setSaveSuccessful,
} from "../../../store/actions/subscriptionsActions";
import SubscriptionsTable from "./tables/SubscriptionsTable";
import { setSubscription } from "../../../store/actions/subscriptionsActions";
import _ from "lodash";
import { Box } from "@chakra-ui/react";
import { getReportTypes } from "../../../store/actions/defaultAction";
import DeleteModal from "./misc/DeleteModal";

const Subscriptions = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.authReducer);
  const { subscriptions, saveSuccessful } = useSelector((state) => state.subscriptionsReducer);
  const { organizationId } = useSelector((state) => state.defaultReducer);
  const subscriptionsClone = _.cloneDeep(subscriptions);

  useEffect(() => {
    dispatch(setSubscription({}));
    dispatch(getReportTypes()); //get the report types
  }, []);

  useEffect(() => {
    if (saveSuccessful) {
      dispatch(getSubscriptions(userData.id, organizationId));
      dispatch(setSaveSuccessful(false));
    }
  }, [saveSuccessful]);

  //TODO: sort by listOrder
  return (
    <Box>
      <SubscriptionsTable subscriptions={subscriptionsClone} />
      <DeleteModal />
    </Box>
  );
};

export default Subscriptions;
