import { useSelector } from "react-redux";
import _ from "lodash";

import { TICKET_STATUS } from "../../../../store/constants";
import { formatTime } from "../../../../store/helpers";

const useAgents = () => {
  const { users, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const { agentSessions, tickets } = useSelector(
    (state) => state.realtimeReducer
  );
  const { iamRoles } = useSelector((state) => state.permissionsReducer);
  const agentRoleId = iamRoles.find((a) => a.name === "agent")?.id;

  let agents = _.cloneDeep(users)
    .filter((a) => Number(a.agentStatus) === 1 || a.roleId === agentRoleId)
    .filter((a) => a.organizationId === organizationId);
  const ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.organizationId === organizationId
  );

  let agentSessionsClone = _.cloneDeep(agentSessions).filter(
    (a) => a.organizationId === organizationId
  );

    agentSessionsClone = agentSessionsClone.reduce((acc, a) => {
    const agent = acc.find((b) => b.userId === a.userId);
    if (!agent) {
      acc.push(a);
    }
    return acc;
  }, []);
  const availableAgentsIds = agents.map((a) => a.id); //some agents in the agentSessions might be deleted

  const allAgents = agentSessionsClone.filter((a) =>
    availableAgentsIds.includes(a.userId)
  );

  const onlineAgents = allAgents.filter((a) => Number(a.status) === 1);
  const agentsData = agents
    .map((agent) => {
      const { id: agentId } = agent;
      const agentTickets = ticketsClone.filter(
        (a) => a.manage?.userId === agentId
      );
      const total = agentTickets.length;

      const completed = agentTickets.filter(
        (a) => Number(a.status) === TICKET_STATUS.COMPLETED
      ).length;


      const agentDetails = users.find((a) => a.id === agentId);
      const agentName = agentDetails
        ? `${agentDetails.name} ${agentDetails.lastname}`
        : "Unnamed Agent";
      const agentServiceTime = agentTickets
        .filter((ticket) => Number(ticket.status) === TICKET_STATUS.COMPLETED)
        .reduce((acc, ticket) => acc + ticket.sessionInSeconds, 0);
        const averageTime = agentServiceTime / (completed || 1);

      return {
        agentId,
        agentName,
        total,
        completed,
        averageTime: formatTime(averageTime),
      };
    })
    .sort((a, b) => b.total - a.total);

  return {
    numOnline: onlineAgents.length,
    numAgents: allAgents.length,
    agentsData,
  };
};

export default useAgents;
