import { ACTION_TYPES } from "../actions/types";

const initialState = {
  level: "",
  addReport: false,
  downloadingReport: false,
  reportRange: "",
  organizationId: "",
  reportTypeId: "",
  loading: false,
  saveSuccessful: false,
  subscriptions: [], //user subscriptions
  showSubscriptionForm: false,

  subscription: { reports: [] },
  isEditing: false,
  activeReportTypeId: "",
  editReportTypeId: "",
  selectedBranches: [],  //current branch selection
  subscriptionId: "",
  pastReportDateRange: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_LEVEL:
      return {
        ...state,
        level: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_SUBSCRIPTION:
      return {
        ...state,
        reportSubscription: action.payload,
      };

    case ACTION_TYPES.UPDATE_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.payload,
      };
    case ACTION_TYPES.UPDATE_BRANCH_IDS:
      return {
        ...state,
        branchIds: action.payload,
      };

    //update report range
    case ACTION_TYPES.UPDATE_SUB_REPORT_RANGE:
      return {
        ...state,
        reportRange: action.payload,
      };

    //update reportTypeId
    case ACTION_TYPES.UPDATE_REPORT_TYPE_ID:
      return {
        ...state,
        reportTypeId: action.payload,
      };

    //update reports

    case ACTION_TYPES.UPDATE_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };

    //setloading
    case ACTION_TYPES.SET_SUB_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    //set save successfull
    case ACTION_TYPES.SET_SAVE_SUCCESSFUL:
      return {
        ...state,
        saveSuccessful: action.payload,
      };

    //set subscriptions
    case ACTION_TYPES.SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };

    //update showSubscriptionForm
    case ACTION_TYPES.UPDATE_SHOW_SUB_FORM:
      return {
        ...state,
        showSubscriptionForm: action.payload,
      };

    //set editSubscription
    case ACTION_TYPES.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };

    case ACTION_TYPES.SET_ADD_REPORT:
      return {
        ...state,
        addReport: action.payload,
      };

    //set active report type id
    case ACTION_TYPES.SET_ACTIVE_REPORT_TYPE_ID:
      return {
        ...state,
        activeReportTypeId: action.payload,
      };

    //is Editing
    case ACTION_TYPES.SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };

    //set edit report type id
    case ACTION_TYPES.SET_EDIT_REPORT_TYPE_ID:
      return {
        ...state,
        editReportTypeId: action.payload,
      };

      //set selected branches
    case ACTION_TYPES.SET_SELECTED_BRANCHES:
      return {
        ...state,
        selectedBranches: action.payload,
      };

    case ACTION_TYPES.UPDATE_DOWNLOADING_REPORT:
      return {
        ...state,
        downloadingReport: action.payload,
      };

    case ACTION_TYPES.UPDATE_SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.payload,
      };

      case ACTION_TYPES.SET_PAST_REPORT_DATE_RANGE:
        return {
          ...state,
          pastReportDateRange: action.payload,
        };

    default:
      return state;
  }
}
