import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import {
  getBranches,
  getOrganizations,
  getServices,
  getServicesSubscriptions,
  getUsers,
} from "./store/actions/defaultAction";

// import actions
import { updateAppStatus, verifyToken } from "./store/actions/authActions";
import { getIamRoles } from "./store/actions/permissionsActions";
import {
  listenToEvents,
  stopListeners,
} from "./store/actions/realtimeAction";

import usePermissions from "./hooks/usePermissions";
import { REPORT_RANGES } from "./store/constants";

// import components
import RoutesComponent from "./Routes";
import NoPermissions from "./pages/NoPermissions";
import Loader from "./components/loader";
import useClock from "./hooks/useClock";

// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";
import "./assets/css/Inputs.css";

function App() {
  const { read } = usePermissions();
  const dispatch = useDispatch();
  const timeNow = useClock();
  const {
    appStatus, userId, verifyStatus, userData,
  } = useSelector((state) => state.authReducer);
  const { organizationId, reportRange } = useSelector((state) => state.defaultReducer);

  useEffect(() => {
    dispatch(verifyToken());
  }, []);
  useEffect(() => {
    if (userId && verifyStatus === 1) {
      dispatch(getIamRoles());
    }
  }, [userId, verifyStatus]);
  useEffect(() => {
    if (appStatus) {
      dispatch(getOrganizations());
    }
  }, [appStatus]);

  useEffect(() => {
    if (organizationId.length > 0) {
      dispatch(getBranches(organizationId));
      dispatch(getServices(organizationId));
      dispatch(getServicesSubscriptions(organizationId));
      dispatch(getUsers(organizationId));
      dispatch(stopListeners());
      if (reportRange === REPORT_RANGES.RT) {
        dispatch(listenToEvents({ organizationId }));
      } else {
        dispatch(stopListeners());
      }
    }
  }, [organizationId, reportRange]);

  useEffect(() => {
    if (read && verifyStatus === 1) {
      dispatch(updateAppStatus(1));
    } else {
      dispatch(updateAppStatus(0));
    }
  }, [read, verifyStatus]);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });

  const permissionsCheck = !read && !_.isEmpty(userData)
    ? <NoPermissions />
    : <Loader />;

  return (
    <ChakraProvider theme={theme}>
      {
      !appStatus
        ? permissionsCheck
        : (
          <Router>
            <RoutesComponent />
          </Router>
        )
      }
    </ChakraProvider>
  );
}
export default App;
