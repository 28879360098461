import { connect } from "react-redux";
import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Tag,
} from '@chakra-ui/react';
import { useSelector } from "react-redux";

import TicketSessionByServiceLineGraph from "../charts/TicketSessionByServiceLineGraph";

const { formatTime, formatNumber } = require('../../../store/helpers');

const ServiceTimePerServiceTable = ({
  branchId = null,
  data,
  
  branchIndex,
  numServices,
  branchName
}) => { 
  const { showEmptyDataSets } = useSelector((state) => state.defaultReducer);

  const [maxWidth, setMaxWidth] = useState(0);
  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector('tr > td:nth-child(2)');
      if (secondColumn) {  setMaxWidth((prevMaxWidth) => Math.max(prevMaxWidth, secondColumn.offsetWidth)); }
    };
    document.querySelectorAll('table').forEach((table) => { calculateSecondColumnWidth(table); });
  }, []);

  useEffect(() => {
    document.querySelectorAll('table').forEach((table) => {
      table.querySelectorAll('tr > td:nth-child(2)').forEach((secondColumn) => { secondColumn.style.width = `${maxWidth}px`; });
    });
  }, [maxWidth]); 
  
  let branch = {};
  let chartTitle = "Entire Organization,";
  if(branchId) {
    chartTitle = `${branchName} Branch, `;
  }

  const branchTitle = branchId ? `${ branchIndex ? branchIndex + ". " : "" }${ branchName } Branch` : "Entire Organization";

  return (
    <Box mt={10}>
      {
        branchId
        ? <Box mb="20px">
            <h2>{branchTitle}</h2>
            <p>{ numServices } services.</p>
          </Box>
        : <Box mb="20px">
            <h2 size='xl'>1. Entire Organization</h2>
          </Box>
      }
      {
        data.map( (a, index) => {
        console.log(` ${ branchIndex ? branchIndex + ". " : "" }${index+1}. ${a.name}`)
        return <Box key={index} className="reportContainer" mt="20px">
          <Box mb="10px">
            <h3>{branchIndex ? branchIndex + ". " : null}{index+1}. {a.name}</h3>
            { branchId ? <Tag><p className="branchTag">{ branch.name } Branch</p></Tag> : null }
          </Box>
          {
            showEmptyDataSets|| ( !showEmptyDataSets && Number(a.completed) > 0 ) 
            ? <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th borderRight="1px solid #dadce0">Total Completed Tickets</Th>
                <Th borderRight="1px solid #dadce0">Service Time</Th>
                <Th>Avg /ticket.</Th>
                <Th>Min.</Th>
                <Th>Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td borderRight="1px solid #dadce0">
                  <Text color={'var(--blue)'}>{formatNumber(a.completed)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text color={'var(--blue)'}>{formatTime(a.sessionInSeconds)}</Text>
                </Td>
                <Td>
                  <Text>{formatTime(a.avg)}</Text>
                </Td>
                <Td>
                  <Text>{formatTime(a.min)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatTime(a.max)}</Text>
                </Td>
          
              </Tr>
              <Tr>
                <Td colSpan={9} p={0}>
                  <Box mt="20px" mb="20px">
                    <TicketSessionByServiceLineGraph title={`${a.name}: ${chartTitle} Day by Day Comparison`} chart={a.days} chartHeight="200px" />
                  </Box>
                </Td>
              </Tr>
            </Tbody>
            
          </Table>
          : <Text fontSize="sm">No data available for this report</Text>
          }
        </Box>
      })
      }
    </Box>
  );
};

const mapStateToProps = (state) => {
    const { defaultReducer } = state
    return {
      tickets: defaultReducer.tickets,
    };
};
  
export default connect(mapStateToProps, {})(
    ServiceTimePerServiceTable
);
  