import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";

const ArrivalRatesPerServiceTable = ({
  data,
  index,
  branchName,
  
  branchIndex,
}) => {
  const { serviceName, average, min, max, total, chart } = data;

  const title = `${branchIndex + 1}.${
    index + 1
  }. ${serviceName}`;
  
  return (
    <>
      <Box className="reportContainer" mt="20px">
        <h3>{title}</h3>
        <Tag>
          <p className="branchTag">{branchName} Branch</p>
        </Tag>
        {!total && <Text fontSize="sm">No data available for this report</Text>}

        {total ? (
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0"> Total Visitors</Th>
                <Th borderRight="1px solid #dadce0">Average</Th>
                <Th>Min</Th>
                <Th>Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{index + 1}</Td>
                <Td borderRight="1px solid #dadce0">{formatNumber(total)}</Td>
                <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
                <Td>{formatNumber(min)}</Td>
                <Td>{formatNumber(max)}</Td>
              </Tr>

              <Tr>
                <Td colSpan={5}>
                  <Box mt="20px" mb="20px">
                    <LineGraph
                      chart={chart}
                      height={"300px"}
                      title={`${serviceName} Arrival Rates, Hour by Hour Comparison`}
                    />
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default ArrivalRatesPerServiceTable;
