import { Box, Heading, Icon, Tooltip } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import FilterForm from "../../../components/FilterForm";
import BranchTicketsByDateReport from "../../../components/branches/reports/ticket-info/BranchTicketsByDate";
import { InfoIcon } from "@chakra-ui/icons";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";

const BranchTickets = ({ showFilterForm = true, reportIndex = 1 }) => {
  const { branches, branchId, organizationId, reportRange, workDay } =
    useSelector((state) => state.defaultReducer);
  let branchesClone = _.cloneDeep(branches);
  branchesClone = branchesClone
    .filter(
      (a) =>
        a.reportStatus != null &&
        Number(a.reportStatus) &&
        a.organizationId === organizationId
    )
    .filter((a) => !branchId.length || (branchId.length && a?.id === branchId));
  const reportTitle = `Tickets By Date`;
  useEffect(() => {
    document.title = reportTitle;
  }, []);
  return (
    <Box>
      {showFilterForm ? <FilterForm isRTTicketsReport={true} /> : <></>}
      <>
        <Heading fontSize="34px" color="#222" fontWeight="700">
          {reportTitle}
          <Tooltip
            label={getReportDescription(reportRange).TicketsByDate}
            aria-label="A tooltip"
          >
            <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
          </Tooltip>
        </Heading>
        {_.isEmpty(workDay) ? (
          <Loader />
        ) : (
          <BranchTicketsByDateReport
            reportIndex={reportIndex}
            branches={branchesClone}
          />
        )}
      </>
    </Box>
  );
};

export default BranchTickets;
