import React from "react";
import { Box } from "@chakra-ui/react";

// Filters
import FilterForm from "../components/FilterForm";

// Reports
// organization
import AgentServiceTimePerService from "./branches/BranchAgentServiceTimePerService";
import AgentTicketsByService from "./branches/BranchAgentTicketsPerService";
import TicketsPerAgents from "./branches/BranchTicketsPerAgent";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getReportRangeString } from "../store/helpers";

const FullReport = () => {
  const { reportRange } = useSelector((state) => state.defaultReducer);

  const report = [
    <TicketsPerAgents />,
    <AgentTicketsByService />,
    <AgentServiceTimePerService />,
  ];
  return (
    <Box>
      <Helmet>
        <title>
          {getReportRangeString(reportRange)} Agent Performance Full Report
        </title>
      </Helmet>
      <FilterForm />
      {report.map((a, index) =>
        React.cloneElement(a, {
          key: index,
          
          showFilterForm: 0,
          showTitle: 0,
        })
      )}
    </Box>
  );
};

export default FullReport;
