import _ from "lodash";
import { useSelector } from "react-redux";

const useRoles = () => {
  const { userData } = useSelector((store) => store.authReducer);
  const { iamRoles } = useSelector((store) => store.permissionsReducer);
  const isSuperAdmin = !_.isEmpty(
    iamRoles.find(
      (role) => role.name === "superAdmin" && role.id === userData.roleId
    )
  );

  const organizationId = userData.organizationId;

  return { isSuperAdmin, organizationId, roleId: userData?.roleId };
};

export default useRoles;
