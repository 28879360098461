import { useSelector } from "react-redux";
import _ from "lodash";
import { TICKET_STATUS } from "../../../../store/constants";

const useBranches = () => {
  const { tickets } = useSelector((state) => state.realtimeReducer);
  const { organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const { branches } = useSelector((state) => state.defaultReducer);
  const ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.organizationId === organizationId
  );

  let branchesClone = _.cloneDeep(branches);
  branchesClone = branchesClone.filter(
    (a) =>
      a.reportStatus != null &&
      Number(a.reportStatus) &&
      a.organizationId === organizationId
  );

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});

  let branchIdWithMostTickets = null;
  let numTickets = 0;
  //in case other metrics are needed
  const branchesData = Object.keys(branchesMap).map((branchId) => {
    const branchData = branchesMap[branchId];
    const branchName =
      branches.find((branch) => branch.id === branchId)?.name ||
      `Branch-${branchId}`;
    const total = branchData.length;
    if (total > numTickets) {
      numTickets = total;
      branchIdWithMostTickets = branchId;
    }

    const completed = branchData.filter(
      (a) => Number(a.status) === TICKET_STATUS.COMPLETED
    ).length || 0;
    const active = branchData.filter(
      (a) => Number(a.status) === TICKET_STATUS.ACTIVE
    )?.length || 0;
    const inQueue = branchData.filter(
      (a) => Number(a.status) === TICKET_STATUS.IN_QUEUE
    )?.length || 0;
    const noShow = branchData.filter(
      (a) => Number(a.status) === TICKET_STATUS.NO_SHOW
    )?.length || 0;

    return {
      branchId,
      branchName,
      total,
      completed,
      active,
      inQueue,
      noShow,
    };
  }).sort((a, b) => b.total - a.total);

  const branchWithMostTickets = branchesClone.find(
    (a) => a.id === branchIdWithMostTickets
  );

  return {
    branchWithMostTickets,
    branchesData,
  };
};

export default useBranches;
