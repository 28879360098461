import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { IoLayersOutline, IoCheckmarkCircleOutline, IoCloseCircleOutline, IoPeopleOutline, IoIdCardOutline } from 'react-icons/io5';
import { useState } from "react";

import useCustomers from "../../../../hooks/reports/organization/realtime/useCustomers";
import StatsCard from "../../../StatsCard";
import useTickets from "../../../../hooks/reports/organization/realtime/useTickets";
import useAgents from "../../../../hooks/reports/organization/realtime/useAgents";
import LineGraph from "../../../daily/LineGraph";
import useBranches from "../../../../hooks/reports/organization/realtime/useBranches";
import BranchesOverviewCard from "../../misc/BranchesOverviewCard";
import AgentsOverviewCard from "../../misc/AgentsOverviewCard";

const OrganizationLiveDashboard = () => {
  const { organizationId, organizations } = useSelector(
    (state) => state.defaultReducer
  );
  const [limitIndexes, setLimitIndexes] = useState({
    agentLimit: 1,
    branchLimit: 1,
  });

  const { branchesData } = useBranches();
  const {
    numQueued,
  } = useCustomers();
  const { numAgents, numOnline, agentsData } = useAgents();
  const {
    totalTickets,
    numCompletedTickets,
    numActiveTickets,
    chart: ticketsChart,
    numNoShow,
  } = useTickets();
  const organizationName = organizations.find(
    (a) => a.id === organizationId
  )?.name;

  const stats = [
    {
      title: 'Total Tickets',
      stat: totalTickets || 0,
      icon: IoLayersOutline,
      iconColor: "var(--green)"
    },
    {
      title: 'Completed',
      stat: numCompletedTickets,
      icon: IoCheckmarkCircleOutline,
      iconColor: "var(--blue-classic)"
    },
    {
      title: 'No Show',
      stat: numNoShow || 0,
      icon: IoCloseCircleOutline,
      iconColor: "var(--red)"
    },
    {
      title: 'Customers Being Served Now',
      stat: numActiveTickets || 0,
      icon: IoPeopleOutline,
      iconColor: "var(--green)"
    },
    {
      title: 'Customers In Queue Now',
      stat: numQueued,
      icon: IoPeopleOutline,
      iconColor: "var(--orange)"
    },
    {
      title: 'Online Agents',
      stat: `${numOnline}/${numAgents}`,
      icon: IoIdCardOutline,
      iconColor: "var(--black-dry)"
    },
  ];

  return (
    <Box>
      {organizationId.length > 0 ? (
        <Box mt={4}>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={0}
            mb={10}
          >
            {
              stats.map((a,i) =>
                <Box
                  px={"20px"}
                  key={i}
                  py={"15px"}
                  mb={"5px"}
                  border={"1px solid var(--borderGrey)"}
                  borderRight={{
                    base: "1px solid #E5E4E5",
                    md: [2,5].includes(i) ? "1px solid #E5E4E5" : "none"
                  }}
                  borderRadius={{
                    base: "10px",
                    md: [0,3].includes(i)
                          ? "10px 0 0 10px"
                          : [2,5].includes(i)
                            ? "0 10px 10px 0"
                            : "0"
                  }}
                
          
                >
                  <StatsCard title={a.title} stat={a.stat} icon={a.icon} iconColor={a.iconColor} />
                </Box>
              )
            }       
          </SimpleGrid>
          <Box>
            <LineGraph
              chart={ticketsChart}
              title={`${organizationName} arrival rates, Hour by Hour comparison`}
              lineName="Tickets"
              lineDataKey="total"
            />
          </Box>
          <Box>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: "20px", lg: "20px" }}
              mb={10}
            >
              <Box mt={"40px"}>
                {
                  branchesData?.map((a, i) => 
                    i < (limitIndexes.branchLimit ? 6 : branchesData?.length)
                    ? <BranchesOverviewCard
                        key={a.branchId}
                        data={a}
                        index={i}
                        totalIndexes={ 
                          branchesData?.length > 6
                            ? Number(limitIndexes.branchLimit) 
                              ? 6
                              : branchesData?.length || 0
                            : branchesData?.length || 0
                        }
                      />
                    : null
                  )
                }
                <Text
                  _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setLimitIndexes({...limitIndexes, branchLimit: !Number(limitIndexes.branchLimit)})}
                  width={"100%"}
                  textAlign={"center"}
                  mt={"20px"}
                >
                  {
                    branchesData?.length > 6
                      ? Number(limitIndexes.agentLimit) ? "View More" : "View Less"
                      : null
                  }
                </Text>
              </Box>

              <Box mt={"40px"}>
              {
                agentsData?.map((a, i) => 
                  i < (limitIndexes.agentLimit ? 6 : agentsData?.length)
                    ? <AgentsOverviewCard
                      key={a.agentId}
                      data={a}
                      index={i}
                      totalIndexes={ 
                        agentsData?.length > 6
                          ? Number(limitIndexes.agentLimit)
                            ? 6
                            : agentsData?.length || 0
                          : agentsData?.length || 0
                      }
                    />
                    : null
                )
              }
              <Text
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setLimitIndexes({...limitIndexes, agentLimit: !Number(limitIndexes.agentLimit)})}
                width={"100%"}
                textAlign={"center"}
                mt={"20px"}
              >
                {
                  agentsData?.length > 6
                    ? Number(limitIndexes.agentLimit) ? "View More" : "View Less"
                    : null
                }
              </Text>
            </Box>
            </SimpleGrid>
          </Box>
        </Box>
      ) : (
        <Box>
          <Text>Please select an organization to get started</Text>
        </Box>
      )}
    </Box>
  );
};

export default OrganizationLiveDashboard;
