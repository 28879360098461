import TicketsPerAgentTable from "../../tables/daily/TicketsPerAgentTable";

import { Box } from "@chakra-ui/react";

const BranchTicketsPerAgent = ({ data }) => {
  return (
    <Box>
      {data.map((data, index) => {
        const { branchName } = data;
        const title = `${index + 1}. ${branchName}`;
        
        return (
          <Box key={index}>
            <h2>{title}</h2>
            <TicketsPerAgentTable
              
              data={data}
              branchIndex={index}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default BranchTicketsPerAgent;
