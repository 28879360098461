import {
  Box,
  Heading,
  SimpleGrid,
  Tag,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { IoPeopleOutline, IoTimeOutline } from "react-icons/io5";

import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import StatsCard from "../../../StatsCard";
import LineGraph from "../../../daily/LineGraph";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

const BranchServiceTransfers = ({ branches }) => {
  BranchServiceTransfers.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape({})),
  };
  BranchServiceTransfers.defaultProps = {
    branches: [],
  };

  const { workDay, servicesSubscriptions, services, organizationId, branchId } =
    useSelector((state) => state.defaultReducer);
  const { ticketTransfers } = useSelector((state) => state.realtimeReducer);

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  const ticketTransfersClone = _.cloneDeep(ticketTransfers).filter(
    (a) => a.organizationId === organizationId
  );

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketTransfersClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});
  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      //agents for this branch
      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;

      branchServices = checkServiceHasChildren(servicesClone)
        .filter(
          (service) =>
            branchServices?.includes(service.id) && !service.hasChildren
        )
        .map((service) => service.id);
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      const branchServicesData = branchServices
        .map((serviceId) => {
          const transfers = branchData.filter(
            (a) => a.oldTicket.serviceId === serviceId
          );
          const startTimeStamp = dayjs()
            .set("hour", workDay[0].split(" ")[0])
            .set("minute", 0)
            .unix();
          const endTimeStamp = dayjs().unix();
          const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);
          const chart = hoursBetween.map((hour) => {
            const hourTickets = transfers.filter(
              (a) => dayjs.unix(a.created).format("h A") === hour
            );
            return {
              hour,
              total: hourTickets.length,
            };
          });
          return {
            serviceId,
            numTransfers: transfers.length,
            serviceName: servicesClone.find((a) => a.id === serviceId)?.name,
            chart,
          };
        })
        .sort((a, b) => b.numTransfers - a.numTransfers)
        .filter((a) => a.numTransfers > 0);
          const total = branchServicesData.reduce(
            (acc, a) => acc + a.numTransfers, 0);
      const max = Math.max(...branchServicesData.map((a) => a.numTransfers));
      const min = Math.min(...branchServicesData.map((a) => a.numTransfers));

      return {
        branchName,
        branchId,
        branchServices,
        branchServicesData,
        total,
        min,
        max,
      };
    })
    .sort((a, b) => b.total - a.total);

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              {
                branchName,
                branchServicesData,
                total,
                min,
                max,
                branchId,
              }
            ) => {
              const totalTransfersThisHour = branchServicesData
                .flatMap((a) => a.chart)
                .filter((a) => a.hour === dayjs().format("h A"))
                .reduce((acc, a) => acc + a.total, 0);
              
                const stats = [
                  {
                    title: 'Total Transfers',
                    stat: total,
                    icon: IoPeopleOutline,
                    iconColor: 'var(--green)',
                  },
                  {
                    title: 'Max. Transfers Per Hour',
                    stat: max,
                    icon: IoPeopleOutline,
                    iconColor: 'var(--orange)',
                  },
                  {
                    title: 'Min. Transfers Per Hour',
                    stat: min,
                    icon: IoTimeOutline,
                    iconColor: 'var(--green)',
                  },
                  {
                    title: 'Transfers This Hour',
                    stat: totalTransfersThisHour,
                    icon: IoTimeOutline,
                    iconColor: 'var(--red)',
                  },                  
                ];

              return (
                <Box key={branchId}>
                  {!Number.isNaN(total) && total > 0 ? (
                    <Box>
                      <Box mt={4}>
                        <SimpleGrid
                          columns={{ base: 1, md: 4 }}
                          spacing={0}
                          mb={10}
                        >
                          {
                            stats.map((a, i) => (
                              <Box
                                px="20px"
                                py="15px"
                                mb="5px"
                                border="1px solid var(--borderGrey)"
                                borderRight={{
                                  base: '1px solid #E5E4E5',
                                  md: [3, 7].includes(i) ? '1px solid #E5E4E5' : 'none',
                                }}
                                borderRadius={{
                                  base: '10px',
                                  md: [0, 4].includes(i)
                                    ? '10px 0 0 10px'
                                    : [3, 7].includes(i)
                                      ? '0 10px 10px 0'
                                      : '0',
                                }}
                              >
                                <StatsCard title={a.title} stat={a.stat} statNumberId={a.statNumberId} icon={a.icon} iconColor={a.iconColor} />
                              </Box>
                            ))
                          }
                        </SimpleGrid>
                      </Box>
                      {branchServicesData?.map(
                        ({ chart, serviceName, serviceId }, i) => {
                          return (
                            <Box key={serviceId}>
                              <Heading fontSize="24px" color="var(--black-dry)">{`${i + 1}. ${serviceName}`}</Heading>
                              <Box mt="20px" mb="20px">
                                <LineGraph
                                  chart={chart}
                                  lineDataKey="total"
                                  title={`${serviceName} Ticket Transfers, Hour by Hour Comparison`}
                                  lineName="Transfers"
                                />
                              </Box>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  ) : (
                    <Text>No tranfers for this branch at the moment</Text>
                  )}
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchServiceTransfers;
