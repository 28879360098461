import { connect } from "react-redux";
import { Box, Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {
  updateReportRange,
  updateOrganizationId,
} from "../store/actions/defaultAction";

import Nav from "./sidebar/Nav";
import ReportRangeSelection from "./filterForm/ReportRangeSelection";

import Logo from '../assets/img/logo-dark.svg'
import BiLinks from "./sidebar/BiLinks";
import OrganizationSelection from "./filterForm/OrganizationSelection";

function Sidebar() {
  const { organizationId } = useSelector((store) => store.defaultReducer);
  
  return (
    <Box
      className="sidebar"
      w="260px"
      backgroundColor="var(--white)"
      borderRight="1px solid #ebebeb"
      position="fixed"
      height="100vh"
      overflowY="auto"
      paddingBottom={0}
    >
      <Box p="15px 15px 0">
        <Image src={Logo} alt="logo" />
      </Box>
      <Box p="0px 15px 0">
        <OrganizationSelection />
      </Box>
      <Box
        p="15px 15px 0"
        pt={0}
        mt={ organizationId.length ? "25px" : "0"}
      >
        <ReportRangeSelection />
        <Box mt="15px">
          {
            organizationId && organizationId.length 
            ? <Nav />
            : null
          }
        </Box>

        {/* <BiLinks /> */}
      </Box>
    </Box>
  );
}

export default connect(null, {
  updateReportRange,
  updateOrganizationId,
})(Sidebar);
