import { useDispatch, useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/react";
import { LEVELS } from "../../../../../store/constants";
import {
  setActiveReportTypeId,
  setSelectedBranches,
  updateLevel,
} from "../../../../../store/actions/subscriptionsActions";
import _ from "lodash";
import { useEffect } from "react";

import Select from "../../../../averly/Select"; 

const ReportLevelSelection = () => {
  const dispatch = useDispatch();
  const { subscription, level } = useSelector(
    (state) => state.subscriptionsReducer
  );

  useEffect(() => {
    if (_.isEmpty(subscription)) {
      dispatch(setActiveReportTypeId(""));
      if (level === LEVELS.ORGANIZATION) dispatch(setSelectedBranches([]));
    }
  }, [level]);
  return (
    <Box>
      <Select
        label="Report Level"
        placeholder="Select report level"
        value={level}
        options={[
          { id: LEVELS.BRANCH, name: "Branch" },
          { id: LEVELS.ORGANIZATION, name: "Organization" },
        ]}
        onChange={(e) => {
          dispatch(updateLevel(e));
        }}
      />
    </Box>
  );
};

export default ReportLevelSelection;
