import React from "react";
import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import _ from "lodash";

import FilterForm from "../../../components/FilterForm";
import OrganizationTicketsPerService from "../../../components/organization/reports/daily/OrganizationTicketsPerService";
import { getReportDescription } from "../../../store/helpers";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import { Helmet } from "react-helmet";

const DailyOrganizationTicketsPerService = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { reportRange, reportsData, organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId = REPORT_TYPE_IDS.daily_organization_tickets_per_service;

  const reportTitle = ` Tickets Per Service`;
  console.log(reportTitle);

  const reportsDataClone = _.cloneDeep(reportsData);
  const report = reportsDataClone.find(
    (a) =>
      a.reportTypeId === reportTypeId && a.organizationId === organizationId
  );
  const data = report?.data || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Organization Tickets Per Service</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : null}
      {!_.isEmpty(data) ? (
        <Box mb="20px">
          <Heading fontSize="34px" color="#222" fontWeight="700">
            {reportTitle}

            <Tooltip
              label={getReportDescription(reportRange).TicketsPerService}
              aria-label="A tooltip"
            >
              <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
            </Tooltip>
          </Heading>
        </Box>
      ) : (
        <Text>No data available in this search</Text>
      )}
      {!_.isEmpty(data) ? <OrganizationTicketsPerService data={data} /> : null}
    </Box>
  );
};

export default DailyOrganizationTicketsPerService;
