import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import LineGraph from "../../../daily/LineGraph";
import StatsCard from "../../../StatsCard";
import { TICKET_STATUS } from "../../../../store/constants";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

const BranchArrivalRatesPerHour = ({ branches }) => {
  const { workDay, servicesSubscriptions, services, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const { tickets, ticketTransfers } = useSelector(
    (state) => state.realtimeReducer
  );
  let ticketsClone = _.cloneDeep(tickets);
  const transfersTicketsClone = _.cloneDeep(ticketTransfers);
  const transferTicketsIds = transfersTicketsClone.map((a) => a.oldTicket.id);

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});
  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;

      branchServices = checkServiceHasChildren(servicesClone)
        .filter(
          (service) =>
            branchServices?.includes(service.id) && !service.hasChildren
        )
        .map((service) => service.id);
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      const startTimeStamp = dayjs()
        .set("hour", workDay[0].split(" ")[0])
        .set("minute", 0)
        .unix();
      const endTimeStamp = dayjs().unix();
      const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);

      const chart = hoursBetween
        .map((hour) => {
          const hourTicketsIds = branchData
            .filter(
              (a) =>
                dayjs.unix(a.created).format("h A") === hour &&
                Number(a.status) === TICKET_STATUS.COMPLETED
            )
            .map((a) => a.id);
          const numTransferedPerHour = hourTicketsIds.filter((a) =>
            transferTicketsIds.includes(a)
          ).length;
          const firstContactresolutionRate =
            Number(
              (
                ((hourTicketsIds.length - numTransferedPerHour) /
                  hourTicketsIds.length) *
                100
              ).toFixed(2)
            ) || null;

          if (workDay.includes(hour))
            return {
              hour,
              firstContactresolutionRate,
              total: hourTicketsIds.length,
            };
          return null;
        })
        .filter(Boolean);

      const min =
        Math.min(
          ...chart
            .filter((a) => a.firstContactresolutionRate !== null)
            .map((a) => a.firstContactresolutionRate)
        ) || 0;
      const max =
        Math.max(
          ...chart
            .filter((a) => a.firstContactresolutionRate !== null)
            .map((a) => a.firstContactresolutionRate)
        ) || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;
      const averageResolutionRate =
        chart.reduce((a, b) => a + b.firstContactresolutionRate, 0) /
          chart.filter((a) => a.firstContactresolutionRate !== null).length ||
        0;

      return {
        branchName,
        branchServices,
        chart,
        min,
        max,
        total,
        averageResolutionRate,
        branchId,
      };
    })
    .sort((a, b) => b.averageResolutionRate - a.averageResolutionRate);

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              {
                branchName,
                chart,
                total,
                min,
                max,
                branchServices,
                averageResolutionRate,
                branchId,
              },
              i
            ) => {
              const fcrrNow = chart.find(
                (a) => a.hour === dayjs().format("h A")
              )?.firstContactresolutionRate;

              const stats = [
                {
                  title: 'First Contact Resolution Rate Today',
                  stat: `${averageResolutionRate.toFixed(2)}%`,
                },
                {
                  title: 'Max. First Contact Resolution Rate Per Hour',
                  stat: max ? max.toFixed(2) + "%" : "NA",
                },
                {
                  title: 'Min. First Contact Resolution Rate Per Hour',
                  stat: min ? min.toFixed(2) + "%" : "NA",
                },
                {
                  title: 'First Contact Resolution Rate This Hour',
                  stat: fcrrNow ? fcrrNow.toFixed(2) + "%" : "NA",
                },
              ];
              
              return (
                <Box key={branchId}>
                  {total ? (
                    <>
                      <Box mt={4}>
                        <SimpleGrid
                          columns={{ base: 1, md: 4 }}
                          spacing="0"
                          mb={"10px"}
                        >
                          {
                            stats.map((a, i) => (
                              <Box
                                key={i}
                                px="20px"
                                py="15px"
                                mb="5px"
                                border="1px solid var(--borderGrey)"
                                borderRight={{
                                  base: '1px solid #E5E4E5',
                                  md: [3, 7].includes(i) ? '1px solid #E5E4E5' : 'none',
                                }}
                                borderRadius={{
                                  base: '10px',
                                  md: [0, 4].includes(i)
                                    ? '10px 0 0 10px'
                                    : [3, 7].includes(i)
                                      ? '0 10px 10px 0'
                                      : '0',
                                }}
                              >
                                <StatsCard title={a.title} stat={a.stat} statNumberId={a.statNumberId} icon={a.icon} iconColor={a.iconColor} />
                              </Box>
                            ))
                          }
                        </SimpleGrid>
                      </Box>

                      <Box mt="20px" mb="20px">
                        <LineGraph
                          chart={chart}
                          title={`${branchName} Branch First Contact Resolution Rate, Hour by Hour Comparison`}
                          lineDataKey="firstContactresolutionRate"
                          lineName="First Contact Resolution Rate(%)"
                        />
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <Text>No data available for this branch</Text>
                    </Box>
                  )}
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchArrivalRatesPerHour;
