import {} from "../actions/types";
const initialState = {
  drawer: {
    title: "",
    content: {},
    isOpen: 0,
  },
  deleteModal: {
    isOpen: 0,
    subscriptionId: null,
  },
};
export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_DRAWER":
      return {
        ...state,
        drawer: {
          title: action.payload.title,
          content: action.payload.content,
          isOpen: action.payload.isOpen,
        },
      };
    case "RESET_DRAWER":
      return {
        ...state,
        drawer: {
          title: "",
          content: {},
          isOpen: 0,
        },
      };

    case "UPDATE_DELETE_MODAL":
      return {
        ...state,
        deleteModal: {
          isOpen: action.payload.isOpen,
          subscriptionId: action.payload.subscriptionId,
        },
      };

    case "RESET_DELETE_MODAL":
      return {
        ...state,
        deleteModal: {
          isOpen: 0,
          subscriptionId: null,
        },
      };

    default:
      return state;
  }
}
