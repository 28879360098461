import { fetchData } from "./defaultAction";
import { ACTION_TYPES } from "./types";

//GET_IAM_ROLES
export const getIamRoles = () => async (dispatch) => {
  dispatch(setPermissionsLoading(true));
  const endpoint = "/iam/roles";
  const payload = {};
  const actionType = ACTION_TYPES.GET_IAM_ROLES;
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  dispatch(setPermissionsLoading(false));
};


//SET_PERMISSIONS_LOADING
export const setPermissionsLoading = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_PERMISSIONS_LOADING,
    payload,
  });
};
