import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateBranchId } from "../../store/actions/defaultAction";
import { useEffect, useState } from "react";
import _ from "lodash";

import Select from "../averly/Select";
import { TICKET_STATUS } from "../../store/constants";
import { updateSelectedTicketsStatus } from "../../store/actions/realtimeAction";

const StatusSelection = () => {
  const dispatch = useDispatch();
  const { selectedTicketsStatus } = useSelector(
    (store) => store.realtimeReducer
  );

  const handleOnchange = (status) => {
    //dispatch(updateBranchId({ branchId, organizationId }));
    dispatch(updateSelectedTicketsStatus(status))
  };

  const options = [
    {
      name: "In Queue",
      id: TICKET_STATUS.IN_QUEUE,
    },
    {
      name: "Active",
      id: TICKET_STATUS.ACTIVE,
    },
    {
      name: "No-show",
      id: TICKET_STATUS.NO_SHOW,
    },
    {
      name: "Completed",
      id: TICKET_STATUS.COMPLETED,
    },
  ];

  return (
    <Select
      label="Tickets"
      placeholder="All Tickets"
      value={selectedTicketsStatus}
      options={options}
      onChange={handleOnchange}
    />
  );
};

export default StatusSelection;
