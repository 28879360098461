import React from "react";
import { Box, Heading, Text, Tooltip, Icon, HStack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import _ from "lodash";

import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";

import FilterForm from "../../components/FilterForm";
import TicketsByService from "../../components/organization/reports/TicketsPerService";
import {
  getReportDescription,
  getReportRangeString,
} from "../../store/helpers";
import { Helmet } from "react-helmet";
import DownloadReport from "../../components/DownloadReport";

const TicketsByServicePage = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_branch_tickets_per_service
      : REPORT_TYPE_IDS.monthly_branch_tickets_per_service;

  const reportTitle = `Tickets Per Service`;

  const reportsDataClone = _.cloneDeep(reportsData);
  let reports = reportsDataClone.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId.length > 0) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data) || [];

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>
            {getReportRangeString(reportRange)} Branch Tickets Per Service
          </title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : null}
      {!_.isEmpty(data) ? (
        <Box mb="20px">
          <HStack flexDirection={"row"} justifyContent={"space-between"}>
            <Heading fontSize="34px" color="#222" fontWeight="700">
              {reportTitle}

              <Tooltip
                label={
                  getReportDescription(reportRange).TicketsPerServiceBranches
                }
                aria-label="A tooltip"
              >
                <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
              </Tooltip>
            </Heading>

            <DownloadReport
              reportRange={reportRange}
              reportTypeId={reportTypeId}
            />
          </HStack>
        </Box>
      ) : (
        <Text>No data available in this search</Text>
      )}
      {!_.isEmpty(data)
        ? data.map((a, index) => (
            <TicketsByService
              key={index}
              branchId={a.branchId}
              branchIndex={index + 1}
              data={a.serviceData}
              numServices={a.numServices}
              branchName={a.branchName}
            />
          ))
        : null}
    </Box>
  );
};

export default TicketsByServicePage;
