import { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganizationId } from "../../store/actions/defaultAction";
import useRoles from "../../hooks/useRoles";

import Select from "../averly/Select";

const OrganizationSelection = () => {
  const dispatch = useDispatch();
  const { organizationId, organizations } = useSelector((store) => store.defaultReducer);

  const { isSuperAdmin, organizationId: userOrganizationId } = useRoles();
  const [showSelect, setShowSelect] = useState(
    isSuperAdmin 
      ? organizationId.length ? 0 : 1
      : 0
  );

  useEffect(() => {
    if (!isSuperAdmin) {
      dispatch(updateOrganizationId(userOrganizationId));
    }
  }, [isSuperAdmin]);

  const handleOnchange = (organizationId) => {
    dispatch(updateOrganizationId(organizationId))
    setShowSelect(0);
  }

  let organizationName = "No Organization Selected";
  if(isSuperAdmin && organizationId.length && organizations.length) {
    const organization = organizations.find((a) => a.id === organizationId);
    organizationName = organization ? organization.name : "Deleted Organization";
  }

  return isSuperAdmin ?
    showSelect === 1
      ? <Box mt="20px" onBlur={()=> setShowSelect(0)}>
          <Select
            label="Organization"
            placeholder="Select an Organization"
            value={organizationId}
            options={organizations}
            onChange={handleOnchange}
          />
        </Box>
      : <Box mt="20px">
          <Text>{organizationName}</Text>
          <Text as="label" color="var(--gray-8)" fontSize="15px" cursor="pointer" onClick={() => setShowSelect(1)}>{ organizationId.length ? "Change" : "Select"}</Text>
      </Box>
    : null;
};

export default OrganizationSelection;

