import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../../store/helpers";
import LineGraph from "../../LineGraph";

const TicketsByBranchTable = ({ branchData, index }) => {
  const {
    branchName,
    min,
    max,
    total,
    average,
    totalPct,
    numServices,
    chart,
    totalCompleted,
    completedPct,
    totalNoShow,
    noShowPct,
    totalUnattended,
    unattendedPct,
  } = branchData;

  const title = `${index + 1}. ${branchName} Branch`;
  
  return (
    <Box>
      <h2>{title}</h2>
      <Text>{numServices} services</Text>

      {total ? (
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Total Tickets</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th borderRight="1px solid #dadce0">Max</Th>
              <Th>Completed</Th>
              <Th>No Show</Th>
              <Th>Unattended</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{index + 1}</Td>
              <Td borderRight="1px solid #dadce0">
                {" "}
                <Text color={"var(--green)"}>
                  {formatNumber(total)}
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    ({totalPct.toFixed(2)}%)
                  </Box>
                </Text>
              </Td>

              <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
              <Td>{formatNumber(min)}</Td>
              <Td borderRight="1px solid #dadce0">{formatNumber(max)}</Td>
              <Td>
                <Text color={"var(--green)"}>
                  {!Number.isNaN(totalCompleted) ? formatNumber(totalCompleted) : "NA"}
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    {!Number.isNaN(completedPct)
                      ? `(${completedPct.toFixed(2)})%`
                      : "NA"}
                  </Box>
                </Text>
              </Td>

              <Td>
                <Text cursor="pointer" color={"var(--red)"}>
                  {!Number.isNaN(totalNoShow) ? formatNumber(totalNoShow) : "NA"}
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    {!Number.isNaN(noShowPct) ? `(${noShowPct.toFixed(2)})%` : "NA"}
                  </Box>
                </Text>
              </Td>

              <Td>
                <Text color={"var(--red)"}>
                  {!Number.isNaN(totalUnattended)
                    ? formatNumber(totalUnattended)
                    : "NA"}
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    {!Number.isNaN(unattendedPct)
                      ? `(${unattendedPct.toFixed(2)})%`
                      : "NA"}
                  </Box>
                </Text>
              </Td>
            </Tr>

            <Tr>
              <Td colSpan={8}>
                <Box mt="20px" mb="20px">
                  <LineGraph
                    chart={chart}
                    title={`${branchName} tickets, Day by Day Comparison`}
                    height={"300px"}
                  />
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default TicketsByBranchTable;
