import React from "react";
import { Box } from "@chakra-ui/react";

// Filters
import FilterForm from "../components/FilterForm";

import TicketsPerServiceBranches from "./branches/BranchTicketsPerService";
import ServiceTimePerServiceBranches from "./branches/BranchServiceTimePerService";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getReportRangeString } from "../store/helpers";

const FullReport = () => {
  const { reportRange } = useSelector((state) => state.defaultReducer);

  const report = [
    <TicketsPerServiceBranches />,
    <ServiceTimePerServiceBranches />,
  ];
  return (
    <Box>
      <Helmet>
        <title>
          {getReportRangeString(reportRange)} Ticket Management Full Report
        </title>
      </Helmet>
      <FilterForm />
      {report.map((a, index) =>
        React.cloneElement(a, {
          key: index,
          
          showFilterForm: 0,
          showTitle: 0,
        })
      )}
    </Box>
  );
};

export default FullReport;
