// Auth
export const FETCH_CLOUD_ORGANIZATIONS = "FETCH_CLOUD_ORGANIZATIONS";

//Add all action types here
export const ACTION_TYPES = Object.freeze({
  //Default reducer
  UPDATE_REPORT_DURATION: "UPDATE_REPORT_DURATION",
  UPDATE_APP_STATUS: "UPDATE_APP_STATUS",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  GET_REPORTS_DATA: "GET_REPORTS_DATA",
  GET_REPORT_TYPES: "GET_REPORT_TYPES",
  UPDATE_REPORT_RANGE: "UPDATE_REPORT_RANGE",
  UPDATE_TIME_NOW: "UPDATE_TIME_NOW",

  //Auth Reducer
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_ID: "SET_USER_ID",
  UPDATE_VERIFY_STATUS: "UPDATE_VERIFY_STATUS",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",

  //Subscriptions Reducer
  UPDATE_LEVEL: "UPDATE_LEVEL",
  UPDATE_REPORT_SUBSCRIPTION: "UPDATE_REPORT_SUBSCRIPTION",
  UPDATE_ORGANIZATION_ID: "UPDATE_ORGANIZATION_ID",
  UPDATE_BRANCH_IDS: "UPDATE_BRANCH_IDS",
  UPDATE_SUB_REPORT_RANGE: "UPDATE_SUB_REPORT_RANGE",
  UPDATE_REPORT_TYPE_ID: "UPDATE_REPORT_TYPE_ID",
  UPDATE_REPORTS: "UPDATE_REPORTS",
  SET_SUB_LOADING: "SET_SUB_LOADING", //subscription loading
  SET_SAVE_SUCCESSFUL: "SET_SAVE_SUCCESSFUL",
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",
  UPDATE_SHOW_SUB_FORM: "UPDATE_SHOW_SUB_FORM",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  SET_ACTIVE_REPORT_INDEX: "SET_ACTIVE_REPORT_INDEX",
  SET_ADD_REPORT: "SET_ADD_REPORT",
  SET_ACTIVE_REPORT_TYPE_ID: "SET_ACTIVE_REPORT_TYPE_ID",
  SET_IS_EDITING: "SET_IS_EDITING",
  SET_EDIT_REPORT_TYPE_ID: "SET_EDIT_REPORT_TYPE_ID",
  UPDATE_DOWNLOADING_REPORT: "UPDATE_DOWNLOADING_REPORT",
  UPDATE_SUBSCRIPTION_ID: "UPDATE_SUBSCRIPTION_ID",
  SET_PAST_REPORT_DATE_RANGE: "SET_PAST_REPORT_DATE_RANGE",

  //permissions reducer
  GET_IAM_ROLES: "GET_IAM_ROLES",
  GET_IAM_PERMISSIONS: "GET_IAM_PERMISSIONS",
  SET_PERMISSIONS_LOADING: "SET_PERMISSIONS_LOADING",

  SET_SELECTED_BRANCHES: "SET_SELECTED_BRANCHES",

  //realtime reducer
  RT_TICKETS: "RT_TICKETS",
  BY_DATE_TICKETS: "BY_DATE_TICKETS",
  UPDATE_IS_FETCHING_BY_DATE_TICKETS: "UPDATE_IS_FETCHING_BY_DATE_TICKETS",
  UPDATE_IS_BY_DATE_TICKETS_REPORT: "UPDATE_IS_BY_DATE_TICKETS_REPORT",
  UPDATE_ACTIVE_TICKETS: "UPDATE_ACTIVE_TICKETS",
  LISTEN_TICKETS_START: "LISTEN_TICKETS_START",
  LISTEN_TICKETS_CREATE: "LISTEN_TICKETS_CREATE",
  LISTEN_TICKETS_UPDATE: "LISTEN_TICKETS_UPDATE",
  LISTEN_TICKETS_DELETE: "LISTEN_TICKETS_DELETE",

  GET_TICKET_TRANSFERS: "GET_TICKET_TRANSFERS",
  LISTEN_TICKET_TRANSFERS_CREATE: "LISTEN_TICKET_TRANSFERS_CREATE",
  LISTEN_TICKET_TRANSFERS_UPDATE: "LISTEN_TICKET_TRANSFERS_UPDATE",
  LISTEN_TICKET_TRANSFERS_DELETE: "LISTEN_TICKET_TRANSFERS_DELETE",

  GET_AGENT_SESSIONS: "GET_AGENT_SESSIONS",
  LISTEN_AGENT_SESSIONS_CREATE: "LISTEN_AGENT_SESSIONS_CREATE",
  LISTEN_AGENT_SESSIONS_UPDATE: "LISTEN_AGENT_SESSIONS_UPDATE",
  LISTEN_AGENT_SESSIONS_DELETE: "LISTEN_AGENT_SESSIONS_DELETE",
  UPDATE_AGENT_SESSIONS: "UPDATE_AGENT_SESSIONS",

  GET_CUSTOMERS: "GET_CUSTOMERS",
  UPDATE_SELECTED_TICKETS_STATUS: "UPDATE_SELECTED_TICKETS_STATUS",

  //ticket lookup
  CUSTOMER_TICKET_LOOKUP: "CUSTOMER_TICKET_LOOKUP",
  UPDATE_FETCHING_TICKET: "UPDATE_FETCHING_TICKET",
});
