import React from "react";
import { Box, Avatar, Text, HStack, Badge, VStack } from "@chakra-ui/react";
import { formatTime } from "../store/helpers";

const AgentCard = ({ agentName, status, duration, userId }) => {
  return (
    <Box
      maxW="md"
      mt={3}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      boxShadow="sm"
    >
      <HStack spacing={4}>
        <Avatar name={agentName} />
        <VStack align="start">
          <Text fontWeight="bold" fontSize="lg">
            {agentName}
          </Text>
          <HStack spacing={2}>
            <Badge
              variant="solid"
              colorScheme={status === 1 ? "green" : "red"}
              borderRadius="full"
              px={2}
              py={1}
            >
              {status === 1 ? "Online" : "Offline"}
            </Badge>
            <Text fontSize="sm" color="gray.500" id={`formartedTime-${userId}`}>
              {formatTime(duration)}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default AgentCard;
