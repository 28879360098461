import {
  Modal,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  ModalHeader,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubscription } from "../../../../store/actions/subscriptionsActions";
import { resetDeleteModal } from "../../../../store/actions/miscAction";

const DeleteModal = () => {
  const dispatch = useDispatch();
  const { deleteModal } = useSelector((state) => state.miscReducer);
  const { isOpen, subscriptionId: id } = deleteModal;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(resetDeleteModal());
      }}
    >
      <ModalOverlay bg="blackAlpha.300" />
      <ModalContent bg="white" boxShadow="lg" borderRadius="md">
        <ModalHeader
          fontWeight="bold"
          fontSize="xl"
          color="gray.600"
          borderBottomWidth="1px"
          pb={2}
        >
          Delete Subscription
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box bg="red.50" p={4} borderRadius="md">
            <Text fontSize="lg" fontWeight="bold" color="red.600" mb={2}>
              Warning
            </Text>
            <Text color="red.500">
              Are you sure you want to delete your subscription? This action
              cannot be undone.
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={() => {
              dispatch(resetDeleteModal());
            }}
            _hover={{ bg: "gray.100" }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            isLoading={false}
            onClick={() => {
              dispatch(deleteSubscription(id));
              dispatch(resetDeleteModal());
            }}
            bg="red.500"
            color="white"
            _hover={{ bg: "red.600" }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
