import { ACTION_TYPES } from "../actions/types";
import { APP_STATUSES } from "../constants";

const initialState = {
  appStatus: APP_STATUSES.LOGGED_OUT, //logged in or not
  authLoading: false,
  userId: "",
  userData: {},
  iamRoles: [],
  verifyStatus: 0,
  userPermissions: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };

    case ACTION_TYPES.SET_AUTH_LOADING:
      return {
        ...state,
        authLoading: action.payload,
      };

    case ACTION_TYPES.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };

    case ACTION_TYPES.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    case ACTION_TYPES.GET_IAM_ROLES:
      return {
        ...state,
        iamRoles: action.payload,
      };

    case ACTION_TYPES.UPDATE_VERIFY_STATUS:
      return {
        ...state,
        verifyStatus: action.payload,
      };
    case ACTION_TYPES.GET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    default:
      return state;
  }
}
