import { Box, Center, Heading, Icon, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import BranchAgentStatusReport from "../../../components/branches/reports/realtime/BranchAgentStatus";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";

const BranchAgentStatus = ({ showFilterForm = true, reportIndex = 1 }) => {
  const { branches, branchId, organizationId, reportRange, workDay } = useSelector(
    (state) => state.defaultReducer
  );
  let branchesClone = _.cloneDeep(branches);
  branchesClone = branchesClone
    .filter(
      (a) =>
        a.reportStatus != null &&
        Number(a.reportStatus) &&
        a.organizationId === organizationId
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));
  const reportTitle = `Agent Status`;

  return (
    <Box>
      <Helmet>
        <title>{reportTitle}</title>
      </Helmet>
      {showFilterForm ? <FilterForm /> : <></>}
      <>
        <Heading fontSize="34px" color="#222" fontWeight="700">
          {reportTitle}
          <Tooltip
            label={getReportDescription(reportRange).BranchAgentStatus}
            aria-label="A tooltip"
          >
            <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
          </Tooltip>
        </Heading>
        {_.isEmpty(workDay) ? (
          <Loader />
        ) : (
          <BranchAgentStatusReport
            reportIndex={reportIndex}
            branches={branchesClone}
          />
        )}
      </>
    </Box>
  );
};

export default BranchAgentStatus;
