import { Box } from "@chakra-ui/react";
import _ from "lodash";
import TicketsPerBranchTable from "../../tables/daily/TicketsPerBranchTable";

const OrganizationTicketsPerBranch = ({ data }) => {
  return (
    <Box>
      {data.map((branchData, index) => {
        return (
          <>
            <Box className="reportContainer" mt="20px">
              <TicketsPerBranchTable
                index={index}
                branchData={branchData}
                
              />
            </Box>
          </>
        );
      })}
    </Box>
  );
};

export default OrganizationTicketsPerBranch;
