import _ from 'lodash';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  Box, HStack, Heading, SimpleGrid, Text, VStack,
} from '@chakra-ui/react';
import {
  IoPeopleOutline,
  IoTimeOutline,
  IoCheckmarkCircleOutline,
  IoIdCardOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { checkServiceHasChildren, formatTime } from '../../../../store/helpers';
import PropTypes from 'prop-types';

import { TICKET_STATUS } from '../../../../store/constants';
import StatsCard from '../../../StatsCard';
import CustomerCard from '../../../CustomerCard';
import SelectBranchInfo from '../../../misc/SelectBranchInfo';
import useTickets from '../../../../hooks/reports/branches/realtime/useTickets';
import useAgents from '../../../../hooks/reports/branches/realtime/useAgents';

function CustomersInQueue({ branches }) {
  CustomersInQueue.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  CustomersInQueue.defaultProps = {
    branches: [],
  };

  const {
    servicesSubscriptions,
    services,
    users,
    organizationId,
    workDay,
    branchId
  } = useSelector((state) => state.defaultReducer);

  const {
    tickets,
    customers,
    selectedTicketsStatus,
    timeNow
  } = useSelector((state) => state.realtimeReducer);
  const { numCompletedTickets, numActiveTickets, numNoShowTickets } = useTickets({ branchId });
  const { numOnline, numAgents} = useAgents({ branchId });

  const { iamRoles } = useSelector((state) => state.permissionsReducer);

  const [expandedAll, setExpandedAll] = useState([0, 0, 0, 0]);
  const agentRoleId = iamRoles.find((a) => a.name === 'agent')?.id;

  const agents = _.cloneDeep(users).filter(
    (a) => Number(a.agentStatus) === 1 || a.roleId === agentRoleId,
  );

  const ticketsClone = _.cloneDeep(tickets)
    .filter((a) => a.organizationId === organizationId)
    .filter(
      (a) => !selectedTicketsStatus.length
        || (selectedTicketsStatus.length
          && Number(a.status) === Number(selectedTicketsStatus)),
    );
  const customersClone = _.cloneDeep(customers).filter(
    (a) => a.organizationId === organizationId,
  );
  const todayCustomers = customersClone.filter((a) => dayjs.unix(a.updated).isSame(dayjs(), 'day'));

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = todayCustomers.filter((a) => a.branchId === branchId);
    return acc;
  }, {});
  const branchesData = Object.keys(branchesMap).map((branchId) => {
    let branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branchId,
    )?.services;

    branchServices = checkServiceHasChildren(servicesClone)
      .filter(
        (service) => branchServices?.includes(service.id) && !service.hasChildren,
      )
      .map((service) => service.id);
    const branchName = branches.find((branch) => branch.id === branchId)?.name
      || `Branch-${branchId}`;
    const customersData = ticketsClone
      .filter((a) => a.branchId === branchId)
      .map((ticket) => {
        const customer = todayCustomers.find(
          (a) => a.mobileNumber === ticket.mobileNumber,
        );
        const agent = agents.find((a) => a.id === ticket?.manage?.userId);
        const agentFullName = agent
          ? `${agent?.name} ${agent?.lastname}`
          : 'Unnamed Agent';
        const waitingTime = Number(ticket.status) < 1
          ? dayjs().unix() - ticket.created
          : ticket.sessionStart - ticket.created;
        const serviceName = servicesClone.find(
          (a) => a.id === ticket.serviceId,
        )?.name;
        let totalTimeInBranch = null;
        if (ticket.status === TICKET_STATUS.COMPLETED) {
          totalTimeInBranch = Math.abs(
            dayjs
              .unix(ticket.sessionStart)
              .diff(dayjs.unix(ticket.created), 'seconds'),
          ) + ticket.sessionInSeconds;
        } else if (
          ticket.status === TICKET_STATUS.ACTIVE
          || ticket.status === TICKET_STATUS.IN_QUEUE
        ) {
          totalTimeInBranch = timeNow - ticket.created;
        }

        return {
          customer,
          waitingTime,
          serviceName,
          agentFullName,
          ticket,
          totalTimeInBranch,
        };
      })
      .filter(Boolean)
      .sort((a, b) => a.ticket.status - b.ticket.status);
    const numQueued = customersData.filter(
      (a) => Number(a.ticket.status) === TICKET_STATUS.IN_QUEUE,
    ).length;
    const maxQueueTimeNow = Math.max(
      ...customersData
        .filter((a) => Number(a.ticket.status) === TICKET_STATUS.IN_QUEUE)
        .map((a) => dayjs().diff(dayjs.unix(a.ticket.created), 'seconds')),
    );

    return {
      branchName,
      branchServices,
      branchId,
      numCustomers:
        ticketsClone.filter((a) => a.branchId === branchId)?.length || 0,
      customersData,
      numQueued,
      maxQueueTimeNow,
    };
  });

  const toggleExpandIndex = (index) => {
    setExpandedAll((prevState) => {
      const newState = [...prevState];
      newState[index] = newState[index] === 0 ? 1 : 0;
      return newState;
    });
  };


  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {branchesData?.map(
            (
              {
                customersData,
                numCustomers,
                branchId,
                numQueued,
                maxQueueTimeNow,
              },
              i,
            ) => {
              const joinedQueueThisHour = ticketsClone.filter(
                (a) => a.branchId === branchId
                  && dayjs.unix(a.created).format('h A') === dayjs().format('h A'),
              ).length;
              const stats = [
                {
                  title: 'Total Customers Today',
                  stat: numCustomers,
                  icon: IoPeopleOutline,
                  iconColor: 'var(--green)',
                },
                {
                  title: 'Customers in Queue',
                  stat: numQueued,
                  icon: IoPeopleOutline,
                  iconColor: 'var(--orange)',
                },
                {
                  title: 'Joined Queue This Hour',
                  stat: joinedQueueThisHour,
                  icon: IoTimeOutline,
                  iconColor: 'var(--green)',
                },
                {
                  title: 'Max. Queue Time Now',
                  stat: !Number.isNaN(maxQueueTimeNow) && Number.isFinite(maxQueueTimeNow) ? formatTime(maxQueueTimeNow) : 'NA',
                  statNumberId: `maxQueueTimeNow-${branchId}`,
                  icon: IoTimeOutline,
                  iconColor: 'var(--red)',
                },

                {
                  title: 'Customers Being Served Now',
                  stat: numActiveTickets,
                  icon: IoPeopleOutline,
                  iconColor: 'var(--green)',
                },
                {
                  title: 'Completed',
                  stat: numCompletedTickets,
                  icon: IoCheckmarkCircleOutline,
                  iconColor: 'var(--orange)',
                },
                {
                  title: 'No Show',
                  stat: numNoShowTickets,
                  icon: IoCloseCircleOutline,
                  iconColor: 'var(--green)',
                },
                {
                  title: 'Online Agents',
                  stat: `${numOnline}/${numAgents}`,
                  statNumberId: `maxQueueTimeNow-${branchId}`,
                  icon: IoIdCardOutline,
                  iconColor: 'var(--black-dry)',
                },
                
              ];

              return (
                <Box key={branchId}>
                  {numCustomers > 0 ? (
                    <Box mt={4}>
                      <SimpleGrid
                        columns={{ base: 1, md: 4 }}
                        spacing={0}
                        mb={"10px"}
                      >
                        {
                      stats.map((a, i) => (
                        <Box
                          key={i}
                          px="20px"
                          py="15px"
                          mb="5px"
                          border="1px solid var(--borderGrey)"
                          borderRight={{
                            base: '1px solid #E5E4E5',
                            md: [3, 7].includes(i) ? '1px solid #E5E4E5' : 'none',
                          }}
                          borderRadius={{
                            base: '10px',
                            md: [0, 4].includes(i)
                              ? '10px 0 0 10px'
                              : [3, 7].includes(i)
                                ? '0 10px 10px 0'
                                : '0',
                          }}
                        >
                          <StatsCard title={a.title} stat={a.stat} statNumberId={a.statNumberId} icon={a.icon} iconColor={a.iconColor} />
                        </Box>
                      ))
                    }
                      </SimpleGrid>
                    </Box>
                  ) : (
                    <Box>
                      <Text>No customers for this branch</Text>
                    </Box>
                  )}

                  <VStack
                    spacing="50px"
                    maxW={{ base: '100%',  md: '100%', lg: '70%', xl: '50%' }}
                    align="start"
                    mt="40px"
                  >
                  {/* active */}
                  {
                  (selectedTicketsStatus === '' || Number(selectedTicketsStatus) === 1) && (
                  <Box w="100%">
                    <HStack
                      justifyContent="space-between"
                    >
                      <Heading fontSize="22px" fontWeight={400} color="var(--gray-45)">
                        Customers Being Served Now (
                        {customersData?.filter((a) => a.ticket.status === 1).length || 0}
                        )
                      </Heading>
                      <Text
                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => toggleExpandIndex(1)}
                      >
                        {
                          customersData?.filter((a) => a.ticket.status === 1).length || 0
                            ? expandedAll[1] ? 'Collapse All' : 'Expand All'
                            : ''
                        }
                      </Text>
                    </HStack>
                    {
                    customersData?.filter((a) => a.ticket.status === 1)
                      .map((a) => <CustomerCard key={a.ticket.id} {...a} expandedAll={expandedAll[1]} />)
                  }
                  </Box>
                  )
                }
                {/* in queue */}
                {
                  (selectedTicketsStatus === '' || Number(selectedTicketsStatus) === 0) && (
                  <Box w="100%">
                    <HStack
                      justifyContent="space-between"
                    >
                      <Heading fontSize="22px" fontWeight={400} color="var(--gray-45)">
                        Customers in Queue (
                        {customersData?.filter((a) => a.ticket.status === 0).length || 0}
                        )
                      </Heading>
                      <Text
                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => toggleExpandIndex(0)}
                      >
                        {
                        customersData?.filter((a) => a.ticket.status === 0).length || 0
                          ? expandedAll[0] ? 'Collapse All' : 'Expand All'
                          : ''
                        }
                      </Text>
                    </HStack>
                    {
                      customersData?.filter((a) => a.ticket.status === 0)
                        .map((a) => <CustomerCard key={a.ticket.id} {...a} expandedAll={expandedAll[0]} />)
                    }
                  </Box>
                  )
                }
                {/* No shows */}
                {
                Number(selectedTicketsStatus) === 2 && (
                <Box w="100%">
                  <HStack
                    justifyContent="space-between"
                  >
                    <Heading fontSize="22px" fontWeight={400} color="var(--gray-45)">
                      No-show Customers (
                      {customersData?.filter((a) => a.ticket.status === 2).length || 0}
                      )
                    </Heading>
                    <Text
                      _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => toggleExpandIndex(2)}
                    >
                      {
                        customersData?.filter((a) => a.ticket.status === 2).length || 0
                          ? expandedAll[2] ? 'Collapse All' : 'Expand All'
                          : ''
                      }
                    </Text>
                  </HStack>
                  {
                    customersData?.filter((a) => a.ticket.status === 2)
                      .map((a) => <CustomerCard key={a.ticket.id} {...a} expandedAll={expandedAll[2]} />)
                  }
                </Box>
                )
                }
                {/* completed tickets */}
                {
                  Number(selectedTicketsStatus) === 3 && (
                  <Box w="100%">
                    <HStack
                      justifyContent="space-between"
                    >
                      <Heading fontSize="22px" fontWeight={400} color="var(--gray-45)">
                        Completed Tickets (
                        {customersData?.filter((a) => a.ticket.status === 3).length || 0}
                        )
                      </Heading>
                      <Text
                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => toggleExpandIndex(3)}
                      >
                        {
                        customersData?.filter((a) => a.ticket.status === 3).length || 0
                          ? expandedAll[3] ? 'Collapse All' : 'Expand All'
                          : ''
                        }
                      </Text>
                    </HStack>
                    {
                    customersData?.filter((a) => a.ticket.status === 3)
                      .map((a) => <CustomerCard key={a.ticket.id} {...a} expandedAll={expandedAll[3]} />)
                  }
                  </Box>
                  )
                }
                  </VStack>
                  {/* below code will be for filtering between the rest */}

                </Box>
              );
            },
          )}
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
}

export default CustomersInQueue;
