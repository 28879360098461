import { Box, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import BranchAgentServiceTimePerService from "../../../components/branches/reports/daily/BranchAgentServiceTimePerService";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import _ from "lodash";
import { Helmet } from "react-helmet";

const DailyBranchAgentServiceTimePerService = ({
  showFilterForm = true,
  
  showTitle = 1,
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId =
    REPORT_TYPE_IDS.daily_branch_agent_service_time_per_service;

  const reportsDataClone = _.cloneDeep(reportsData);
  let reports = reportsDataClone.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId.length > 0) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data) || [];

  const title = `Agent Service Time Per Service`;
  

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Daily Branch Agent Service Time Per Service</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <Heading fontSize="34px" color="#222" fontWeight="700">
                {title}

                <Tooltip
                  label={
                    getReportDescription(reportRange).AgentServiceSessionTime
                  }
                  aria-label="A tooltip"
                >
                  <Icon as={InfoIcon} w={5} h={5} ml={2} cursor="pointer" />
                </Tooltip>
              </Heading>
              <Text fontSize="16px" color="#666" fontWeight={400} mt="10px">
                {getReportDescription(reportRange).AgentServiceSessionTime}
              </Text>

              <BranchAgentServiceTimePerService data={data} />
            </>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default DailyBranchAgentServiceTimePerService;
