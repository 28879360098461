import { useSelector } from "react-redux";
import _ from "lodash";
import { TICKET_STATUS } from "../../../../store/constants";
import {
  checkServiceHasChildren,
} from "../../../../store/helpers";

const useServices = ({ branchId }) => {
  const { servicesSubscriptions, services } =
    useSelector((state) => state.defaultReducer);
  const { tickets } = useSelector((state) => state.realtimeReducer);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  const ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.branchId === branchId
  );

  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  )?.services;

  branchServices = checkServiceHasChildren(servicesClone).filter(
    (service) => branchServices?.includes(service.id) && !service.hasChildren
  );

  const servicesData = branchServices
    .map(({ id: serviceId, name }) => {
      const serviceTickets = ticketsClone.filter(
        (a) => a.serviceId === serviceId
      );
      const completed = serviceTickets.filter(
        (a) => Number(a.status) === TICKET_STATUS.COMPLETED
      ).length;
      const active = serviceTickets.filter(
        (a) => Number(a.status) === TICKET_STATUS.ACTIVE
      ).length;
      const inQueue = serviceTickets.filter(
        (a) => Number(a.status) === TICKET_STATUS.IN_QUEUE
      ).length;

      const total = serviceTickets.length;
      return {
        serviceId,
        serviceName: name,
        completed,
        active,
        inQueue,
        total,
      };
    })
    .sort((a, b) => b.total - a.total);

  return {
    servicesData,
  };
};

export default useServices;
