import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, Button } from "@chakra-ui/react";
import { useState } from "react";

import { getReportsData } from "../store/actions/defaultAction";
import BranchSelection from "./filterForm/BranchSelection";

import DatePicker from "./filterForm/DatePicker";
import InputComponent from "./averly/Input";
import { formatTicketNumber } from "../store/helpers";
import {
  getTicketsByDate,
  ticketLookup,
} from "../store/actions/realtimeAction";
import { REPORT_RANGES } from "../store/constants";
import StatusSelection from "./filterForm/StatusSelection";

function FilterForm({ isTicketLookUp = false, isRTTicketsReport = false }) {
  const dispatch = useDispatch();
  const [ticketNumber, setTicketNumber] = useState("");
  const { dateRange, reportRange, organizationId, branchId } = useSelector(
    (store) => store.defaultReducer
  );

  const onTicketNumberInput = (value) => {
    const formatedNumber = formatTicketNumber(value);
    if (formatedNumber !== null) {
      setTicketNumber(formatedNumber);
    }
  };

  if (!organizationId.length) {
    return null;
  }

  return (
    <Box
      overflow="visible"
      borderBottom="1px solid #ebebeb"
      m="0 -15px 30px"
      p="0 15px 15px"
    >
      <HStack overflow="visible" spacing="15px">
        <Box>
          <BranchSelection />
        </Box>
        {isRTTicketsReport ? (
          <Box>
            <StatusSelection />
          </Box>
        ) : (
          <></>
        )}
        {reportRange !== REPORT_RANGES.RT && (
          <Box>
            <DatePicker />
          </Box>
        )}

        {isTicketLookUp ? (
          <Box>
            <InputComponent
              label="Ticket Number"
              placeholder="A-16"
              value={ticketNumber}
              onChange={onTicketNumberInput}
            />
          </Box>
        ) : (
          <></>
        )}
        {reportRange !== REPORT_RANGES.RT && (
          <Box>
            <Button
              w="200px"
              h="56px"
              borderRadius="8px"
              background="#222"
              color="var(--white)"
              _hover={{ bg: "#000" }}
              onClick={() => {
                if (isTicketLookUp) {
                  dispatch(
                    ticketLookup(
                      ticketNumber,
                      dateRange,
                      organizationId,
                      branchId
                    )
                  );
                } else if (isRTTicketsReport) {
                  dispatch(
                    getTicketsByDate({
                      organizationId,
                      branchId,
                      created: dateRange[0],
                    })
                  );
                } else {
                  dispatch(
                    getReportsData(organizationId, dateRange, reportRange)
                  );
                }
              }}
            >
              Search
            </Button>
          </Box>
        )}
      </HStack>
    </Box>
  );
}

export default FilterForm;
