import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";
import { TICKET_STATUS } from "../../../../store/constants";

const useCustomers = () => {
  const { customers, tickets } = useSelector((state) => state.realtimeReducer);
  const { services, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.organizationId === organizationId
  );
  const servicesClone = _.cloneDeep(services);
  const organizationCustomers = customers.filter(
    (a) => a.organizationId === organizationId
  );

  const customersData = ticketsClone
    .map((ticket) => {
      const customer = organizationCustomers.find(
        (a) => a.mobileNumber === ticket.mobileNumber
      );
      const waitingTime =
        Number(ticket.status) < 1
          ? dayjs().unix() - ticket.created
          : ticket.sessionStart - ticket.created;
      const serviceName = servicesClone.find(
        (a) => a.id === ticket.serviceId
      )?.name;
      let totalTimeInBranch = null;
      if (ticket.status === TICKET_STATUS.COMPLETED) {
        totalTimeInBranch =
          Math.abs(
            dayjs
              .unix(ticket.sessionStart)
              .diff(dayjs.unix(ticket.created), "seconds")
          ) + ticket.sessionInSeconds;
      } else if (
        ticket.status === TICKET_STATUS.ACTIVE ||
        ticket.status === TICKET_STATUS.IN_QUEUE
      ) {
        totalTimeInBranch = dayjs().diff(dayjs.unix(ticket.created), "seconds");
      }

      return {
        customer,
        waitingTime,
        serviceName,
        ticket,
        totalTimeInBranch,
      };
    })
    .filter(Boolean);

  const numQueued = customersData.filter(
    (a) => Number(a.ticket.status) === TICKET_STATUS.IN_QUEUE
  ).length;
  const maxQueueTimeNow = Math.max(
    ...customersData
      .filter((a) => Number(a.ticket.status) === TICKET_STATUS.IN_QUEUE)
      .map((a) => dayjs().diff(dayjs.unix(a.ticket.created), "seconds"))
  );
  const longestQueueingCustomer = customersData
    .filter((a) => Number(a.ticket.status) === TICKET_STATUS.IN_QUEUE)
    .find(
      (a) =>
        dayjs().diff(dayjs.unix(a.ticket.created), "seconds") ===
        maxQueueTimeNow
    );
  const ticketLetter = longestQueueingCustomer?.ticket?.ticketLetter;
  const ticketNumber = longestQueueingCustomer?.ticket?.ticketNumber;
  const ticketNumOfLongestQueueingCustomer = ticketNumber
    ? `${ticketLetter}-${ticketNumber}`
    : "NA";

  return {
    numQueued,
    maxQueueTimeNow,
    longestQueueingCustomer,
    ticketNumOfLongestQueueingCustomer,
  };
};

export default useCustomers;
