import { Box, Text } from "@chakra-ui/react";

import MonthlyPicker from "./calendar/month/";
import WeeklyPicker from "./calendar/week/";
import DailyPicker from "./calendar/day/";

import "./index.css";

const AverlyDatePicker = ({ label, styling, value, maxDate, onChange, calendarType }) => {
    const calendarOptions = {
        month: MonthlyPicker,
        week: WeeklyPicker,
        day: DailyPicker,
    }   

    return (
        <Box>
            <Text
                h="15px"
                lineHeight="15px"
                fontSize="12px"
                color="#6a6a6a"
                textTransform="capitalize"
            >{label}</Text>
            <Box
                h="20px"
                w="100%"
                fontSize="16px"
                color="#222"
            >
                <Box
                    as={calendarOptions[calendarType]}
                    styling={styling}
                    value={value}
                    maxDate={maxDate}
                    onChange={onChange}
                    zIndex="999999"
                    position="relative"
                >
                </Box>
            </Box>
        </Box>
    );
}

export default AverlyDatePicker;