import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";

// start monthly report ----
import FullReport from "./pages/FullReport";
import FullreportArrivalRates from "./pages/FullreportArrivalRates";
import FullreportAgentPerformance from "./pages/FullreportAgentPerformance";
import FullreportTicketManagement from "./pages/FullreportTicketManagement";
// Organization
import OrganizationTickets from "./pages/organization/OrganizationTickets";
import TicketsPerService from "./pages/organization/OrganizationTicketsPerService";
import ServiceTimePerService from "./pages/organization/OrganizationServiceTimePerService";
import ArrivalRatesPerHour from "./pages/organization/OrganizationArrivalRatesPerHour";
import ArrivalRatesPerDay from "./pages/organization/OrganizationArrivalRatesPerDay";
import ArrivalRatesPerService from "./pages/organization/OrganizationArrivalRatesPerService";
import TicketsPerBranch from "./pages/organization/OrganizationTicketsPerBranch";

// Arrival rates
import BranchesArrivalRatesPerHour from "./pages/branches/BranchArrivalRatesPerHour";
import BranchesArrivalRatesPerDay from "./pages/branches/BranchArrivalRatesPerDay";
import BranchesArrivalRatesPerService from "./pages/branches/BranchArrivalRatesPerService";

// Service times
import ServiceTimePerServiceBranches from "./pages/branches/BranchServiceTimePerService";
import AgentServiceTimePerService from "./pages/branches/BranchAgentServiceTimePerService";

// Ticket Management
import TicketsPerServiceBranches from "./pages/branches/BranchTicketsPerService";
import TicketsPerAgent from "./pages/branches/BranchTicketsPerAgent";
import AgentTicketsByService from "./pages/branches/BranchAgentTicketsPerService";

// Customers
import BranchCustomersInBranchPerHour from "./pages/branches/BranchCustomersInBranchPerHour";
// end monthly reports
// daily
import DailyOrganizationArrivalRatesPerHour from "./pages/organization/daily/DailyOrganizationArrivalRatesPerHour";
import DailyOrganizationArrivalRatesPerService from "./pages/organization/daily/DailyOrganizationArrivalRatesPerService";
import DailyOrganizationServiceTimePerService from "./pages/organization/daily/DailyOrganizationServiceTimePerService";
import DailyOrganizationTickets from "./pages/organization/daily/DailyOrganizationTickets";
import DailyOrganizationTicketsPerBranch from "./pages/organization/daily/DailyOrganizationTicketsPerBranch";
import DailyOrganizationTicketsPerService from "./pages/organization/daily/DailyOrganizationTicketsPerService";
// branches
import DailyBranchAgentServiceTimePerService from "./pages/branches/daily/DailyBranchAgentServiceTimePerService";
import DailyBranchAgentTicketsPerService from "./pages/branches/daily/DailyBranchAgentTicketsPerService";
import DailyBranchArrivalRatesPerHour from "./pages/branches/daily/DailyBranchArrivalRatesPerHour";
import DailyBranchArrivalRatesPerService from "./pages/branches/daily/DailyBranchArrivalRatesPerService";
import DailyBranchServiceTimePerService from "./pages/branches/daily/DailyBranchServiceTimePerService";
import DailyBranchTicketsPerAgent from "./pages/branches/daily/DailyBranchTicketsPerAgent";
import DailyBranchTicketsPerService from "./pages/branches/daily/DailyBranchTicketsPerService";
import DailyBranchCustomersInBranchPerHour from "./pages/branches/daily/DailyBranchCustomersInBranchPerHour";

// daily reports
import DailyFullReport from "./pages/reports/daily/DailyFullReport.js";
import DailyFullReportArrivalRates from "./pages/reports/daily/FullreportArrivalRates";
import DailyFullReportAgentPerformance from "./pages/reports/daily/FullReportAgentPerformance";
import DailyFullReportTicketManagement from "./pages/reports/daily/FullreportTicketManagement";

// realtime
import RTOrganizationArrivalRatesPerHour from "./pages/organization/realtime/OrganizationArrivalRatesPerHour";
import RTOrganizationAverageSessionTimePerBranch from "./pages/organization/realtime/OrganizationAverageSessionTimePerBranch";
import RTOrganizationAverageWaitTimePerBranch from "./pages/organization/realtime/OrganizationAverageWaitTimePerBranch";
import RTOrganizationLiveDashboard from "./pages/organization/realtime/OrganizationLiveDashboard";

// branches
import RTBranchArrivalRatesPerHour from "./pages/branches/realtime/BranchArrivalRatesPerHour";
import RTBranchAverageSessionTimePerAgent from "./pages/branches/realtime/BranchAverageSessionTimePerAgent";
import RTBranchAverageIdleTimePerAgent from "./pages/branches/realtime/BranchAverageIdleTimePerAgent";
import RTBranchFirstContactResolutionRate from "./pages/branches/realtime/BranchFirstContactResolutionRate";
import RTBranchAbondonmentRate from "./pages/branches/realtime/BranchAbondonmentRate";

import RTBranchAgentStatus from "./pages/branches/realtime/BranchAgentStatus";
import RTBranchServiceTransfers from "./pages/branches/realtime/BranchServiceTransfers";
import RTCustomersInQueue from "./pages/branches/realtime/CustomersInQueue";

import RTBranchLiveDahboard from "./pages/branches/realtime/BranchLiveDashboard";
import BranchNumberOfCustomersInHour from "./pages/branches/realtime/BranchNumberOfCustomersInHour";

// ticketInfo
import TIBranchTicketLookup from "./pages/branches/ticket-info/BranchTicketLookup";
import TIBranchTicketsByDate from "./pages/branches/ticket-info/BranchTicketsByDate";

// Reports
import Subscriptions from "./pages/reports/Subscriptions";

function RoutesComponent() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <Layout>
      <ToastContainer position="bottom-center" />
      <Routes>
        {/* Monthly */}
        <Route path="/monthly/reports/full-report/" element={<FullReport />} />
        <Route
          path="/monthly/reports/arrival-times/"
          element={<FullreportArrivalRates />}
        />
        <Route
          path="/monthly/reports/agent-performance/"
          element={<FullreportAgentPerformance />}
        />
        <Route
          path="/monthly/reports/ticket-management/"
          element={<FullreportTicketManagement />}
        />
        <Route path="/" element={<Dashboard />} />
        {/* organization */}
        <Route
          path="/monthly/organization/tickets/"
          element={<OrganizationTickets />}
        />
        <Route
          path="/monthly/organization/tickets-per-service/"
          element={<TicketsPerService />}
        />
        <Route
          path="/monthly/organization/service-tim-per-service/"
          element={<ServiceTimePerService />}
        />
        <Route
          path="/monthly/organization/arrival-rates-per-hour"
          element={<ArrivalRatesPerHour />}
        />
        <Route
          path="/monthly/organization/arrival-rates-per-day"
          element={<ArrivalRatesPerDay />}
        />
        <Route
          path="/monthly/organization/arrival-rates-per-service"
          element={<ArrivalRatesPerService />}
        />
        <Route
          path="/monthly/organization/tickets-per-branch"
          element={<TicketsPerBranch />}
        />
        {/* Arrival Rates */}
        <Route
          path="/monthly/branches/arrival-rates-per-hour"
          element={<BranchesArrivalRatesPerHour />}
        />
        <Route
          path="/monthly/branches/arrival-rates-per-service/"
          element={<BranchesArrivalRatesPerService />}
        />
        <Route
          path="/monthly/branches/arrival-rates-per-day/"
          element={<BranchesArrivalRatesPerDay />}
        />
        {/* Service Times */}
        <Route
          path="/monthly/branches/service-tim-per-service/"
          element={<ServiceTimePerServiceBranches />}
        />
        <Route
          path="/monthly/branches/agent-service-time-per-service"
          element={<AgentServiceTimePerService />}
        />
        {/* Tickets */}
        <Route
          path="/monthly/branches/tickets-per-service/"
          element={<TicketsPerServiceBranches />}
        />
        <Route
          path="/monthly/branches/tickets-per-agents"
          element={<TicketsPerAgent />}
        />
        <Route
          path="/monthly/branches/agent-tickets-per-service"
          element={<AgentTicketsByService />}
        />

        <Route
          path="/monthly/branches/customers-in-branch-per-hour"
          element={<BranchCustomersInBranchPerHour />}
        />
        {/* Weekly */}
        <Route path="/weekly/reports/full-report/" element={<FullReport />} />
        <Route
          path="/weekly/reports/arrival-times/"
          element={<FullreportArrivalRates />}
        />
        <Route
          path="/weekly/reports/agent-performance/"
          element={<FullreportAgentPerformance />}
        />
        <Route
          path="/weekly/reports/ticket-management/"
          element={<FullreportTicketManagement />}
        />
        <Route path="/" element={<Dashboard />} />
        {/* organization */}
        <Route
          path="/weekly/organization/tickets/"
          element={<OrganizationTickets />}
        />
        <Route
          path="/weekly/organization/tickets-per-service/"
          element={<TicketsPerService />}
        />
        <Route
          path="/weekly/organization/service-tim-per-service/"
          element={<ServiceTimePerService />}
        />
        <Route
          path="/weekly/organization/arrival-rates-per-hour"
          element={<ArrivalRatesPerHour />}
        />
        <Route
          path="/weekly/organization/arrival-rates-per-day"
          element={<ArrivalRatesPerDay />}
        />
        <Route
          path="/weekly/organization/arrival-rates-per-service"
          element={<ArrivalRatesPerService />}
        />
        <Route
          path="/weekly/organization/tickets-per-branch"
          element={<TicketsPerBranch />}
        />
        {/* Arrival Rates */}
        <Route
          path="/weekly/branches/arrival-rates-per-hour"
          element={<BranchesArrivalRatesPerHour />}
        />
        <Route
          path="/weekly/branches/arrival-rates-per-service/"
          element={<BranchesArrivalRatesPerService />}
        />
        <Route
          path="/weekly/branches/arrival-rates-per-day/"
          element={<BranchesArrivalRatesPerDay />}
        />
        {/* Service Times */}
        <Route
          path="/weekly/branches/service-tim-per-service/"
          element={<ServiceTimePerServiceBranches />}
        />
        <Route
          path="/weekly/branches/agent-service-time-per-service"
          element={<AgentServiceTimePerService />}
        />
        {/* Tickets */}
        <Route
          path="/weekly/branches/tickets-per-service/"
          element={<TicketsPerServiceBranches />}
        />
        <Route
          path="/weekly/branches/tickets-per-agents"
          element={<TicketsPerAgent />}
        />
        <Route
          path="/weekly/branches/agent-tickets-per-service"
          element={<AgentTicketsByService />}
        />

        <Route
          path="/weekly/branches/customers-in-branch-per-hour"
          element={<BranchCustomersInBranchPerHour />}
        />
        {/* Daily */}
        <Route path="/" element={<Dashboard />} />
        {/* organization */}
        <Route
          path="/daily/organization/tickets/"
          element={<DailyOrganizationTickets />}
        />
        <Route
          path="/daily/organization/tickets-per-service/"
          element={<DailyOrganizationTicketsPerService />}
        />
        <Route
          path="/daily/organization/service-tim-per-service/"
          element={<DailyOrganizationServiceTimePerService />}
        />
        <Route
          path="/daily/organization/arrival-rates-per-hour"
          element={<DailyOrganizationArrivalRatesPerHour />}
        />
        <Route
          path="/daily/organization/arrival-rates-per-service"
          element={<DailyOrganizationArrivalRatesPerService />}
        />
        <Route
          path="/daily/organization/tickets-per-branch"
          element={<DailyOrganizationTicketsPerBranch />}
        />
        {/* Arrival Rates */}
        <Route
          path="/daily/branches/arrival-rates-per-hour"
          element={<DailyBranchArrivalRatesPerHour />}
        />
        <Route
          path="/daily/branches/arrival-rates-per-service/"
          element={<DailyBranchArrivalRatesPerService />}
        />
        {/* Service Times */}
        <Route
          path="/daily/branches/service-tim-per-service/"
          element={<DailyBranchServiceTimePerService />}
        />
        <Route
          path="/daily/branches/agent-service-time-per-service"
          element={<DailyBranchAgentServiceTimePerService />}
        />
        {/* Tickets */}
        <Route
          path="/daily/branches/tickets-per-service/"
          element={<DailyBranchTicketsPerService />}
        />
        <Route
          path="/daily/branches/tickets-per-agents"
          element={<DailyBranchTicketsPerAgent />}
        />
        <Route
          path="/daily/branches/agent-tickets-per-service"
          element={<DailyBranchAgentTicketsPerService />}
        />
        <Route
          path="/daily/reports/full-report/"
          element={<DailyFullReport />}
        />
        <Route
          path="/daily/reports/arrival-times/"
          element={<DailyFullReportArrivalRates />}
        />
        <Route
          path="/daily/reports/agent-performance/"
          element={<DailyFullReportAgentPerformance />}
        />
        <Route
          path="/daily/reports/ticket-management/"
          element={<DailyFullReportTicketManagement />}
        />
        {/* Customers */}

        <Route
          path="/daily/branches/customers-in-branch-per-hour"
          element={<DailyBranchCustomersInBranchPerHour />}
        />
        {/* Real time */}
        <Route
          path="/realtime/organization/dashboard"
          element={<RTOrganizationLiveDashboard />}
        />
        <Route
          path="/realtime/organization/arrival-rates-per-hour"
          element={<RTOrganizationArrivalRatesPerHour />}
        />
        <Route
          path="/realtime/organization/average-session-time-per-branch/"
          element={<RTOrganizationAverageSessionTimePerBranch />}
        />
        <Route
          path="/realtime/organization/average-wait-time-per-branch/"
          element={<RTOrganizationAverageWaitTimePerBranch />}
        />
        <Route
          path="/realtime/branches/arrival-rates-per-hour"
          element={<RTBranchArrivalRatesPerHour />}
        />
        <Route
          path="/realtime/branches/average-session-time-per-agent"
          element={<RTBranchAverageSessionTimePerAgent />}
        />
        <Route
          path="/realtime/branches/average-idle-time-per-agent/"
          element={<RTBranchAverageIdleTimePerAgent />}
        />
        <Route
          path="/realtime/branches/first-contact-resolution-rate/"
          element={<RTBranchFirstContactResolutionRate />}
        />
        <Route
          path="/realtime/branches/abondonment-rate/"
          element={<RTBranchAbondonmentRate />}
        />
        <Route
          path="/realtime/branches/agent-status/"
          element={<RTBranchAgentStatus />}
        />
        <Route
          path="/realtime/branches/service-transfers/"
          element={<RTBranchServiceTransfers />}
        />
        <Route
          path="/realtime/branches/customers-in-queue/"
          element={<RTCustomersInQueue />}
        />
        <Route
          path="/ticket-info/branches/ticket-lookup/"
          element={<TIBranchTicketLookup />}
        />
        <Route
          path="/ticket-info/branches/tickets-by-date/"
          element={<TIBranchTicketsByDate />}
        />

        <Route
          path="/realtime/branches/dashboard"
          element={<RTBranchLiveDahboard />}
        />
        <Route
          path="/realtime/branches/customers-in-branch/"
          element={<BranchNumberOfCustomersInHour />}
        />
        {/* Reports */}
        <Route path="/reports/subscriptions" element={<Subscriptions />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
}
export default RoutesComponent;
