import React from "react";
import {
  Box,
  Heading,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";

const BranchesOverviewCard = ({ data, index, totalIndexes }) => {
  return (
    <Box
      p={"15px"}
      borderRadius={
        index === 0
          ? "10px 10px 0 0" // Top left and top right
          : index === totalIndexes - 1
            ? "0 0 10px 10px" // Bottom left and bottom right
            : "0" // No border radius
      }
      border="1px solid var(--borderGrey)"
      borderBottom={index === totalIndexes - 1 ? "1px solid var(--borderGrey)" : "none"}
    >
      <Heading fontSize="20px" mb={"10px"}>{data.branchName}</Heading>
      <Flex justify="space-between">          
        <Stat>
          <StatLabel>Total</StatLabel>
          <StatNumber fontSize="16px" fontWeight={400}>{data.total}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Completed</StatLabel>
          <StatNumber fontSize="16px" fontWeight={400}>{data.completed}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Active</StatLabel>
          <StatNumber fontSize="16px" fontWeight={400}>{data.active}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>In Queue</StatLabel>
          <StatNumber fontSize="16px" fontWeight={400}>{data.inQueue}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>No Show</StatLabel>
          <StatNumber fontSize="16px" fontWeight={400}>{data.noShow}</StatNumber>
        </Stat>
      </Flex>
    </Box>
  );
};

export default BranchesOverviewCard;
