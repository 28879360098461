import { combineReducers } from "redux";
import defaultReducer from "./defaultReducer";
import authReducer from "./authReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import miscReducer from "./miscReducer";
import permissionsReducer from "./permissionsReducer";
import realtimeReducer from "./realtimeReducer";

const reducer = combineReducers({
  defaultReducer,
  authReducer,
  subscriptionsReducer,
  miscReducer,
  permissionsReducer,
  realtimeReducer,
});

export default reducer;
