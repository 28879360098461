import React from "react";
import { Box } from "@chakra-ui/react";

import TicketsPerServiceTable from "../../../components/organization/tables/TicketsPerServiceTable";

const TicketsByService = ({
  branchId = null,
  branchIndex,
  data,
  numServices = 0,
  branchName = null,
}) => {
  return (
    <Box>
      <TicketsPerServiceTable
        branchId={branchId}
        data={data}
        numServices={numServices}
        branchName={branchName}
        branchIndex={branchIndex}
      />
    </Box>
  );
};

export default TicketsByService;
