import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { IoTimeOutline } from "react-icons/io5";

import {
  checkServiceHasChildren,
  generateHoursArray,
  formatTime,
} from "../../../../store/helpers";
import AgentServiceTimePerServiceLineGraph from "../../../branches/charts/daily/AgentServiceTimePerServiceLineGraph";
import StatsCard from "../../../StatsCard";

const OrganizationAverageSessionTimePerBranch = ({ reportIndex }) => {
  const { workDay, branches, servicesSubscriptions, services, organizationId } =
    useSelector((state) => state.defaultReducer);
  const { tickets, timeNow } = useSelector((state) => state.realtimeReducer);
  let branchesClone = _.cloneDeep(branches);
  const ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.organizationId === organizationId
  );
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  branchesClone = branchesClone.filter(
    (a) => a.reportStatus != null && Number(a.reportStatus)
  );

  const branchesMap = branchesClone.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});

  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchTickets = branchesMap[branchId];
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;
      branchServices = checkServiceHasChildren(servicesClone).filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      );
      const numServices = branchServices.length || 0;

      //BranchChartData

      const startTimeStamp = dayjs()
        .set("hour", workDay[0].split(" ")[0])
        .set("minute", 0)
        .unix();
      const endTimeStamp = timeNow;
      const hoursArray = generateHoursArray(startTimeStamp, endTimeStamp);
      const chart = hoursArray
        .map((hour) => {
          const hourTickets = branchTickets.filter(
            (a) => dayjs.unix(a.created).format("h A") === hour
          );

          const totalSessionTime =
            hourTickets
              .filter((a) => a.sessionStart)
              .reduce((acc, a) => acc + (a.sessionInSeconds || 0), 0) || 0;

          if (workDay.includes(hour))
            return {
              hour,
              total: hourTickets.length,
              hourlyAverage:
                Math.ceil(totalSessionTime / hourTickets.length) || 0,
            };
          return null;
        })
        .filter(Boolean);

      const min = Math.min(...chart.map((a) => a.hourlyAverage)) || 0;
      const max = Math.max(...chart.map((a) => a.hourlyAverage)) || 0;
      const average =
        chart.reduce((a, b) => a + b.hourlyAverage, 0) / chart.length || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;

      return {
        branchName,
        min,
        branchId,
        max,
        total,
        average,
        numServices,
        chart,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets time in descending order
      if (b.total - a.total !== 0) {
        return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.branchName.localeCompare(b.branchName);
    });

  return (
    <Box>
      {branchesData.map((branchData, i) => {
        const { branchName, chart, average, max } = branchData;
        const stats = [
          {
            title: "Avg. Session Time Today",
            stat: formatTime(average),
            icon: IoTimeOutline,
            iconColor: "var(--gray-8)",
          },
          {
            title: "Max. Avg. Session Time Per Hour",
            stat:
              Number.isFinite(max) && !Number.isNaN(max)
                ? formatTime(max)
                : "NA",
            icon: IoTimeOutline,
            iconColor: "var(--gray-8)",
          },
          {
            title: "Avg. Session Time this hour",
            stat: formatTime(
              chart.find((a) => a.hour === dayjs().format("h A"))
                ?.hourlyAverage || 0
            ),
            icon: IoTimeOutline,
            iconColor: "var(--gray-8)",
          },
        ];
        return (
          <Box className="reportContainer" mt="20px" key={i}>
            <Heading fontSize={"24px"} color="var(--black-dry)">
              {branchName} Branch
            </Heading>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={0}
              mb={10}
              mt="20px"
            >
              {stats.map((a, i) => (
                <Box
                  px={"20px"}
                  py={"15px"}
                  mb={"5px"}
                  border={"1px solid var(--borderGrey)"}
                  borderRight={{
                    base: "1px solid #E5E4E5",
                    md: [2, 5].includes(i) ? "1px solid #E5E4E5" : "none",
                  }}
                  borderRadius={{
                    base: "10px",
                    md: [0, 3].includes(i)
                      ? "10px 0 0 10px"
                      : [2, 5].includes(i)
                      ? "0 10px 10px 0"
                      : "0",
                  }}
                >
                  <StatsCard
                    title={a.title}
                    stat={a.stat}
                    icon={a.icon}
                    iconColor={a.iconColor}
                  />
                </Box>
              ))}
            </SimpleGrid>
            <AgentServiceTimePerServiceLineGraph
              chart={chart}
              lineName="Average Session Time In Seconds"
              title={"Average Session Time Per Branch, Hour by Hour"}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default OrganizationAverageSessionTimePerBranch;
