import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import { loadState } from './LocalStorage';

const persistedState = loadState();

let initialState = persistedState !== null ? { ...persistedState } : {};
if(initialState.tickets != null ){
  // initialState.tickets = [];
  initialState.services = [];
  initialState.servicesSubscriptions = [];
  initialState.users = [];
  initialState.branches = [];
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk),
});

export default store;
