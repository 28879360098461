import { Box, SimpleGrid } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";
import { IoTimeOutline, IoPeopleOutline, IoAnalyticsOutline } from 'react-icons/io5';


import { formatNumber, generateHoursArray } from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";
import StatsCard from "../../../StatsCard";

const ArrivalRatesPerHour = ({ reportIndex }) => {
  const { workDay, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const { tickets } = useSelector((state) => state.realtimeReducer);

  let ticketsClone = _.cloneDeep(tickets);

  ticketsClone = ticketsClone.filter(
    (a) => a.organizationId === organizationId
  );
  
  const startTimeStamp = dayjs()
    .set("hour", workDay[0].split(" ")[0])
    .set("minute", 0)
    .unix();
  const endTimeStamp = dayjs().unix();
  const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);

  const chart = hoursBetween
    .map((hour) => {
      const hourTickets = ticketsClone.filter(
        (a) => dayjs.unix(a.created).format("h A") === hour
      );

      if (workDay.includes(hour))
        return {
          hour,
          total: hourTickets.length,
        };
      return null;
    })
    .filter(Boolean);

  const min = Math.min(...chart.map((a) => a.total)) || 0;
  const max = Math.max(...chart.map((a) => a.total)) || 0;
  const total = chart.reduce((a, b) => a + b.total, 0) || 0;
  const average = total / chart.length || 0;
  const peakHour = chart.find((a) => a.total === max)?.hour || "NA";

  const stats = [
    {
      title: 'Total Visitors Today',
      stat: total,
      icon: IoPeopleOutline,
      iconColor: "var(--green)"
    },
    {
      title: 'Peak Hour',
      stat: [peakHour],
      icon: IoAnalyticsOutline,
      iconColor: "var(--red)"
    },
    {
      title: 'Total This Hour',
      stat: chart.find((a) => a.hour === dayjs().format("h A"))?.total || 0,
      icon: IoTimeOutline,
      iconColor: "var(--green)"
    },
    {
      title: 'Maximum Visitors Per Hour',
      stat: Number.isFinite(max) && !Number.isNaN(max) ? formatNumber(max) : "NA",
      icon: IoPeopleOutline,
      iconColor: "var(--red)"
    },
    {
      title: 'Minimum Visitors Per Hour',
      stat: Number.isFinite(min) && !Number.isNaN(min) ? formatNumber(min) : "NA",
      icon: IoPeopleOutline,
      iconColor: "var(--green)"
    },
    {
      title: 'Average Visitors Per Hour',
      stat: Number.isFinite(average) && !Number.isNaN(average) ? formatNumber(average) : "NA",
      icon: IoPeopleOutline,
      iconColor: "var(--orange)"
    },
  ]

  return (
    <Box className="reportContainer" mt="20px">
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={0}
        mb={10}
      >
        {
          stats.map((a,i) =>
            <Box
              px={"20px"}
              py={"15px"}
              mb={"5px"}
              border={"1px solid var(--borderGrey)"}
              borderRight={{
                base: "1px solid #E5E4E5",
                md: [2,5].includes(i) ? "1px solid #E5E4E5" : "none"
              }}
              borderRadius={{
                base: "10px",
                md: [0,3].includes(i)
                      ? "10px 0 0 10px"
                      : [2,5].includes(i)
                        ? "0 10px 10px 0"
                        : "0"
              }}
            
      
            >
              <StatsCard title={a.title} stat={a.stat} icon={a.icon} iconColor={a.iconColor} />
            </Box>
          )
        }       
      </SimpleGrid>

      <Box mt="20px" mb="20px">
        <LineGraph
          chart={chart}
          height={"300px"}
          title={"Entire Organization Arrival Rates, Hour by Hour Comparison"}
          lineName="Tickets"
        />
      </Box>
    </Box>
  );
};

export default ArrivalRatesPerHour;
