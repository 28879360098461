import { Box, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { IoPeopleOutline, IoTimeOutline } from "react-icons/io5";

import {
  convertTo24HourHour,
  generateHoursArray,
} from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";
import StatsCard from "../../../StatsCard";
import { TICKET_STATUS } from "../../../../store/constants";
import SelectBranchInfo from "../../../misc/SelectBranchInfo";

const BranchNumberOfVisitorsPerHour = ({ branches }) => {
  // props validation
  BranchNumberOfVisitorsPerHour.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  const { workDay, branchId } = useSelector((state) => state.defaultReducer);
  const { tickets } = useSelector((state) => state.realtimeReducer);
  const ticketsClone = _.cloneDeep(tickets);

  const branchesMap = branches.reduce((acc, { id: branchId }) => {
    acc[branchId] = ticketsClone.filter((a) => a.branchId === branchId);
    return acc;
  }, {});

  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      const branchName = branches.find((branch) => branch.id === branchId)?.name || `Branch-${branchId}`;
      const startTimeStamp = dayjs()
        .set("hour", workDay[0].split(" ")[0])
        .set("minute", 0)
        .unix();
      const endTimeStamp = dayjs().unix();
      const hoursBetween = generateHoursArray(startTimeStamp, endTimeStamp);

      const chart = hoursBetween.map((hour) => {
        const currentTime = convertTo24HourHour(hour);
        const hourVisitors = branchData.filter((a) => {
          const sessionEndHour = dayjs.unix(a?.sessionEnd).format("H");
          const createdTime = dayjs.unix(a.created).hour();
          const status = Number(a.status);

          return (
            (sessionEndHour >= currentTime && createdTime <= currentTime)
            || (status < TICKET_STATUS.NO_SHOW && createdTime <= currentTime)
          );
        });

        if (workDay.includes(hour)) {
          return {
            hour,
            total: hourVisitors.length,
          };
        }
        return null;
      });
      const average = chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
      const max = Math.max(...chart.map((a) => a.total)) || 0;
      const peakHour = chart.find((a) => a.total === max)?.hour || "N/A";

      return {
        branchName,
        chart,
        average: Math.floor(average),
        peakHour,
        total: branchData.length, // tickets overlap so we cant reduce the totals
      };
    })
    .sort((a, b) => b.total - a.total);

  return (
    <Box>
      {branchId.length > 0 ? (
        <Box>
          {
          branchesData?.map(({
            branchName, chart, branchId, total, average, peakHour,
          }) => {
            const stats = [
              {
                title: 'Total Customers',
                stat: total,
                icon: IoPeopleOutline,
                iconColor: 'var(--green)',
              },
              {
                title: 'Avg. in Branch Per Hour',
                stat: average,
                icon: IoPeopleOutline,
                iconColor: 'var(--orange)',
              },
              {
                title: 'Peak Hour',
                stat: peakHour,
                icon: IoTimeOutline,
                iconColor: 'var(--IoPeopleOutline)',
              },
              {
                title: 'Total in Branch This Hour',
                stat: chart.find((a) => a.hour === dayjs().format("h A"))?.total || 0,
                icon: IoPeopleOutline,
                iconColor: 'var(--green)',
              },
            ];

            return (
              <Box key={branchId}>
                <Box mt={4}>
                  <SimpleGrid
                    columns={{ base: 1, md: 4 }}
                    spacing={0}
                    mb={10}
                  >
                    {
                      stats.map((a, i) => (
                        <Box
                          px="20px"
                          py="15px"
                          mb="5px"
                          border="1px solid var(--borderGrey)"
                          borderRight={{
                            base: '1px solid #E5E4E5',
                            md: [3, 7].includes(i) ? '1px solid #E5E4E5' : 'none',
                          }}
                          borderRadius={{
                            base: '10px',
                            md: [0, 4].includes(i)
                              ? '10px 0 0 10px'
                              : [3, 7].includes(i)
                                ? '0 10px 10px 0'
                                : '0',
                          }}
                        >
                          <StatsCard
                            title={a.title}
                            stat={a.stat}
                            statNumberId={a.statNumberId}
                            icon={a.icon}
                            iconColor={a.iconColor}
                          />
                        </Box>
                      ))
                    }
                  </SimpleGrid>
                </Box>
                <Box mt="20px" mb="20px" pt="10px">
                  <LineGraph
                    chart={chart}
                    lineName="Customers"
                    title={`${branchName} Number of Customers in Branch, Hour by Hour Comparison`}
                  />
                </Box>
              </Box>
            );
          })
          }
        </Box>
      ) : (
        <SelectBranchInfo />
      )}
    </Box>
  );
};

export default BranchNumberOfVisitorsPerHour;
